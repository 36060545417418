import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';
import { createCalendarQuery } from '../../../core/helpers/dateHelpers';
import { ESkipTake } from '../../../core/constants/enums';

const END_POINT = {
  prefix : 'Reports',
  gameByBetTypeReport : 'gameByBetTypeReport',
  gameReport : 'gameReport',
  topbettype : 'topbettype',
  betTypeReportForSingleBets : 'betTypeReportForSingleBets',
  reportByWinning : 'reportByWinning',
  betReportByStake : 'betReportByStake',
  jackpotReport : 'jackpotReport',
  betReport : 'betReport',
  betTypeReport : 'betTypeReport',
  userReport : 'userReport',
  activity : 'activity',
  partnersFinancialReport : 'partnersFinancialReport'
};
const defaultTakeQuery = { [ESkipTake.take]:  5};

class ReportsApiHandler extends  JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }
  getUserReport(queryField){

    return queryField ? this.get(`${END_POINT.userReport}`, queryField ) : this.get(`${END_POINT.userReport}`);
}
  getGameByBetTypeReport(){
    return this.get(`${END_POINT.gameByBetTypeReport}`);
  }

  getBetReport(queryField){
    return queryField ?  this.get(`${END_POINT.betReport}`, queryField) : this.get(`${END_POINT.betReport}`);
  }
  getGameReport(queryField){
    return queryField ?  this.get(`${END_POINT.gameReport}`, queryField) : this.get(`${END_POINT.gameReport}`);
  }

  getJackpotReport(){
    return this.get(`${END_POINT.jackpotReport}`)
  }

  getBetTypeReport(queryField){
    return queryField ?  this.get(`${END_POINT.betTypeReport}`, queryField) : this.get(`${END_POINT.betTypeReport}`);
  }


  getTopBetTypeReport(query){
    return query ? this.get(END_POINT.topbettype, {...defaultTakeQuery, ...query}) :  this.get(END_POINT.topbettype, {...defaultTakeQuery});
  }

  getBetTypeReportForSingleBets(){
    return this.get(`${END_POINT.betTypeReportForSingleBets}`);
  }

  getReportByWinning(query){
    return  query ? this.get(END_POINT.reportByWinning, {...defaultTakeQuery, ...query}) :  this.get(END_POINT.reportByWinning, {...defaultTakeQuery});
  }

  getBetReportByStake(query){
    return query ? this.get(END_POINT.betReportByStake, {...defaultTakeQuery, ...query}) :  this.get(END_POINT.betReportByStake, {...defaultTakeQuery});
  }

  getReportsActivity(query){
    return query ? this.get(END_POINT.activity, {...query}) :  this.get(END_POINT.activity);
  }

  getPartnersFinancialReport(query){
    return query ? this.get(END_POINT.partnersFinancialReport, query) : this.get(END_POINT.partnersFinancialReport)
  }
}

const reportsApiHandler = new ReportsApiHandler(END_POINT.prefix);
export  default reportsApiHandler;