import createReducer from '../../core/helpers/createReducer';
import { MODAL_ACTION_TYPES } from '../../core/constants/actionTypes';
import MODAL_NAMES from '../../core/constants/modalNames';

const modalToggle = (state, action) => {
  return state.concat(action.payload);
};

const modalClose = (state) => {
  state.pop();
  return [...state];
};

export const  modalState = createReducer([], {
  [MODAL_ACTION_TYPES.RX_MODAL_TOGGLE]: modalToggle,
  [MODAL_ACTION_TYPES.RX_MODAL_CLOSE]: modalClose,
});



