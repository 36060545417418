import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { controllerMiddleware } from '../middlewares';
import configs  from '../../configs/config';
import * as  reducers from '../reducers'


const rootReducers = combineReducers({
  isAuth: reducers.isAuthReducer,
  userSettings: reducers.usersSettingsReducers,
  userList: reducers.userListReducer,
  betReport : reducers.betReportReducer,
  betTypeReport: reducers.betTypeReportReducer,
  gameReport: reducers.gameReportReducer,
  userReport: reducers.userReportReducer,
  currencies: reducers.currenciesReducer,
  currency: reducers.currency,
  translations: reducers.translationReducer,
  partnerCustomizationConfig: reducers.partnerCustomizationConfigReducer,
  partnerBetType : reducers.partnersBetTypeReducer,
  partnerConfig: reducers.partnerConfigReducer,
  partnerMainLimits: reducers.partnerMainLimits,
  schedulerTemplates: reducers.schedulerTemplates,
  partnerBetTypesGroup: reducers.partnerBetTypesGroupReducer,
  dashBoard : reducers.dashBoard,
  partnersList : reducers.partnersList,
  gameReducer: reducers.gamesReducer,
  modalState: reducers.modalState,
  partnerSettings: reducers.partnerSettings,
  partnerBetTypeConfigs: reducers.partnerBetTypesConfig,
  languages: reducers.languages,
  partnerCurrencies: reducers.partnerCurrencies,
  partnersFinancialReport:reducers.partnersFinancalReportReducer,
  partnerAnimals: reducers.partnerAnimalsReducer,
  animals: reducers.animalsReducer,
  partnerBetTypeInfo:reducers.partnersBetTypeInfoReducer,
  partnerGameTypeLimits: reducers.partnerGameTypeLimits
});

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(controllerMiddleware(configs))
  // other store enhancers if any
);


const store = createStore(rootReducers, enhancer);
export default store;
