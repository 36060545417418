import { TRANSLATIONS_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetTranslations = payload => {
  return {
    type: TRANSLATIONS_TYPES.CNTRL_GET_TRANSLATIONS,
    payload,
  }
};
export const rxGetTranslationsDone = payload => {
  return {
    type: TRANSLATIONS_TYPES.RX_GET_TRANSLATIONS_DONE,
    payload,
  }
};
export const rxGetTranslationsPending = payload => {
  return {
    type: TRANSLATIONS_TYPES.RX_GET_TRANSLATIONS_PENDING,
    payload,
  }
};

export const cntrlGetLanguages = payload => {
  return {
    type: TRANSLATIONS_TYPES.CNTRL_GET_LANGUAGES,
    payload,
  }
};
export const rxGetLanguagesDone = payload => {
  return {
    type: TRANSLATIONS_TYPES.RX_GET_LANGUAGES_DONE,
    payload,
  }
};
export const rxGetLanguagesPending = payload => {
  return {
    type: TRANSLATIONS_TYPES.RX_GET_LANGUAGES_PENDING,
    payload,
  }
};

