import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix: 'Bets',
  betHistory : 'betHistory'

};

class BetsApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix)
  }
  addBet(data){
    return this.post('', data)
  }
  editBet(betId, data ){
    return this.put(betId, data )
  }

  getBets(){
    return this.get(END_POINT.betHistory )
  }
}

const betsApiHandler = new BetsApiHandler(END_POINT.prefix);
export default betsApiHandler;