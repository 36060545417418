import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import {
  cntrlGetReportsActivity,
  cntrlGetReportsByStake,
  cntrlGetReportsByTopBetType,
  cntrlGetReportsByWining, cntrlGetReportsConfigurations
} from '../../../state-management/actions/reportAction';
import { createCalendarQuery } from '../../../core/helpers/dateHelpers';
import { EDashBoardConfigTypes } from '../../../core/constants/enums';
import { cntrlGetPartners } from '../../../state-management/actions/partnerActions';

class ReportsOperation extends Operations{
  constructor(reportsApiHandler, usersApiHandler){
    super();
    this.reportsApiHandler = reportsApiHandler;
    this.usersApiHandler = usersApiHandler;
  }
  async getBetReport(action){
    const response = await this.reportsApiHandler.getBetReport(action);
    return responseErrorCheck(response);
  }
  async getBetTypeReport(action){
    const response = await this.reportsApiHandler.getBetTypeReport(action);
    return responseErrorCheck(response);
  }
  async getUserReport(action){
    const response = await this.reportsApiHandler.getUserReport(action);
    return responseErrorCheck(response);
  }


  async getGameByBetTypeReport(store, action){
    const response = await this.reportsApiHandler.getGameByBetTypeReport();
    return responseErrorCheck(response);
  }

  async getGameReport( action ){
    const response = await this.reportsApiHandler.getGameReport(action);
    return responseErrorCheck(response);
  }

  async getTopBetTypeReport(store, action){
    const response = await this.reportsApiHandler.getTopBetTypeReport(action.payload);
    return responseErrorCheck(response);
  }

  async getBetTypeReportForSingleBets(store, action){
    const response = await this.reportsApiHandler.getBetTypeReportForSingleBets();
    return responseErrorCheck(response);
  }


  async getJackpotReport(store, action){
    const response = await this.reportsApiHandler.getJackpotReport();
    return responseErrorCheck(response);
  }

  /**
   * description method for partners financal reports
   * @param store
   * @param action
   * @returns {Promise<*>}
   */

  async getPartnersFinancialReport(store, action){

    const response = await this.reportsApiHandler.getPartnersFinancialReport(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description method for dashboard info about winning
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getReportByWinning(store, action){
    const response = await this.reportsApiHandler.getReportByWinning(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description method for dashboard info about stake
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getBetReportByStake(store, action){
    const response = await this.reportsApiHandler.getBetReportByStake(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description method for getting user activity data about bets
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getReportsActivity(store, action){
    const response = await this.reportsApiHandler.getReportsActivity(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description method for dashboard info about top betTypes
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getTopBetTypeReport(store, action) {
    const response = await this.reportsApiHandler.getTopBetTypeReport(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description method for getting dashboard configs
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getDashboardConfigs(store, action) {
    let result = {};
    const response = await this.usersApiHandler.getDashboardConfigs();
    if (!response.result.dashboard.length) {
      await this.setReportsConfigs(store, EDashBoardConfigTypes);
      result =  {dashboard :EDashBoardConfigTypes};
    } else {
      result = responseErrorCheck(response);
    }
    return result
  }


  async setReportsConfigs(store, data) {
    const response = await this.usersApiHandler.postDashboardConfigs(data);
    return responseErrorCheck(response);
  }

  async getDashBoardData(store, action) {
    const query  = action.payload;
    await Promise.all([
      store.dispatch(cntrlGetReportsActivity(query)),
      store.dispatch(cntrlGetReportsByStake(query)),
      store.dispatch(cntrlGetReportsByWining(query)),
      store.dispatch(cntrlGetReportsByTopBetType(query)),
    ]);
    return true
  }
}
export default ReportsOperation;
