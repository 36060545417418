import { EDateConstants } from '../constants/enums';
import moment from 'moment';

export const createCalendarQuery = (startDate , endDate)=> {
  let dateQuery = [];
  if (startDate && endDate) {
    const newDay = addOneDayToStart(endDate);
    dateQuery = [
      { key: EDateConstants.startDate, value: startDate },
      { key: EDateConstants.endDate, value: newDay },
    ]
  } else if (startDate && !endDate) {
    const newDay = addOneDayToStart(startDate);
    dateQuery = [
      { key: EDateConstants.startDate, value: startDate },
      { key: EDateConstants.endDate, value: newDay }
    ];
  } else {
    dateQuery = [
      { key: EDateConstants.startDate, value: ""},
      { key: EDateConstants.endDate, value: "" }
    ];
  }
  return dateQuery;
};
export const addOneDayToStart = date => {
  const nextDay = moment(date).format('L');
  const tomorrow = moment(nextDay).add(1, 'days');
  return tomorrow.format('L');
};

export const pmTimeToAmConverter = time => {
  let converted = "";
  const timeArr = time.split(":");
  const hours = timeArr[0];
  if (hours.length < 2) {
    converted = "0" + hours;
    return `${converted}:${timeArr[1]}`;
  }
  return time;
};