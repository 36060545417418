import ErrorHandler from './ErrorHandler';
import store from '../../state-management/store'
import { rxSetIsAuth } from '../../state-management/actions/tokenActions';

const responseErrorCheck = response => {
  if (response.status === 0) {
    if (response.hasOwnProperty(ErrorHandler.errorConstants.status)) {

      // unAuthorized error 401
      if (response.responseStatus === ErrorHandler.errorCodesEnum.unAuthorized) {
        store.dispatch(rxSetIsAuth(false));
        // postMessageService.openLoginPopup();
        throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.unAuthorized]);
      }

      // front end Errors 400 - 500 unique errors at the top
      if (response.responseStatus >= ErrorHandler.errorCodesEnum.frontErrors && response.status < ErrorHandler.errorCodesEnum.serverError) {
        throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.frontErrors]);
      }

      // server errors 500+
      if (response.responseStatus >= ErrorHandler.errorCodesEnum.serverError) {
        throw new Error(ErrorHandler.errorCodesWithErrorMessage[ErrorHandler.errorCodesEnum.serverError]);
      }

    }
    throw new Error(response.errorMessage)
  }
  return response.result;
};

export default responseErrorCheck;