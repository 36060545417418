import { PARTNER_TYPES } from "../../core/constants/actionTypes";
import { PARTNER_BETTYPES } from "../../core/constants/actionTypes";

export const cntrlGetPartnerCustomizationConfig = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_CUSTOMIZATION_CONFIG,
  payload
});
export const rxGetPartnerCustomizationConfigDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CUSTOMIZATION_CONFIG_DONE,
  payload
});
export const rxGetPartnerCustomizationConfigPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CUSTOMIZATION_CONFIG_PENDING,
  payload
});
export const cntrlSetPartnerCustomizationConfigColors = payload => ({
  type: PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_COLORS,
  payload
});
export const cntrlSetPartnerCustomizationConfigBackgrounds = (
  data,
  partnerId
) => ({
  type: PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS,
  payload: {
    data,
    partnerId
  }
});
export const cntrlResetPartnerCustomizationConfigBackgrounds = partnerId => ({
  type: PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS_RESET,
  payload: partnerId
});

export const cntrlGetPartnerBetType = payload => ({
  type: PARTNER_BETTYPES.CNTRL_GET_PARTNER_BETTYPES,
  payload
});
export const rxGetPartnerBetTypePending = payload => ({
  type: PARTNER_BETTYPES.RX_GET_PARTNER_BETTYPE_PENDING,
  payload
});
export const rxGetPartnerBetTypeDone = payload => ({
  type: PARTNER_BETTYPES.RX_GET_PARTNER_BETTYPE_DONE,
  payload
});

// partner config
export const cntrlGetPartnerConfig = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_CONFIG,
  payload
});
export const rxGetPartnerConfigDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CONFIG_DONE,
  payload
});
export const rxGetPartnerConfigPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CONFIG_PENDING,
  payload
});

export const cntrlEditPartnerConfig = payload => ({
  type: PARTNER_TYPES.CNTRL_EDIT_PARTNER_CONFIG,
  payload
});

export const cntrlGetPartners = payload => {
  return {
    type: PARTNER_TYPES.CNTRL_GET_PARTNERS,
    payload
  };
};
export const rxGetPartnersDone = payload => {
  return {
    type: PARTNER_TYPES.RX_GET_PARTNERS_DONE,
    payload
  };
};
export const rxGetPartnersPending = payload => {
  return {
    type: PARTNER_TYPES.RX_GET_PARTNERS_PENDING,
    payload
  };
};

//Partner Settings
export const cntrlGetPartnerSettings = payload => {
  return {
    type: PARTNER_TYPES.CNTRL_GET_PARTNER_SETTINGS,
    payload
  };
};
export const rxGetPartnerSettingsDone = payload => {
  return {
    type: PARTNER_TYPES.RX_GET_PARTNER_SETTINGS_DONE,
    payload
  };
};
export const rxGetPartnerSettingsPending = payload => {
  return {
    type: PARTNER_TYPES.RX_GET_PARTNER_SETTINGS_PENDING,
    payload
  };
};

//Add partner
export const cntrlAddPartner = payload => {
  return {
    type: PARTNER_TYPES.CNTRL_ADD_PARTNER,
    payload
  };
};

//partner bet type config
export const cntrlGetPartnerBetTypeConfig = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPE_CONFIG,
  payload
});
export const rxGetPartnerBetTypeConfigDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_CONFIG_DONE,
  payload
});
export const rxGetPartnerBetTypeConfigPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_CONFIG_PENDING,
  payload
});
export const cntrlSetPartnerBetTypeConfis = payload => ({
  type: PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_CONFIG,
  payload
});

// partner main limits
export const cntrlGetPartnerMainLimits = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_MAIN_LIMITS,
  payload
});
export const rxGetPartnerMainLimitsDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_MAIN_LIMITS_DONE,
  payload
});
export const rxGetPartnerMainLimitsPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_MAIN_LIMITS_PENDING,
  payload
});
export const cntrlSetPartnerMainLimits = payload => ({
  type: PARTNER_TYPES.CNTRL_SET_PARTNER_MAIN_LIMITS,
  payload
});

export const cntrlGetPartnerCurrencies = payload => {
  return {
    type: PARTNER_TYPES.CNTRL_GET_PARTNER_CURRENCIES,
    payload
  };
};

export const rxGetPartnerCurrenciesDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CURRENCIES_DONE,
  payload
});
export const rxGetPartnerCurrenciesPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_CURRENCIES_PENDING,
  payload
});
// partner bet Type group actions

export const cntrlGetBetTypeGroups = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPES_GROUP,
  payload
});
export const rxGetBetTypeGroupsDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPES_GROUP_DONE,
  payload
});
export const rxGetBetTypeGroupsPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPES_GROUP_PENDING,
  payload
});

export const cntrlAddBetTypeGroup = (data, partnerId) => ({
  type: PARTNER_TYPES.CNTRL_ADD_PARTNER_BET_TYPE_GROUP,
  payload: {
    partnerId,
    data
  }
});
export const cntrlDeleteBetTypeGroup = (groupId, partnerId) => ({
  type: PARTNER_TYPES.CNTRL_DELETE_PARTNER_BET_TYPE_GROUP,
  payload: {
    groupId,
    partnerId
  }
});
export const cntrlEditBetTypeGroup = (groupId, data, partnerId) => ({
  type: PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_GROUP,
  payload: {
    groupId,
    data,
    partnerId
  }
});

// partner animals actions

export const cntrlGetPartnerAnimals = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_ANIMALS,
  payload
});

export const rxGetPartnerAnimalsDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_ANIMALS_DONE,
  payload
});

export const rxGetPartnerAnimalsPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_ANIMALS_PENDING,
  payload
});

export const cntrlEditPartnerAnimals = (data, partnerId, circleAnimals) => ({
  type: PARTNER_TYPES.CNTRL_EDIT_PARTNER_ANIMALS,
  payload: {
    data,
    partnerId,
    circleAnimals
  }
});

export const cntrlResetPartnerAnimals = partnerId => ({
  type: PARTNER_TYPES.CNTRL_RESET_PARTNER_ANIMALS,
  payload: partnerId
});

export const cntrlGetPartnerBetTypeInfo = payload => ({
  type: PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPE_INFO,
  payload
});
export const cntrlEditPartnerBetTypeInfo = (data, partnerId) => ({
  type: PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_INFO,
  payload: {
    data,
    partnerId
  }
});

export const rxGetPartnerBetTypeInfoDone = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_INFO_DONE,
  payload
});

export const rxGetPartnerBetTypeInfoPending = payload => ({
  type: PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_INFO_PENDING,
  payload
});


export const cntrlGetPartnerGameTypeLimits = payload =>({
  type:PARTNER_TYPES.CNTRL_GET_PARTNER_GAMETYPE_LIMITS,
  payload
});
export const rxGetPartnerGameTypeLimitsDone = payload =>({
  type:PARTNER_TYPES.RX_GET_PARTNER_GAMETYPE_LIMITS_DONE,
  payload
});
export const rxGetPartnerGameTypeLimitsPending = payload =>({
  type:PARTNER_TYPES.RX_GET_PARTNER_GAMETYPE_LIMITS_PENDING,
  payload
});
export const cntrlEditPartnerGameTypeLimits = payload =>({
  type:PARTNER_TYPES.CNTRL_EDIT_PARTNER_GAMETYPE_LIMITS,
  payload
});
export const rxEditPartnerGameTypeLimitsDone = payload =>({
  type:PARTNER_TYPES.RX_EDIT_PARTNER_GAMETYPE_LIMITS_DONE,
  payload
});
export const rxEditPartnerGameTypeLimitsPending = payload =>({
  type:PARTNER_TYPES.RX_EDIT_PARTNER_GAMETYPE_LIMITS_PENDING,
  payload
});