import Controller from '../../core/classes/AppController';
import LocalStorageHelper from '../../core/helpers/LocalStorageHelper';
import { ACCESS } from '../../core/constants/util';
import ApiHandler from '../../core/classes/ApiHandler';
import { cntrlRefreshToken } from '../../state-management/actions/tokenActions';
import { cntrlGetCurrencies } from '../../state-management/actions/currenciesActions';
import { cntrlGetPartnerConfig } from '../../state-management/actions/partnerActions';

class AppController extends Controller{

  async init(store, action) {

    const access = LocalStorageHelper.getItem(ACCESS);

    if(LocalStorageHelper.getItem(ACCESS)) {

      if (access && access.expiresIn > Date.now()) {
        await store.dispatch(cntrlGetCurrencies());
        // store.dispatch(cntrlGetLanguages());
        ApiHandler.token = access.accessToken;
        // store.dispatch(cntrlGetLanguages());
        await store.dispatch(cntrlRefreshToken());
        return ApiHandler.token;
      }
      return false;
    } else {
      return false;
    }
    // users/configs
    // users/settings
    //permissions




    //notifications?skip=0&take=10
    //users/languages
    //Messages/unread
    //users/balance
  }
}

export default AppController