import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'Currencies'
};

class CurrenciesApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }

  getCurrencies(){
    return this.get()
  }

  getCurrenciesCode(currencyCode){
    return this.get(currencyCode)
  }

}

const currenciesApiHandler = new CurrenciesApiHandler(END_POINT.prefix);
export default currenciesApiHandler
