import TranslationsApiHandler from '../TranslationsApiHandler';

const END_POINTS = {
  prefix: 'Languages',
};
class LanguagesApiHandler extends TranslationsApiHandler {
  constructor(prefix) {
    super(prefix)
  }

  getLanguages() {
    return this.get()
  }

}

const languagesApiHandler = new LanguagesApiHandler(END_POINTS.prefix);
export default languagesApiHandler;