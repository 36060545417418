import React, { useState } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import { partnerRoutes, dashboardRoutes } from "../../../routing/router";
import { Collapse, Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import SideBarItem from "../sideBarItem";
import { withRouter } from "react-router-dom";

const SideBar = React.memo(
  ({ state, isOpen, isAdmin, setIsOpen, ...props }) => {
    const [selectedRoute, setSelectedRoute] = useState("/");

    const changeRout = route => {
      setSelectedRoute(route.path);
      setIsOpen(prevState => !prevState);
    };

    const routes = isAdmin ? dashboardRoutes : partnerRoutes;

    return (
      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <List>
          {routes.map(route => {
            if (route.innerRouts && route.innerRouts.length) {
              return (
                <div key={route.path}>
                  <SideBarItem
                    route={{ ...route, path: props.location.pathname }}
                    active={selectedRoute !== route.path}
                    onClick={() =>
                      selectedRoute !== route.path
                        ? setSelectedRoute(route.path)
                        : setSelectedRoute("/")
                    }
                  />
                  <Collapse
                    in={selectedRoute === route.path}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" style={{ paddingLeft: 10 }}>
                      {route.innerRouts.map(inner => {
                        if (inner.hasOwnProperty("permission")) {
                          return inner.permission(state) ? (
                            <SideBarItem
                              key={`${route.path}${inner.path}`}
                              route={{
                                ...inner,
                                path: `${route.path}${inner.path}`
                              }}
                              onClick={inner.onClick}
                              notRedirect={inner.notRedirect}
                              setIsOpen={setIsOpen}
                            />
                          ) : null;
                        }
                        return (
                          <SideBarItem
                            key={`${route.path}${inner.path}`}
                            route={{
                              ...inner,
                              path: `${route.path}${inner.path}`
                            }}
                            onClick={inner.onClick}
                            notRedirect={inner.notRedirect}
                            setIsOpen={setIsOpen}
                          />
                        );
                      })}
                    </List>
                  </Collapse>
                </div>
              );
            }

            return (
              <SideBarItem
                key={route.path}
                onClick={() => setSelectedRoute(route.path)}
                route={route}
                setIsOpen={setIsOpen}
              />
            );
          })}
        </List>
      </Drawer>
    );
  }
);

SideBar.defaultProps = {};

SideBar.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

const mapStateToProps = state => ({
  state,
  isAdmin: !state.userSettings.data.type
});
export default connect(mapStateToProps)(withRouter(SideBar));
