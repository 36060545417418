import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'ExtraGames',
  players : 'players',
  result : 'result'
}

class ExtraGamesApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }
  getExtraGamesPlayers(gameId){
    return this.get(`${gameId}/${END_POINT.players}`)
  }
  getExtraGames(){
    return this.get()
  }
  addExtraGames(data){
    return this.post('', data)
  }
  addExtraGamesById(id, data){
    return this.post(`${id}/${END_POINT.result}`, data)
  }

  deleteExtraGames(id){
    return this.delete(id)
  }

}

const extraGamesApiHandler = new ExtraGamesApiHandler(END_POINT.prefix);
export default extraGamesApiHandler;