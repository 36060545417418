import Controller from '../../core/classes/AppController';
import { rxGetDefaultAnimalsPending, rxGetDefaultAnimalsDone} from "../../state-management/actions/animalsActions";

class AnimalsController extends Controller {
  constructor(animalsOperation) {
    super();
    this.animalsOperation = animalsOperation;
  }

  async getDefaultAnimals(store, action) {

    try {
      store.dispatch(rxGetDefaultAnimalsPending());
      const defaultAnimals = await this.animalsOperation.getDefaultAnimals(
          store,
          action
      );

      store.dispatch(rxGetDefaultAnimalsDone(defaultAnimals));
      return defaultAnimals;
    } catch (e) {
      return new Error(e);
    }
  }

}

export default AnimalsController;
