export const APP_GLOBAL_TYPES = {
  CNTRL_APP_INIT: 'CNTRL_APP_INIT',
};

export const AUTH_TYPES = {
  CNTRL_LOG_IN: 'CNTRL_LOG_IN',
  CNTRL_REFRESH_TOKEN: 'CNTRL_REFRESH_TOKEN',
  RX_SET_AUTH_BOOL: 'RX_SET_AUTH_BOOL',
  CNTRL_LOGOUT: 'CNTRL_LOGOUT',
};

export const USER_TYPES = {
  //user settings
  CNTRL_GET_USERS_SETTINGS: 'CNTRL_GET_USERS_SETTINGS',
  RX_GET_USERS_SETTINGS: 'RX_GET_USERS_SETTINGS',
  RX_GET_USERS_SETTINGS_DONE: 'RX_GET_USERS_SETTINGS_DONE',
  RX_GET_USERS_SETTINGS_PENDING: 'RX_GET_USERS_SETTINGS_PENDING',

  CNTRL_EDIT_USER_SETTINGS: 'CNTRL_EDIT_USER_SETTINGS',
  // user list
  CNTRL_GET_USERS: 'CNTRL_GET_USERS',
  RX_GET_USERS: 'RX_GET_USERS',
  RX_GET_USERS_PENDING: 'RX_GET_USERS_PENDING',
  RX_GET_USERS_DONE: 'RX_GET_USERS_DONE',

  //balance
  CNTRL_GET_USER_BALANCE: 'CNTRL_GET_USER_BALANCE',
  RX_GET_USER_BALANCE: 'RX_GET_USER_BALANCE',
  RX_GET_USER_BALANCE_DONE: 'RX_GET_USER_BALANCE_DONE',
  RX_GET_USER_BALANCE_PENDING: 'RX_GET_USER_BALANCE_PENDING',

  //EDIT CURRENCY
  CNTRL_EDIT_USER_CURRENCY: 'CNTRL_EDIT_USER_CURRENCY',
  RX_EDIT_USER_CURRENCY: 'RX_EDIT_USER_CURRENCY',
  RX_EDIT_USER_CURRENCY_PENDING: 'RX_EDIT_USER_CURRENCY_PENDING',
  RX_EDIT_USER_CURRENCY_DONE: 'RX_EDIT_USER_CURRENCY_DONE',

  CNTRL_EDIT_USER_LANGUAGE: 'CNTRL_EDIT_USER_LANGUAGE',
};
export const CURRENCIES_TYPES = {
  CNTRL_GET_CURRENCIES: 'CNTRL_GET_CURRENCIES',
  RX_GET_CURRENCIES: 'RX_GET_CURRENCIES',
  RX_GET_CURRENCIES_DONE: 'RX_GET_CURRENCIES_DONE',
  RX_GET_CURRENCIES_PENDING: 'RX_GET_CURRENCIES_PENDING',

  CNTRL_GET_CURRENCY_BY_CODE: 'CNTRL_GET_CURRENCY_BY_CODE',
  RX_GET_CURRENCY_BY_CODE: 'RX_GET_CURRENCY_BY_CODE',
  RX_GET_CURRENCY_BY_CODE_PENDING: 'RX_GET_CURRENCY_BY_CODE_PENDING',
  RX_GET_CURRENCY_BY_CODE_DONE: 'RX_GET_CURRENCY_BY_CODE_DONE',

};
export const REPORT_TYPES = {

  //BET REPORTS
  CNTRL_GET_BET_REPORT: 'CNTRL_GET_BET_REPORT',
  RX_GET_BET_REPORT: 'RX_GET_BET_REPORT',
  RX_GET_BET_REPORT_PENDING: 'RX_GET_BET_REPORT_PENDING',
  RX_GET_BET_REPORT_DONE: 'RX_GET_BET_REPORT_DONE',


  //BET TYPE REPORTS
  CNTRL_GET_BET_TYPE_REPORT: 'CNTRL_GET_BET_TYPE_REPORT',
  RX_GET_BET_TYPE_REPORT: 'RX_GET_BET_TYPE_REPORT',
  RX_GET_BET_TYPE_REPORT_PENDING: 'RX_GET_BET_TYPE_REPORT_PENDING',
  RX_GET_BET_TYPE_REPORT_DONE: 'RX_GET_BET_TYPE_REPORT_DONE',

  //GAME REPORT

  CNTRL_GET_GAME_REPORT: 'CNTRL_GET_GAME_REPORT',
  RX_GET_GAME_REPORT: 'RX_GET_GAME_REPORT',
  RX_GET_GAME_REPORT_PENDING: 'RX_GET_GAME_REPORT_PENDING',
  RX_GET_GAME_REPORT_DONE: 'RX_GET_GAME_REPORT_DONE',

  //USER REPORT

  CNTRL_GET_USER_REPORT: 'CNTRL_GET_USER_REPORT',
  RX_GET_USER_REPORT: 'RX_GET_USER_REPORT',
  RX_GET_USER_REPORT_PENDING: 'RX_GET_USER_REPORT_PENDING',
  RX_GET_USER_REPORT_DONE: 'RX_GET_USER_REPORT_DONE',

  //REPORT ACTIVITIES
  CNTRL_GET_REPORTS_ACTIVITY: 'CNTRL_GET_REPORTS_ACTIVITY',
  RX_GET_REPORTS_ACTIVITY: 'RX_GET_REPORTS_ACTIVITY',
  RX_GET_REPORTS_ACTIVITY_PENDING: 'RX_GET_REPORTS_ACTIVITY_PENDING',
  RX_GET_REPORTS_ACTIVITY_DONE: 'RX_GET_REPORTS_ACTIVITY_DONE',

  //REPORT CONFIGS
  CNTRL_GET_REPORTS_CONFIGS: 'CNTRL_GET_REPORTS_CONFIGS',
  RX_GET_REPORTS_CONFIGS: 'RX_GET_REPORTS_CONFIGS',
  RX_GET_REPORTS_CONFIGS_PENDING: 'RX_GET_REPORTS_CONFIGS_PENDING',
  RX_GET_REPORTS_CONFIGS_DONE: 'RX_GET_REPORTS_CONFIGS_DONE',


  CNTRL_SET_REPORTS_CONFIGS: 'CNTRL_SET_REPORTS_CONFIGS',

  //TOP BET TYPES
  CNTRL_GET_TOP_BET_TYPES: 'CNTRL_GET_TOP_BET_TYPES',
  RX_GET_TOP_BET_TYPES: 'RX_GET_TOP_BET_TYPES',
  RX_GET_TOP_BET_TYPES_PENDING: 'RX_GET_TOP_BET_TYPES_PENDING',
  RX_GET_TOP_BET_TYPES_DONE: 'RX_GET_TOP_BET_TYPES_DONE',

  //REPORTS BY WINNING
  CNTRL_GET_REPORTS_BY_WINNING: 'CNTRL_GET_REPORTS_BY_WINNING',
  RX_GET_REPORTS_BY_WINNING: 'RX_GET_REPORTS_BY_WINNING',
  RX_GET_REPORTS_BY_WINNING_PENDING: 'RX_GET_REPORTS_BY_WINNING_PENDING',
  RX_GET_REPORTS_BY_WINNING_DONE: 'RX_GET_REPORTS_BY_WINNING_DONE',

  //REPORTS BY STAKE
  CNTRL_GET_REPORTS_BY_STAKE: 'CNTRL_GET_REPORTS_BY_STAKE',
  RX_GET_REPORTS_BY_STAKE: 'RX_GET_REPORTS_BY_STAKE',
  RX_GET_REPORTS_BY_STAKE_PENDING: 'RX_GET_REPORTS_BY_STAKE_PENDING',
  RX_GET_REPORTS_BY_STAKE_DONE: 'RX_GET_REPORTS_BY_STAKE_DONE',


  //DASHBOARD DATA
  CNTRL_GET_DASHBOARD_DATA: 'CNTRL_GET_DASHBOARD_DATA',
  RX_GET_DASHBOARD_DATA: 'RX_GET_DASHBOARD_DATA',
  RX_GET_DASHBOARD_DATA_PENDING: 'RX_GET_DASHBOARD_DATA_PENDING',
  RX_GET_DASHBOARD_DATA_DONE: 'RX_GET_DASHBOARD_DATA_DONE',

  //ADD PAGE AND CHANGE ROWS COUNT

  RX_SET_BETREPORT_PAGE_NUMBER: 'RX_SET_PAGE_NUMBER',
  RX_SET_BETREPORT_ROWS_COUNT: 'RX_SET_ROWS_COUNT',
  RX_SET_BET_TYPE_REPORT_PAGE_NUMBER: 'RX_SET_BET_TYPE_REPORT_PAGE_NUMBER',
  RX_SET_BET_TYPE_REPORT_ROWS_COUNT: 'RX_SET_BET_TYPE_REPORT_ROWS_COUNT',
  RX_SET_FINANCIAL_REPORT_PAGE_NUMBER: 'RX_SET_FINANCIAL_REPORT_PAGE_NUMBER',
  RX_SET_FINANCIAL_REPORT_ROWS_COUNT: 'RX_SET_FINANCIAL_REPORT_ROWS_COUNT',
  RX_SET_GAME_REPORT_PAGE_NUMBER: 'RX_SET_GAME_REPORT_PAGE_NUMBER',
  RX_SET_GAME_REPORT_ROWS_COUNT: 'RX_SET_GAME_REPORT_ROWS_COUNT',
  RX_SET_PLAYERS_PAGE_NUMBER: 'RX_SET_PLAYERS_PAGE_NUMBER',
  RX_SET_PLAYERS_ROWS_COUNT: 'RX_SET_PLAYERS_ROWS_COUNT',


  //FINANCAL REPORTS
  CNTRL_GET_PARTNER_FINANCAL_REPORT : 'CNTRL_GET_PARTNER_FINANCAL_REPORT',
  RX_GET_PARTNER_FINANCAL_REPORT : 'RX_GET_PARTNER_FINANCAL_REPORT',
  RX_GET_PARTNER_FINANCAL_REPORT_PENDING : 'RX_GET_PARTNER_FINANCAL_REPORT_PENDING',
  RX_GET_PARTNER_FINANCAL_REPORT_DONE : 'RX_GET_PARTNER_FINANCAL_REPORT_DONE'


};

// GAME TYPES
export const GAME_TYPES = {

  //GET GAME
  CNTRL_GET_GAMES: 'CNTRL_GET_GAMES',
  RX_GET_GAMES: 'RX_GET_GAMES',
  RX_GET_GAMES_DONE: 'RX_GET_GAMES_DONE',
  RX_GET_GAMES_PENDING: 'RX_GET_GAMES_PENDING',

  //GET GAME ID
  CNTRL_GET_GAME_BY_ID: 'CNTRL_GET_GAME_BY_ID',

  RX_SET_PAGE_NUMBER: 'RX_SET_PAGE_NUMBER',
  RX_SET_ROWS_COUNT: 'RX_SET_ROWS_COUNT',

  //CANCEL GAME
  CNTRL_CANCEL_GAME: 'CNTRL_CANCEL_GAME',
  RX_CANCEL_GAME: 'RX_CANCEL_GAME',
  RX_CANCEL_GAME_PENDING: 'RX_CANCEL_GAME_PENDING',
  RX_CANCEL_GAME_DONE: 'RX_CANCEL_GAME_DONE',

  //GET RESULT
  CNTRL_GET_RESULT_BY_ID: 'CNTRL_GET_RESULT_BY_ID',
  RX_GET_RESULT_BY_ID: 'RX_GET_RESULT_BY_ID',
  RX_GET_RESULT_BY_ID_PENDING: 'RX_GET_RESULT_BY_ID_PENDING',
  RX_GET_RESULT_BY_ID_DONE: 'RX_GET_RESULT_BY_ID_DONE',

  //ADD GAME RESULT
  CNTRL_ADD_GAME_RESULT: 'CNTRL_ADD_GAME_RESULT',
  RX_ADD_GAME_RESULT: 'RX_ADD_GAME_RESULT',
  RX_ADD_GAME_RESULT_PENDING: 'RX_ADD_GAME_RESULT_PENDING',
  RX_ADD_GAME_RESULT_DONE: 'RX_ADD_GAME_RESULT_DONE',

  //SET SELECTED GAME ID

  RX_SET_SELECTED_GAME_ID: 'RX_SET_SELECTED_GAME_ID',

  //ADD GAME
  CNTRL_ADD_GAME: 'CNTRL_ADD_GAME',
  RX_ADD_GAME: 'RX_ADD_GAME',
  RX_ADD_GAME_PENDING: 'RX_ADD_GAME_PENDING',
  RX_ADD_GAME_DONE: 'RX_ADD_GAME_DONE',

  //SET CALENDAR DATES
  RX_SET_CALENDAR_DATES: 'RX_SET_CALENDAR_DATES'
};

//PARTNER BETTYPES
export const PARTNER_BETTYPES = {
  CNTRL_GET_PARTNER_BETTYPES: 'CNTRL_GET_PARTNER_BETTYPES',
  RX_GET_PARTNER_BETTYPE_PENDING: 'RX_GET_PARTNER_BETTYPE_PENDING',
  RX_GET_PARTNER_BETTYPE_DONE: 'RX_GET_PARTNER_BETTYPE_DONE',
  RX_GET_PARTNER_BETTYPE: 'RX_GET_PARTNER_BETTYPE'
};

export const PARTNER_TYPES = {

  // partner gametype limits

  CNTRL_GET_PARTNER_GAMETYPE_LIMITS: 'CNTRL_GET_PARTNER_GAMETYPE_LIMITS',
  RX_GET_PARTNER_GAMETYPE_LIMITS: 'RX_GET_PARTNER_GAMETYPE_LIMITS',
  RX_GET_PARTNER_GAMETYPE_LIMITS_DONE: 'RX_GET_PARTNER_GAMETYPE_LIMITS_DONE',
  RX_GET_PARTNER_GAMETYPE_LIMITS_PENDING: 'RX_GET_PARTNER_GAMETYPE_LIMITS_PENDING',

  CNTRL_EDIT_PARTNER_GAMETYPE_LIMITS: 'CNTRL_EDIT_PARTNER_GAMETYPE_LIMITS',
  RX_EDIT_PARTNER_GAMETYPE_LIMITS: 'RX_EDIT_PARTNER_GAMETYPE_LIMITS',
  RX_EDIT_PARTNER_GAMETYPE_LIMITS_DONE: 'RX_EDIT_PARTNER_GAMETYPE_LIMITS_DONE',
  RX_EDIT_PARTNER_GAMETYPE_LIMITS_PENDING: 'RX_EDIT_PARTNER_GAMETYPE_LIMITS_PENDING',


  //customization config
  CNTRL_GET_PARTNER_CUSTOMIZATION_CONFIG: 'CNTRL_GET_PARTNER_CUSTOMIZATION_CONFIG',
  RX_GET_PARTNER_CUSTOMIZATION_CONFIG: 'RX_GET_PARTNER_CUSTOMIZATION_CONFIG',
  RX_GET_PARTNER_CUSTOMIZATION_CONFIG_DONE: 'RX_GET_PARTNER_CUSTOMIZATION_CONFIG_DONE',
  RX_GET_PARTNER_CUSTOMIZATION_CONFIG_PENDING: 'RX_GET_PARTNER_CUSTOMIZATION_CONFIG_PENDING',
  //set customization config
  CNTRL_SET_CUSTOMIZATION_CONFIG_COLORS: 'CNTRL_SET_CUSTOMIZATION_CONFIG_COLORS',
  // partner backgrounds
  CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS: 'CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS',
  CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS_RESET: 'CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS_RESET',
  //config
  CNTRL_GET_PARTNER_CONFIG: 'CNTRL_GET_PARTNER_CONFIG',
  RX_GET_PARTNER_CONFIG: 'RX_GET_PARTNER_CONFIG',
  RX_GET_PARTNER_CONFIG_DONE: 'RX_GET_PARTNER_CONFIG_DONE',
  RX_GET_PARTNER_CONFIG_PENDING: 'RX_GET_PARTNER_CONFIG_PENDING',

  //edit config
  CNTRL_EDIT_PARTNER_CONFIG: 'CNTRL_EDIT_PARTNER_CONFIG',

  //GET PARTNERS
  CNTRL_GET_PARTNERS: 'CNTRL_GET_PARTNERS',
  RX_GET_PARTNERS: 'RX_GET_PARTNERS',
  RX_GET_PARTNERS_PENDING: 'RX_GET_PARTNERS_PENDING',
  RX_GET_PARTNERS_DONE: 'RX_GET_PARTNERS_DONE',

  //GET SETTINGS
  CNTRL_GET_PARTNER_SETTINGS: 'CNTRL_GET_PARTNER_SETTINGS',
  RX_GET_PARTNER_SETTINGS: 'RX_GET_PARTNER_SETTINGS',
  RX_GET_PARTNER_SETTINGS_PENDING: 'RX_GET_PARTNER_SETTINGS_PENDING',
  RX_GET_PARTNER_SETTINGS_DONE: 'RX_GET_PARTNER_SETTINGS_DONE',

  //ADD PARTNER
  CNTRL_ADD_PARTNER: 'CNTRL_ADD_PARTNER',


  //partner betType config

  CNTRL_GET_PARTNER_BET_TYPE_CONFIG: 'CNTRL_GET_PARTNER_BET_TYPE_CONFIG',
  RX_GET_PARTNER_BET_TYPE_CONFIG: 'RX_GET_PARTNER_BET_TYPE_CONFIG',
  RX_GET_PARTNER_BET_TYPE_CONFIG_DONE: 'RX_GET_PARTNER_BET_TYPE_CONFIG_DONE',
  RX_GET_PARTNER_BET_TYPE_CONFIG_PENDING: 'RX_GET_PARTNER_BET_TYPE_CONFIG_PENDING',

  CNTRL_EDIT_PARTNER_BET_TYPE_CONFIG: 'CNTRL_EDIT_PARTNER_BET_TYPE_CONFIG',

  // partner main limits
  CNTRL_GET_PARTNER_MAIN_LIMITS: 'CNTRL_GET_PARTNER_MAIN_LIMITS',
  RX_GET_PARTNER_MAIN_LIMITS: 'RX_GET_PARTNER_MAIN_LIMITS',
  RX_GET_PARTNER_MAIN_LIMITS_DONE: 'RX_GET_PARTNER_MAIN_LIMITS_DONE',
  RX_GET_PARTNER_MAIN_LIMITS_PENDING: 'RX_GET_PARTNER_MAIN_LIMITS_PENDING',

  CNTRL_SET_PARTNER_MAIN_LIMITS: 'CNTRL_SET_PARTNER_MAIN_LIMITS',

  CNTRL_GET_PARTNER_CURRENCIES: 'CNTRL_GET_PARTNER_CURRENCIES',
  RX_GET_PARTNER_CURRENCIES: 'RX_GET_PARTNER_CURRENCIES',
  RX_GET_PARTNER_CURRENCIES_DONE: 'RX_GET_PARTNER_CURRENCIES_DONE',
  RX_GET_PARTNER_CURRENCIES_PENDING: 'RX_GET_PARTNER_CURRENCIES_PENDING',

  // partner bet types config

  CNTRL_GET_PARTNER_BET_TYPES_GROUP: 'CNTRL_GET_PARTNER_BET_TYPES_GROUP',
  RX_GET_PARTNER_BET_TYPES_GROUP: 'RX_GET_PARTNER_BET_TYPES_GROUP',
  RX_GET_PARTNER_BET_TYPES_GROUP_DONE: 'RX_GET_PARTNER_BET_TYPES_GROUP_DONE',
  RX_GET_PARTNER_BET_TYPES_GROUP_PENDING: 'RX_GET_PARTNER_BET_TYPES_GROUP_PENDING',
  CNTRL_ADD_PARTNER_BET_TYPE_GROUP: 'CNTRL_ADD_PARTNER_BET_TYPE_GROUP',
  CNTRL_DELETE_PARTNER_BET_TYPE_GROUP: 'CNTRL_DELETE_PARTNER_BET_TYPE_GROUP',
  CNTRL_EDIT_PARTNER_BET_TYPE_GROUP: 'CNTRL_EDIT_PARTNER_BET_TYPE_GROUP',

  // partner animals

  CNTRL_GET_PARTNER_ANIMALS: 'CNTRL_GET_PARTNER_ANIMALS',
  RX_GET_PARTNER_ANIMALS: 'RX_GET_PARTNER_ANIMALS',
  RX_GET_PARTNER_ANIMALS_DONE: 'RX_GET_PARTNER_ANIMALS_DONE',
  RX_GET_PARTNER_ANIMALS_PENDING: 'RX_GET_PARTNER_ANIMALS_PENDING',
  CNTRL_EDIT_PARTNER_ANIMALS: 'CNTRL_EDIT_PARTNER_ANIMALS',
  CNTRL_RESET_PARTNER_ANIMALS: 'CNTRL_RESET_PARTNER_ANIMALS',

  // bet type info

  CNTRL_GET_PARTNER_BET_TYPE_INFO: 'CNTRL_GET_PARTNER_BET_TYPE_INFO',
  CNTRL_EDIT_PARTNER_BET_TYPE_INFO: 'CNTRL_EDIT_PARTNER_BET_TYPE_INFO',
  RX_GET_PARTNER_BET_TYPE_INFO: 'RX_GET_PARTNER_BET_TYPE_INFO',
  RX_GET_PARTNER_BET_TYPE_INFO_DONE: 'RX_GET_PARTNER_BET_TYPE_INFO_DONE',
  RX_GET_PARTNER_BET_TYPE_INFO_PENDING: 'RX_GET_PARTNER_BET_TYPE_INFO_PENDING',

  // default animals

  CNTRL_GET_DEFAULT_ANIMALS: 'CNTRL_GET_DEFAULT_ANIMALS',
  RX_GET_DEFAULT_ANIMALS: 'RX_GET_DEFAULT_ANIMALS',
  RX_GET_DEFAULT_ANIMALS_DONE: 'RX_GET_DEFAULT_ANIMALS_DONE',
  RX_GET_DEFAULT_ANIMALS_PENDING: 'RX_GET_DEFAULT_ANIMALS_PENDING',

};

  export const SCHEDULER_TEMPLATES_TYPES ={

  //schedulerTemplate
  CNTRL_GET_SCHEDULER_TEMPLATE: 'CNTRL_GET_SCHEDULER_TEMPLATE',
  RX_GET_SCHEDULER_TEMPLATE: 'RX_GET_SCHEDULER_TEMPLATE',
  RX_GET_SCHEDULER_TEMPLATE_DONE: 'RX_GET_SCHEDULER_TEMPLATE_DONE',
  RX_GET_SCHEDULER_TEMPLATE_PENDING: 'RX_GET_SCHEDULER_TEMPLATE_PENDING',

  CNTRL_EDIT_SCHEDULER_TEMPLATE: 'CNTRL_EDIT_SCHEDULER_TEMPLATE',
  CNTRL_ADD_SCHEDULER_TEMPLATE: 'CNTRL_ADD_SCHEDULER_TEMPLATE',
  CNTRL_DELETE_SCHEDULER_TEMPLATE_BY_ID: 'CNTRL_DELETE_SCHEDULER_TEMPLATE_BY_ID',};

export const SIGNAL_R_TYPES = {
  CNTRL_SIGNAL_R_CONNECT: 'CNTRL_SIGNAL_R_CONNECT',
};

//MODAL

export const MODAL_ACTION_TYPES = {
  RX_MODAL_TOGGLE: 'RX_MODAL_TOGGLE',
  RX_MODAL_CLOSE: 'RX_MODAL_CLOSE',
};

export const TRANSLATIONS_TYPES = {
  //GET TRANSLATIONS
  CNTRL_GET_TRANSLATIONS: 'CNTRL_GET_TRANSLATIONS',
  RX_GET_TRANSLATIONS: 'RX_GET_TRANSLATIONS',
  RX_GET_TRANSLATIONS_PENDING: 'RX_GET_TRANSLATIONS_PENDING',
  RX_GET_TRANSLATIONS_DONE: 'RX_GET_TRANSLATIONS_DONE',

  CNTRL_GET_LANGUAGES: 'CNTRL_GET_LANGUAGES',
  RX_GET_LANGUAGES: 'RX_GET_LANGUAGES',
  RX_GET_LANGUAGES_PENDING: 'RX_GET_LANGUAGES_PENDING',
  RX_GET_LANGUAGES_DONE: 'RX_GET_LANGUAGES_DONE',


};
