import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const SideBarItem = ({ notRedirect, route, onClick, active, ...props }) => {

  const innerClick = _ => {
    onClick && onClick();
    props.setIsOpen && props.setIsOpen();
  };

  return (
    <NavLink to={notRedirect ? {} : route.path}>
      <ListItem button onClick={() =>  innerClick()}>
        <ListItemIcon>
          <route.icon/>
        </ListItemIcon>
        <ListItemText primary={route.name}/>
        {
          active !== null
            ?
            <ListItemIcon>
              {active ? <ChevronRightIcon/> : <ExpandMoreIcon/>}
            </ListItemIcon>
            : null
        }
      </ListItem>
    </NavLink>
  )
};

SideBarItem.defaultProps = {
  active: null,
  notRedirect: false
};

SideBarItem.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  route: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.any
  }),
  onClick: PropTypes.func,
  notRedirect: PropTypes.bool
};

export default SideBarItem;