import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix: 'Avatars',
};

class AvatarsApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix)
  }
  getAvatars(){
    return this.get()
  }

  getAvatarsById(id){
    return this.get(id)
  }

}
const avatarApiHandler = new AvatarsApiHandler(END_POINT.prefix);
export default avatarApiHandler;

