import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../../../state-management/store/index';

const Translations = ({translationKey, translations, ...props}) => {
  if(translations.isLoading) return '...';
  return <>{translations.data[translationKey] || translationKey}</>
};
const mapStateToProps = state => ({
  translations: state.translations
});

const TranslationText =  connect(mapStateToProps)(Translations);

export const translateText = (key , isValue = false) => {
 if (isValue) {
    return   store.getState().translations.data[key] ?  store.getState().translations.data[key] : key
 } return   <TranslationText translationKey={key}/>
} ;

export default TranslationText;