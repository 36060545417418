import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom'
import {dashboardRoutes,  separatelyRouters } from './router';

const SuspenseWrap = props => (<Suspense fallback={<p>Loading</p>}>{props.children}</Suspense>);

const DefaultRouts = props => {
  return (
    <Switch>
      <Redirect exact from='/' to='/dashboard'/>
      {
        separatelyRouters.concat(dashboardRoutes).map(route => {
          if (route.innerRouts && route.innerRouts.length) {
            return route.innerRouts.map(innerRoute => {
              return <Route
                exact
                key={`${route.path}${innerRoute.path}`}
                path={`${route.path}${innerRoute.path}`}
                render={() => <SuspenseWrap>
                  <innerRoute.component/>
                </SuspenseWrap>}
              />
            })

          }
          return <Route
            exact
            key={route.path}
            path={route.path}
            render={() => <SuspenseWrap>
              <route.component/>
            </SuspenseWrap>}/>
        })
      }
    </Switch>
  )
};

DefaultRouts.defaultProps = {};

DefaultRouts.propTypes = {};

export default DefaultRouts;
