import React, { Suspense } from 'react';

const defineGuards = (layers = [], Component, props) => {
  if (!layers.length) {
    return (
      <Suspense fallback={<p>Loading</p>}>
        <Component {...props}/>
      </Suspense>
    );
  }

  return (
    <>{React.createElement(layers[0], props, defineGuards(layers.slice(1), Component, props))}</>
  );
};

export default defineGuards;