import Subscriber from './Subscriber';
import TypeChecker from '../helpers/TypeChecker';

class Observer {
  #subscribers = [];

  constructor(initialState) {
    this.state = initialState;
  }

  setState(newState) {
    this.state = newState;
    this.#notify();
  }

  subscribe(callback) {
    const subscriber = new Subscriber(callback);
    this.#subscribers.push(subscriber);
    return subscriber.id;
  }

  unSubscribe (id) {
    this.#subscribers = this.#subscribers.filter(subscriber => subscriber.id !== id);
  }


  #notify = () => {
    console.log(this.#subscribers);
    this.#subscribers.forEach(subscriber => {
      TypeChecker.isFunction(subscriber.callBack) && subscriber.callBack(this.state);
    })
  }

}

export default Observer