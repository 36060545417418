import * as controllers from './controllerFactory';
import * as TYPES from '../core/constants/actionTypes'

const configs = {
  [TYPES.SIGNAL_R_TYPES.CNTRL_SIGNAL_R_CONNECT]: controllers.signalRController.connect.bind(controllers.signalRController),



  [TYPES.APP_GLOBAL_TYPES.CNTRL_APP_INIT]: controllers.appController.init.bind(controllers.appController),
  //token , auth
  [TYPES.AUTH_TYPES.CNTRL_LOG_IN]: controllers.tokenController.login.bind(controllers.tokenController),
  [TYPES.AUTH_TYPES.CNTRL_REFRESH_TOKEN]: controllers.tokenController.refreshToken.bind(controllers.tokenController),
  [TYPES.AUTH_TYPES.CNTRL_LOGOUT]: controllers.tokenController.logOut.bind(controllers.tokenController),
  //users
  [TYPES.USER_TYPES.CNTRL_GET_USERS]: controllers.usersController.getUsers.bind(controllers.usersController),
  [TYPES.USER_TYPES.CNTRL_GET_USERS_SETTINGS]: controllers.usersController.getSettings.bind(controllers.usersController),
  [TYPES.USER_TYPES.CNTRL_EDIT_USER_LANGUAGE]: controllers.usersController.editLanguage.bind(controllers.usersController),
  [TYPES.USER_TYPES.CNTRL_GET_USER_BALANCE]: controllers.usersController.getUserBalance.bind(controllers.usersController),
  [TYPES.USER_TYPES.CNTRL_EDIT_USER_SETTINGS]: controllers.usersController.editUser.bind(controllers.usersController),
  [TYPES.USER_TYPES.CNTRL_EDIT_USER_CURRENCY]: controllers.usersController.editCurrency.bind(controllers.usersController),
  //currencies
  [TYPES.CURRENCIES_TYPES.CNTRL_GET_CURRENCIES]: controllers.currenciesController.getCurrencies.bind(controllers.currenciesController),
  [TYPES.CURRENCIES_TYPES.CNTRL_GET_CURRENCY_BY_CODE]: controllers.currenciesController.getCurrenciesCode.bind(controllers.currenciesController),

  //reports
  [TYPES.REPORT_TYPES.CNTRL_GET_BET_REPORT]: controllers.reportsController.getBetReport.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_BET_TYPE_REPORT]: controllers.reportsController.getBetTypeReport.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_GAME_REPORT]: controllers.reportsController.getGameReport.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_USER_REPORT]: controllers.reportsController.getUserReport.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_PARTNER_FINANCAL_REPORT] : controllers.reportsController.getPartnersFinancalReport.bind(controllers.reportsController),
  //REPORTS FOR DASHBOARD
  [TYPES.REPORT_TYPES.CNTRL_GET_REPORTS_ACTIVITY]: controllers.reportsController.getReportsActivity.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_TOP_BET_TYPES]: controllers.reportsController.getTopBetTypeReport.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_REPORTS_BY_STAKE]: controllers.reportsController.getReportsByStake.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_REPORTS_BY_WINNING]: controllers.reportsController.getReportByWinning.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_REPORTS_CONFIGS]: controllers.reportsController.getDashboardConfigs.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_SET_REPORTS_CONFIGS]: controllers.reportsController.setReportsConfigs.bind(controllers.reportsController),
  [TYPES.REPORT_TYPES.CNTRL_GET_DASHBOARD_DATA]: controllers.reportsController.getDashBoardData.bind(controllers.reportsController),

  //partner
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_GAMETYPE_LIMITS] :controllers.partnersController.getPartnerGameTypeLimits.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_GAMETYPE_LIMITS] : controllers.partnersController.editPartnerGameTypeLimits.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_CUSTOMIZATION_CONFIG]: controllers.partnersController.getCustomizationConfig.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_COLORS]: controllers.partnersController.setCustomizationConfigColors.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS]: controllers.partnersController.setCustomizationConfigBackgrounds.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_SET_CUSTOMIZATION_CONFIG_BACKGROUNDS_RESET]: controllers.partnersController.resetCustomizationConfigBackgrounds.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_CONFIG]: controllers.partnersController.getPartnerConfig.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_CONFIG]: controllers.partnersController.editPartnerConfig.bind(controllers.partnersController),

  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_SETTINGS]: controllers.partnersController.getPartnerSettings.bind(controllers.partnersController),
  [TYPES.PARTNER_BETTYPES.CNTRL_GET_PARTNER_BETTYPES]: controllers.partnersController.getPartnersBetTypesById.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNERS]: controllers.partnersController.getPartners.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_ADD_PARTNER]: controllers.partnersController.addPartner.bind(controllers.partnersController),

  //partner bet type config
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPE_CONFIG]: controllers.partnersController.getPartnerBetTypeConfig.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_CONFIG]: controllers.partnersController.setPartnerBetTypeConfig.bind(controllers.partnersController),


  //partner main limits
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_MAIN_LIMITS]: controllers.partnersController.getPartnerMainLimits.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_SET_PARTNER_MAIN_LIMITS]: controllers.partnersController.setPartnerMainLimits.bind(controllers.partnersController),

  //partner currencies
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_CURRENCIES] : controllers.partnersController.getPartnerCurrencies.bind(controllers.partnersController),
  //partner bet types group
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPES_GROUP]: controllers.partnersController.getPartnerBetTypesGroups.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_ADD_PARTNER_BET_TYPE_GROUP]: controllers.partnersController.addPartnerBetTypesGroup.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_DELETE_PARTNER_BET_TYPE_GROUP]: controllers.partnersController.deletePartnerBetTypesGroup.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_GROUP]: controllers.partnersController.editPartnerBetTypesGroup.bind(controllers.partnersController),

  //partner animals
  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_ANIMALS]: controllers.partnersController.getPartnerAnimals.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_ANIMALS]: controllers.partnersController.editPartnerAnimals.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_RESET_PARTNER_ANIMALS]: controllers.partnersController.resetPartnerAnimals.bind(controllers.partnersController),

  // partner bet type info

  [TYPES.PARTNER_TYPES.CNTRL_GET_PARTNER_BET_TYPE_INFO]: controllers.partnersController.getPartnerBetTypeInfo.bind(controllers.partnersController),
  [TYPES.PARTNER_TYPES.CNTRL_EDIT_PARTNER_BET_TYPE_INFO]: controllers.partnersController.editPartnerBetTypeInfo.bind(controllers.partnersController),


  //animals
  [TYPES.PARTNER_TYPES.CNTRL_GET_DEFAULT_ANIMALS]: controllers.animalsController.getDefaultAnimals.bind(controllers.animalsController),

  // * schedulerTemplates

  [TYPES.SCHEDULER_TEMPLATES_TYPES.CNTRL_GET_SCHEDULER_TEMPLATE]: controllers.schedulerTemplatesController.getSchedulerTemplates.bind(controllers.schedulerTemplatesController),
  [TYPES.SCHEDULER_TEMPLATES_TYPES.CNTRL_ADD_SCHEDULER_TEMPLATE]: controllers.schedulerTemplatesController.addSchedulerTemplate.bind(controllers.schedulerTemplatesController),
  [TYPES.SCHEDULER_TEMPLATES_TYPES.CNTRL_EDIT_SCHEDULER_TEMPLATE]: controllers.schedulerTemplatesController.setSchedulerTemplate.bind(controllers.schedulerTemplatesController),
  [TYPES.SCHEDULER_TEMPLATES_TYPES.CNTRL_DELETE_SCHEDULER_TEMPLATE_BY_ID]: controllers.schedulerTemplatesController.deleteSchedulerTemplate.bind(controllers.schedulerTemplatesController),

  //games
  [TYPES.GAME_TYPES.CNTRL_GET_GAMES]: controllers.gamesController.getGames.bind(controllers.gamesController),
  [TYPES.GAME_TYPES.CNTRL_GET_GAME_BY_ID]: controllers.gamesController.getGameById.bind(controllers.gamesController),
  [TYPES.GAME_TYPES.CNTRL_CANCEL_GAME]: controllers.gamesController.cancelGame.bind(controllers.gamesController),
  [TYPES.GAME_TYPES.CNTRL_GET_RESULT_BY_ID]: controllers.gamesController.getGameResultById.bind(controllers.gamesController),
  [TYPES.GAME_TYPES.CNTRL_ADD_GAME_RESULT]: controllers.gamesController.addGameResultById.bind(controllers.gamesController),
  [TYPES.GAME_TYPES.CNTRL_ADD_GAME]: controllers.gamesController.addGame.bind(controllers.gamesController),

  //TRANSLATIONS
  [TYPES.TRANSLATIONS_TYPES.CNTRL_GET_TRANSLATIONS]: controllers.translationsController.getTranslationsByID.bind(controllers.translationsController),
  [TYPES.TRANSLATIONS_TYPES.CNTRL_GET_LANGUAGES]: controllers.languagesController.getLanguages.bind(controllers.languagesController),
};

export default configs;
