import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class SchedulerTemplatesOperation extends Operations {
    constructor(schedulerTemplatesApiHandler) {
        super();
        this.schedulerTemplatesApiHandler = schedulerTemplatesApiHandler;
    }

    async getSchedulerTemplates(store, action) {
        const response = await this.schedulerTemplatesApiHandler.getSchedulerTemplates(action.payload);
        return responseErrorCheck(response);
    }

    async setSchedulerTemplate(store, action) {
        const response = await this.schedulerTemplatesApiHandler.setSchedulerTemplate(action.payload);
        return responseErrorCheck(response);
    }

    async addSchedulerTemplate(store, action) {
        const response = await this.schedulerTemplatesApiHandler.addSchedulerTemplate(action.payload);
        return responseErrorCheck(response);
    }

    async deleteSchedulerTemplate(store, action) {
        const response = await this.schedulerTemplatesApiHandler.deleteSchedulerTemplate(action.payload);
        return responseErrorCheck(response);
    }

}

export default SchedulerTemplatesOperation;
