import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { REPORT_TYPES } from '../../core/constants/actionTypes';
import { combineReducers } from 'redux';
import createReducer from '../../core/helpers/createReducer';

export const betReportReducer = combineReducers({
  betReport : createAsyncReducer(REPORT_TYPES.RX_GET_BET_REPORT, {
    count : 0,
    betReports: []
  }),
  pageNumber:createReducer(0, {
  [REPORT_TYPES.RX_SET_BETREPORT_PAGE_NUMBER] : (state, action)=> action.payload
  }),
  rowsCount : createReducer(10, {
   [REPORT_TYPES.RX_SET_BETREPORT_ROWS_COUNT] : (state, action) => action.payload
  })
});

export const betTypeReportReducer = combineReducers({
  betTypeReport : createAsyncReducer(REPORT_TYPES.RX_GET_BET_TYPE_REPORT, {
    count : 0,
    betTypeReports : []
  }),
  pageNumber : createReducer( 0, {
    [REPORT_TYPES.RX_SET_BET_TYPE_REPORT_PAGE_NUMBER] : (state, action) => action.payload
  }),
  rowsCount : createReducer(10, {
    [REPORT_TYPES.RX_SET_BET_TYPE_REPORT_ROWS_COUNT] : (state, action ) => action.payload
  })
});

export const gameReportReducer = combineReducers({
  gameReport : createAsyncReducer(REPORT_TYPES.RX_GET_GAME_REPORT, {
    count : 0,
    gameReports : []
  }),
  pageNumber : createReducer( 0, {
    [REPORT_TYPES.RX_SET_GAME_REPORT_PAGE_NUMBER] : (state, action) => action.payload
  } ),
  rowsCount : createReducer(10, {
    [REPORT_TYPES.RX_SET_GAME_REPORT_ROWS_COUNT] : (state, action) => action.payload
  })
})

export const userReportReducer = combineReducers({
  userReport : createAsyncReducer(REPORT_TYPES.RX_GET_USER_REPORT, {
    count : 0,
    userReports : []
  }),
  pageNumber : createReducer(0, {
    [REPORT_TYPES.RX_SET_PLAYERS_PAGE_NUMBER] : (state, action) => action.payload,
  }),
  rowsCount : createReducer(10, {
    [REPORT_TYPES.RX_SET_PLAYERS_ROWS_COUNT] : (state, action) => action.payload
  })
});

export const partnersFinancalReportReducer = createAsyncReducer(REPORT_TYPES.RX_GET_PARTNER_FINANCAL_REPORT, []);

export const dashBoard = combineReducers({
 configs: createAsyncReducer(REPORT_TYPES.RX_GET_REPORTS_CONFIGS, []),
 activities: createAsyncReducer(REPORT_TYPES.RX_GET_REPORTS_ACTIVITY, {}),
 topBetTypes: createAsyncReducer(REPORT_TYPES.RX_GET_TOP_BET_TYPES, []),
 betsByWinning: createAsyncReducer(REPORT_TYPES.RX_GET_REPORTS_BY_WINNING, []),
 betsByStake: createAsyncReducer(REPORT_TYPES.RX_GET_REPORTS_BY_STAKE, []),
});