import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../../../core/constants/colors';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { addPartnerModalInputs } from '../../../../core/constants/InputsConstants';
import TextField from '@material-ui/core/TextField';
import { translateText } from '../../../components/translation/index';
import Icon from '@material-ui/core/Icon';
import { rxModalClose } from '../../../../state-management/actions/modalActions';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { cntrlAddPartner } from '../../../../state-management/actions/partnerActions';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    width: '500px',
    minWidth: '250px',
    background: '#fff',
    border: `1px solid ${colors.headerColor}`,
    borderRadius: '5px',
    fontSize: '1.1em',
    overflowY: 'hidden',
    fontWeight: '500',
    paddingBottom: '30px'
  },
  header: {
    height: '50px',
    marginBottom: '20px',
    background: colors.headerColor,
    padding: '0 10px',
    color: '#fff'
  },
  selectContent: {
    marginTop: '10px'
  },
  icon: {
    cursor: 'pointer'
  },
  button: {
    background: colors.buttonColor,
    marginTop: '20px',
    '&:hover': {
      background: colors.headerColor,
      color: '#fff'
    }
  },
  formControl: {
    maxWidth: '100%'
  },
  buttonContent: {
    marginTop: 10
  }
});

const AddPartnerModal = React.memo(({ classes, ...props }) => {

  const [fields, setField] = useState({});
  const [multipleLanguagesValue, setMultipleLanguagesValue] = useState([]);
  const [multipleCurrencyValue, setMultipleCurrencyValue] = useState([]);
  const [defaultConfig, setDefaultConfig] = useState(false);
  const handleInputChange = (key, value) => {
    setField(prevState => ({
      ...prevState, [key]: value
    }))
  };

  const addPartner = _ => {
    props.addPartner(fields).then(_ => props.close())
  };

  const handleMultipleLanguagesChange = e => {
    setMultipleLanguagesValue(e.target.value);
    setField(state => ({
      ...state,
      backofficeLanguages: e.target.value.map(id => ({ languageId: id }))

    }))

  };
  const handleMultipleCurrencyChange = e => {
    setMultipleCurrencyValue(e.target.value);
    setField(state => ({
      ...state,
      currencies: e.target.value
    }))

  };

  const handleCheckDefaultConfig = e => {
    setDefaultConfig(e.target.checked);
    handleInputChange("DefaultConfig", e.target.checked);
  };

  return (
    <div className={classes.root}>
      <Grid container justify={'center'}>
        <Grid container className={classes.header} justify={'space-between'} alignItems={'center'}>
          <Grid item>
            <p>{translateText('ADD_A_PARTNER')} </p>
          </Grid>
          <Grid item>
            <Icon onClick={props.close} className={classes.icon}>close</Icon>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={3}>
            {
              addPartnerModalInputs.map((i, index) => (
                <Grid item xs={i.xs} key={`${index}`}>
                  <TextField
                    value={fields[i.name] ? fields[i.name] : ''}
                    className={classes.input}
                    required={i.required}
                    fullWidth={i.fullWidth}
                    name={i.name}
                    label={i.label}
                    margin={i.margin}
                    onChange={e => handleInputChange(e.target.name, i.name !== 'id' ? e.target.value : Number(e.target.value))}
                    type={i.type && i.type}
                  />
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.selectContent}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {/*<Grid item xs={6}>*/}
                  {/*  <Grid container justify={'flex-start'}>*/}
                  {/*    <FormControl className={classes.formControl}>*/}
                  {/*      <Select*/}
                  {/*        value={multipleCurrencyValue}*/}
                  {/*        onChange={handleMultipleCurrencyChange}*/}
                  {/*        MenuProps={{*/}
                  {/*          style: {*/}
                  {/*            height: '300px'*/}
                  {/*          }*/}
                  {/*        }}*/}
                  {/*        multiple*/}
                  {/*      >*/}
                  {/*        {*/}
                  {/*          props.currencies.data.map(option => <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>)*/}
                  {/*        }*/}
                  {/*      </Select>*/}
                  {/*      <FormHelperText>{translateText('BACK_OFFICE_CURRENCIES')}</FormHelperText>*/}
                  {/*    </FormControl>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                  <Grid item xs={6}>
                    <Grid container justify={'flex-start'}>
                      <FormControl className={classes.formControl}>
                        <Select
                          value={multipleLanguagesValue}
                          onChange={handleMultipleLanguagesChange}
                          MenuProps={{
                            style: {
                              height: '300px'
                            }
                          }}
                          multiple
                        >
                          {
                            props.languages.data.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{translateText('BACK_OFFICE_LANGUAGES')}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonContent}>
                <Grid container justify={'space-between'}>
                  <TextField
                    select
                    onChange={e => handleInputChange('currencyName', e.target.value)}
                    value={fields.currencyName ? fields.currencyName : ''}
                    helperText={translateText('CURRENCY')}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          height: '300px'
                        }

                      }
                    }}
                  >
                    {
                      props.currencies.data.map(option => {
                        return <MenuItem key={option.id} value={option.name}>{option.name} </MenuItem>
                      })
                    }
                  </TextField>
                  <TextField
                    select
                    onChange={e => handleInputChange('languageId', e.target.value)}
                    value={fields.languageId ? fields.languageId : ''}
                    helperText={translateText('LANGUAGE')}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          height: '300px'
                        }

                      }
                    }}
                  >
                    {
                      props.languages.data.map(option => {
                        return <MenuItem key={option.id} value={option.id}>{option.name} </MenuItem>
                      })
                    }
                  </TextField>
                  {/*<FormControlLabel*/}
                  {/*    control={<Checkbox checked={defaultConfig} onChange={(e) => handleCheckDefaultConfig(e)}  name="defaultConfig" />}*/}
                  {/*    label={translateText('DEFAULT_CONFIG')}*/}
                  {/*/>*/}
                  <Button variant="contained"
                          className={classes.button}
                          onClick={addPartner}
                  >
                    {translateText('ADD')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});


const mapStateToProps = state => ({
  currencies: state.currencies,
  languages: state.languages
});

const mapDispatchToProps = dispatch => ({
  close: _ => dispatch(rxModalClose()),
  addPartner: data => dispatch(cntrlAddPartner(data))

});

AddPartnerModal.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddPartnerModal));
