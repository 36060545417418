import Controller from '../../core/classes/AppController';
import { rxGetTranslationsDone, rxGetTranslationsPending } from '../../state-management/actions/translationsActions';

class TranslationsController extends Controller {
  constructor(translationsOperations) {
    super();
    this.translationsOperations = translationsOperations;
  }


  async getTranslationsByID(store, action) {
    try {
      store.dispatch(rxGetTranslationsPending());
      const response = await this.translationsOperations.getTranslationsByID(store, action);
      store.dispatch(rxGetTranslationsDone(response));
      return response;
    }catch (e) {
      return e;
    }
  }
}

export default TranslationsController;