import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";
import { cntrlGetTranslations } from "../../../state-management/actions/translationsActions";

class PartnersOperation extends Operations {
  constructor(partnersApiHandler) {
    super();
    this.partnersApiHandler = partnersApiHandler;
  }

  async getPartners(store, action) {
    const response = await this.partnersApiHandler.getPartners();
    if (response.result && response.result.length) {
      response.result.unshift({ name: "All Partners", id: -1 });
      return response.result;
    }
    return responseErrorCheck(response);
  }
  async getPartnerGameTypeLimits(store, action) {
    const response = await this.partnersApiHandler.getPartnerGameTypeLimits(action.payload);
    return responseErrorCheck(response);
  }
  async editPartnerGameTypeLimits(store, action) {
    const response = await this.partnersApiHandler.editPartnerGameTypeLimits(action.payload);
    return responseErrorCheck(response);
  }

  async getPartnerSettings(store, action) {
    const response = await this.partnersApiHandler.getPartnerSettings();
    return responseErrorCheck(response);
  }

  async addPartner(store, action) {
    const response = await this.partnersApiHandler.addPartner(action.payload);
    return responseErrorCheck(response);
  }

  async addPartners(store, action) {
    const response = await this.partnersApiHandler.addPartners();
    return responseErrorCheck(response);
  }

  async deletePartnersById(store, action) {
    const response = await this.partnersApiHandler.deletePartnersById();
    return responseErrorCheck(response);
  }

  async setPartnersStatusById(store, action) {
    const response = await this.partnersApiHandler.setPartnersStatusById();
    return responseErrorCheck(response);
  }

  async getPartnersBetTypesById(store, action) {
    const response = await this.partnersApiHandler.getPartnersBetTypesById();
    return responseErrorCheck(response);
  }

  async getPartnerCustomizationConfigById(store, action) {
    const response = await this.partnersApiHandler.getPartnerCustomizationConfigById(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async setPartnerCustomizationConfigColors(store, action) {
    const { payload } = action;
    let { partnerCustomizationConfig } = store.getState();
    partnerCustomizationConfig = JSON.parse(
      JSON.stringify(partnerCustomizationConfig)
    );
    partnerCustomizationConfig.data.colors = payload.colors;
    return await this.putPartnerCustomizationConfigById(store, {
      payload: partnerCustomizationConfig.data,
      id: payload.partnerValue
    });
  }
  async setPartnerCustomizationConfigBackgrounds(data) {
    const response = await this.partnersApiHandler.setPartnerCustomizationConfigBackgrounds(data);
    return responseErrorCheck(response);
  }
  async resetPartnerBackgrounds(data) {
    const response = await this.partnersApiHandler.resetPartnerBackgrounds(data);
    return responseErrorCheck(response);
  }

  async putPartnerCustomizationConfigById(store, action, isFormData) {
    const response = await this.partnersApiHandler.putPartnerCustomizationConfigById(
      action.payload,
      action.id,
      isFormData
    );
    return responseErrorCheck(response);
  }

  async getPartnerSettingsById(store, action) {
    const response = await this.partnersApiHandler.getPartnerSettingsById();
    return responseErrorCheck(response);
  }

  async getPartnerAnimalsById(store, action) {
    const response = await this.partnersApiHandler.getPartnerAnimalsById();
    return responseErrorCheck(response);
  }

  async addPartnerAnimalsById(store, action) {
    const response = await this.partnersApiHandler.addPartnerAnimalsById();
    return responseErrorCheck(response);
  }

  async getPartnerHelpItemsById(store, action) {
    const response = await this.partnersApiHandler.getPartnerHelpItemsById();
    return responseErrorCheck(response);
  }

  async getPartnersConfigById(store, id) {
    const response = await this.partnersApiHandler.getPartnersConfigById(id);
    return responseErrorCheck(response);
  }

  async addPartnersConfig(store, action) {
    const response = await this.partnersApiHandler.addPartnersConfig(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async addPartnerCurrencyById(store, action) {
    const response = await this.partnersApiHandler.addPartnerCurrencyById();
    return responseErrorCheck(response);
  }

  async deletePartnerCurrencyById(store, action) {
    const response = await this.partnersApiHandler.deletePartnerCurrencyById();
    return responseErrorCheck(response);
  }

  async getPartnersSchedulerTemplate(store, action) {
    const response = await this.partnersApiHandler.getPartnersSchedulerTemplate(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async setPartnersSchedulerTemplate(store, action) {
    const response = await this.partnersApiHandler.setPartnersSchedulerTemplate(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async addPartnersSchedulerTemplate(store, action) {
    const response = await this.partnersApiHandler.addPartnersSchedulerTemplate(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async deletePartnersSchedulerTemplate(store, action) {
    const response = await this.partnersApiHandler.deletePartnersSchedulerTemplate(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async getPartnerBetTypeConfigs(store, action) {
    const response = await this.partnersApiHandler.getPartnerBetTypeConfigs(
      action.payload.defaultCurrencyId,
      action.payload.partnerValue
    );
    return responseErrorCheck(response);
  }

  async addPartnerBetTypeConfigs(store, action) {
    const response = await this.partnersApiHandler.addPartnerBetTypeConfigs(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async setPartnerBetTypeConfigs(store, action) {
    const response = await this.partnersApiHandler.setPartnerBetTypeConfigs(
      action.payload.partnerBetConfData,
      action.payload.partnerValue
    );
    return responseErrorCheck(response);
  }

  async getPartnerMainLimits(store, action) {
    const response = await this.partnersApiHandler.getPartnerMainLimits(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async postPartnerMainLimits(store, action) {
    const response = await this.partnersApiHandler.postPartnerMainLimits(
      action.payload.limitsEditData,
      action.payload.partnerValue
    );
    return responseErrorCheck(response);
  }

  async getPartnerCurrencies(store, action) {
    const response = await this.partnersApiHandler.getPartnerCurrencies(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async getPartnerBetTypesGroups(store, action) {
    const response = await this.partnersApiHandler.getPartnerBetTypesGroups(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async addPartnerBetTypesGroup(store, action) {
    const response = await this.partnersApiHandler.addPartnerBetTypesGroup(
      action.payload
    );
    return responseErrorCheck(response);
  }
  async deletePartnerBetTypesGroup(store, action) {
    const response = await this.partnersApiHandler.deletePartnerBetTypesGroup(
      action.payload
    );
    return responseErrorCheck(response);
  }
  async editPartnerBetTypesGroup(store, action) {
    const response = await this.partnersApiHandler.editPartnerBetTypesGroup(
      action.payload
    );
    return responseErrorCheck(response);
  }

  // partner bet type info

  async getPartnerBetTypeInfo(store, action) {
    const response = await this.partnersApiHandler.getPartnerBetTypeInfo(
        action.payload
    );
    return responseErrorCheck(response);
  }
  async editPartnerBetTypeInfo(data) {
    const response = await this.partnersApiHandler.editPartnerBetTypeInfo(data);
    return responseErrorCheck(response);
  }

  //partner animals

  async getPartnerAnimals(store, action) {
    const response = await this.partnersApiHandler.getPartnerAnimals(
      action.payload
    );
    return responseErrorCheck(response);
  }

  async editPartnerAnimals(data) {
    const response = await this.partnersApiHandler.editPartnerAnimals(data);
    return responseErrorCheck(response);
  }
  async resetPartnerAnimals(data) {
    const response = await this.partnersApiHandler.resetPartnerAnimals(data);
    return responseErrorCheck(response);
  }
}

export default PartnersOperation;
