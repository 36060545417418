import React from 'react';
import './style.css'
import { Paper, Button, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles/index';
import { connect } from 'react-redux';
import ConfigItem from './ConfigItem/ConfigItem';
import {
  cntrlSetReportsConfigurations,
  rxGetReportsConfigurationsDone
} from '../../../../state-management/actions/reportAction';
import MODAL_NAMES from '../../../../core/constants/modalNames';
import { rxModalClose } from '../../../../state-management/actions/modalActions';
import { translateText } from '../../../components/translation';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    minWidth: 250,
    // minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 390,
  },

}));

function DashBoardConfig({ configs, setConfigs, postConfigs, closeModal, ...props }) {
  let copyConfigs = configs.data.dashboard &&   configs.data.dashboard.map(item => ({ ...item }));

  const classes = useStyles();

  const dashBoardConfigMutator = (blockName, enabled) => {
    if (copyConfigs && copyConfigs.length) {
      const index = copyConfigs.findIndex(item => item.blockName === blockName);
      if (~index) {
        copyConfigs[index].enable = enabled;
      }
    }
  };

  const renderConfigs = configs => {
    if (configs.data && configs.data.dashboard) {
      return configs.data.dashboard.map(configItem => <ConfigItem key={configItem.blockName} data={configItem}
                                                                  mutator={(blockName, enabled) => dashBoardConfigMutator(blockName, enabled)}/>)
    }
  };

  const setConfigsData = data => {
    closeModal(MODAL_NAMES.DASHBOARD_CONFIG);
    const { dashboard } = data;
    setConfigs(data);
    postConfigs(dashboard);
  };
  return (
    <Paper component='div' className={classes.root}>
      <Typography variant="subtitle1" gutterBottom align={'center'}>
        {translateText("CONFIGS")}
      </Typography>
      <div className="dashboard-config-items-container">
        {
          renderConfigs(configs)
        }
      </div>
      <div className='dashboard-configs-buttons-block'>
        <Button onClick={() => setConfigsData({ dashboard: copyConfigs })} variant="contained"
                color="primary">{translateText("SAVE")}</Button>
        <Button onClick={() => closeModal(MODAL_NAMES.DASHBOARD_CONFIG)} variant="contained"
        >{translateText("CANCEL")}</Button>
      </div>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    configs: state.dashBoard.configs
  }
};
const mapDispatchToProps = dispatch => {
  return {
    setConfigs: state => dispatch(rxGetReportsConfigurationsDone(state)),
    postConfigs: data => dispatch(cntrlSetReportsConfigurations(data)),
    closeModal: data => dispatch(rxModalClose(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardConfig);
