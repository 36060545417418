import React, { version } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './view/app/App';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Switch, Route, } from 'react-router-dom'
import LoginPage from './view/page/login';
import {dashboardRoutes,  separatelyRouters } from './routing/router';
import store from './state-management/store';
import Splash from './view/containers/splash';
import defineGuards from './routing/defineGuards/defineGuards';
import { IsAuthLayer, IsNotAuthLayer } from './routing/Layers';
import { Provider } from 'react-redux';
import './polifils';
import Observer from './core/classes/Observer';

console.log('version', version);

const dashboardPath = [];


dashboardRoutes.concat({ path: '/' }).concat(separatelyRouters).forEach(route => {
  if (route.innerRouts && route.innerRouts.length) {
    route.innerRouts.forEach(inner => {
      dashboardPath.push(`${route.path}${inner.path}`)
    })
  } else {
    dashboardPath.push(route.path)
  }
});

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Splash>
        <Switch>
          <Route path='/login' render={props => defineGuards([IsNotAuthLayer], LoginPage, props)}/>
          <Route path={dashboardPath} render={props => defineGuards([IsAuthLayer], App, props)}/>
          <Route path={'*'} render={props => <div>No Page</div>}/>
        </Switch>
      </Splash>
    </HashRouter>
  </Provider>, document.getElementById('root'));

serviceWorker.unregister();
