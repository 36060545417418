import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import { TRANSLATION_HEADERS } from '../../../core/constants/headers';
import translation from '../../../core/utils/translatiation';

class TranslationsOperations extends Operations {
  constructor(translationsApiHandler) {
    super();
    this.translationsApiHandler= translationsApiHandler;
  }

  async getTranslationsByID(store, action) {
    if (action.payload) {
      const query = { LanguageId: action.payload, ApplicationId: TRANSLATION_HEADERS.ApplicationId };
      const response = await this.translationsApiHandler.getTranslationsByID(query);
      if (response.result && Object.keys(response.result).length) {
        return responseErrorCheck(response);
      } else {
        return translation
      }
    }
    return translation
  }

}

export default TranslationsOperations;