import Controller from '../../core/classes/AppController';
import {
  cntrlGetGames, rxAddGameResultDone, rxAddGameResultPending, rxAddSkipTakeState, rxGetGamesDone,
  rxGetGamesPending, rxGetResultByIdDone, rxGetResultByIdPending
} from '../../state-management/actions/gameActions';
import { rxGetBetTypeReportDone } from '../../state-management/actions/reportAction';
import { rxModalClose } from '../../state-management/actions/modalActions';

class GamesController extends Controller{
  constructor(gamesOperation){
    super();
    this.gamesOperation = gamesOperation;
  }

  async getGames(store, action){
    try {
      store.dispatch(rxGetGamesPending());
      const response = await this.gamesOperation.getGames(store, action);
      store.dispatch(rxGetGamesDone(response))
    }
    catch (e){
      return new Error(e)
    }
  }

  async getGameById(store, action){
    try {
      store.dispatch(rxGetGamesPending());
      const response = await this.gamesOperation.getGameById(store, action);
      const res = {
        count: 1,
        gamesWithTotalStake: [
          {...response}
        ]
      };
      store.dispatch(rxGetGamesDone(res))
    }
    catch (e){
      return new Error(e)
    }
  }
 async cancelGame(store, action){
    try {
      const response = await this.gamesOperation.cancelGame(store, action);
      const {gamesRowsCount} = store.getState().gameReducer;
      store.dispatch(cntrlGetGames({Skip: 0, Take: gamesRowsCount}));
      return response
    }
    catch (e){
      return new Error(e)
    }
  }
  async getGameResultById(store, action){
    try {
      store.dispatch(rxGetResultByIdPending());
      const response = await this.gamesOperation.getGameResultById(store, action);
      store.dispatch(rxGetResultByIdDone(response));

      return response
    }
    catch (e){
      return new Error(e)
    }
  }
  async addGameResultById(store, action){
    try {
      await this.gamesOperation.addGameResultById(store, action);
      store.dispatch(rxModalClose());
    }
    catch (e){
      return new Error(e)
    }
  }

  async addGame(store, action){
    try {
      await this.gamesOperation.addGame(store, action);
      const {gamesRowsCount, gamesPage} = store.getState().gameReducer;
      store.dispatch(cntrlGetGames({Skip: gamesRowsCount * gamesPage, Take: gamesRowsCount}));
      store.dispatch(rxModalClose());
    }
    catch (e){
      return new Error(e)
    }
  }
}

export default GamesController;
