import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FormHelperText } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { cntrlLogin, rxSetIsAuth } from '../../../state-management/actions/tokenActions';
import { connect } from 'react-redux';
import { defaultPath } from '../../../routing/router';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const LoginPage = (props) => {
  const { classes, signIn } = props;

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);

  return (
    <main className={classes.main}>
      <CssBaseline/>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth error={isError}>
            <InputLabel htmlFor="email">Login</InputLabel>
            <Input value={login}

                   onChange={event => setLogin(event.target.value)}
                   id="email"
                   name="login"
                   autoComplete="email"
                   autoFocus/>
          </FormControl>
          <FormControl margin="normal" required fullWidth error={isError}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input value={password}

                   onChange={event => setPassword(event.target.value)}
                   name="password"
                   type="password"
                   id="password"
                   autoComplete="current-password"/>
            <FormHelperText id="component-error-text">{
              isError ? 'Invalid username or password' : null
            } </FormHelperText>
          </FormControl>

          <Button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              signIn(login.trim(), password.trim()).then(res => {
                if (!(res.hasOwnProperty('error'))) {
                  props.history.push(defaultPath);
                  props.setIsAuth(true);
                } else {
                  setIsError(true)
                }
              })
            }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign in
          </Button>
        </form>
      </Paper>
    </main>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  signIn: (userName, password) => dispatch(cntrlLogin(userName, password)),
  setIsAuth: bool => dispatch(rxSetIsAuth(bool))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginPage));