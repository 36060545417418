import * as ApiHandlers from "../services/api";
import * as Controllers from "../controllers";
import * as Operations from "../services/operations";

// operations
const tokenOperations = new Operations.TokenOperation(
  ApiHandlers.tokenApiHandler
);
const usersOperations = new Operations.UsersOperation(
  ApiHandlers.usersApiHandler
);
const currenciesOperation = new Operations.CurrenciesOperation(
  ApiHandlers.currenciesApiHandler
);
const reportsOperation = new Operations.ReportsOperation(
  ApiHandlers.reportsApiHandler,
  ApiHandlers.usersApiHandler
);
const partnersOperation = new Operations.PartnersOperation(
  ApiHandlers.partnersApiHandler
);
const schedulerTemplatesOperation = new Operations.SchedulerTemplatesOperation(
  ApiHandlers.schedulerTemplatesApiHandler
);
const gamesOperations = new Operations.GamesOperation(
  ApiHandlers.gamesApiHandler
);
const translationsOperations = new Operations.TranslationsOperations(
  ApiHandlers.translationsApi
);
const languagesOperations = new Operations.LanguagesOperations(
  ApiHandlers.languagesApiHandler,
  ApiHandlers.partnersApiHandler
);
const animalsOperations = new Operations.AnimalsOperation(
  ApiHandlers.animalsApiHandler
);

//controllers

const tokenController = new Controllers.TokenController(tokenOperations);
const appController = new Controllers.AppController();
const usersController = new Controllers.UsersController(usersOperations);
const currenciesController = new Controllers.CurrenciesController(
  currenciesOperation
);
const reportsController = new Controllers.ReportsController(reportsOperation);
const partnersController = new Controllers.PartnersController(
  partnersOperation
);
const schedulerTemplatesController = new Controllers.SchedulerTemplatesController(
  schedulerTemplatesOperation
);
const gamesController = new Controllers.GamesController(gamesOperations);
const signalRController = new Controllers.SignalRController();
const translationsController = new Controllers.TranslationsController(
  translationsOperations
);
const languagesController = new Controllers.LanguagesController(
  languagesOperations
);
const animalsController = new Controllers.AnimalsController(animalsOperations);

export {
  tokenController,
  appController,
  usersController,
  currenciesController,
  reportsController,
  partnersController,
  gamesController,
  signalRController,
  translationsController,
  languagesController,
  schedulerTemplatesController,
  animalsController
};
