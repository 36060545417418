import { combineReducers } from 'redux';
import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { GAME_TYPES } from '../../core/constants/actionTypes';
import createReducer from '../../core/helpers/createReducer';

export const gamesReducer = combineReducers({
  games: createAsyncReducer(GAME_TYPES.RX_GET_GAMES,{}),
  gameResult: createAsyncReducer(GAME_TYPES.RX_GET_RESULT_BY_ID,{}),
  gamesPage: createReducer(0,{
    [GAME_TYPES.RX_SET_PAGE_NUMBER]: (state, action) => action.payload
  }),
  gamesRowsCount: createReducer(10,{
    [GAME_TYPES.RX_SET_ROWS_COUNT]: (state, action) => action.payload
  }),
  selectedGameId: createReducer('',{
    [GAME_TYPES.RX_SET_SELECTED_GAME_ID]: (state, action) => action.payload
  }),
  calendarDates: createReducer({startDate: '', endDate: ''},{
    [GAME_TYPES.RX_SET_CALENDAR_DATES]: (state, action) => action.payload
  })
});

