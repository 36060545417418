import React from 'react';

/**
 * @name componentSwitcher
 * @param isShow: boolean
 * @param firstComponent: Component
 * @param secondComponent: Component
 * @returns {*}
 */
export const componentSwitcher = (isShow, firstComponent, secondComponent = null) => {
  return (
    <>
      {
        isShow ? firstComponent : secondComponent
      }
    </>
  )
};
