import { CURRENCIES_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetCurrencies = payload => ({
  type: CURRENCIES_TYPES.CNTRL_GET_CURRENCIES,
  payload,
});

export const rxGetCurrenciesDone = payload => ({
  type: CURRENCIES_TYPES.RX_GET_CURRENCIES_DONE,
  payload,
});

export const rxGetCurrenciesPending = payload => ({
  type: CURRENCIES_TYPES.RX_GET_CURRENCIES_PENDING,
  payload,
});


export const cntrlGetCurrencyByCode = payload => {
  return {
    type: CURRENCIES_TYPES.CNTRL_GET_CURRENCY_BY_CODE,
    payload,
  }
};
export const rxGetCurrencyByCodeDone = payload => {
  return {
    type: CURRENCIES_TYPES.RX_GET_CURRENCY_BY_CODE_DONE,
    payload,
  }
};
export const rxGetCurrencyByCodePending = payload => {
  return {
    type: CURRENCIES_TYPES.RX_GET_CURRENCY_BY_CODE_PENDING,
    payload,
  }
};
