import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class CurrenciesOperation extends Operations{
  constructor(currenciesApiHandler){
    super();
    this.currenciesApiHandler = currenciesApiHandler;
  }

  async getCurrencies(){
    const response = await this.currenciesApiHandler.getCurrencies();
    return responseErrorCheck(response);
  }

  async getCurrenciesCode(state, action){
    const response = await this.currenciesApiHandler.getCurrenciesCode(action.payload);
    return responseErrorCheck(response);
  }

}
export default CurrenciesOperation;