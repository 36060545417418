import React from 'react';
import './index.css';
import Loading from '../loading/loading';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * @name DataLoading
 * @param isLoading: bool
 * @param data: object or array
 * @param component: Component
 * @param emptyComponent: Component
 * @param LoadingComponent: node
 * @returns {*}
 * @constructor
 */
const DataLoading = (isLoading, data, component, emptyComponent, LoadingComponent ) => {


  if (isLoading && LoadingComponent) {
    return LoadingComponent
  }
  if (isLoading && !LoadingComponent) {
    return  <div style={{width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'center'}}>
      <CircularProgress  />
    </div>
  }



  if (data instanceof (Array)) {
    if (!data.length) {
      if(LoadingComponent) {
        return <Loading/>
      }
      return emptyComponent ? emptyComponent : <p> data is empty</p>
    }
    return component
  }

  if (data instanceof (Object)) {
    if (!Object.keys(data).length) {
      if(LoadingComponent) {
        return <Loading/>
      }
      return emptyComponent ? emptyComponent : <p> data is empty</p>
    }
    return component
  }

  if (data || typeof data === "number" || typeof data === "string") {
    return component
  }
};

export default DataLoading;