import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import SideBar from '../components/sideBar';
import Header from '../components/header';
import { withStyles } from '@material-ui/core';
import DefaultRouts from '../../routing/DefaultRouts';
import Modals from '../containers/modals';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    width: '100%',
    height: 'calc(100% - 64px)',
    display: 'flex',
  },
  sideBar: {
    position: 'relative',
    zIndex: 5,
    boxSizing: 'border-box',
    width: `30%`,
    height: '100%',
    maxWidth: '350px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '1px 0 1px #3f51b5'
  },
  mainContent: {
    width: '70%',
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto'
  }
});


const App = ({ classes, ...props }) => {

  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
  return (
    <Suspense fallback={<p>Loading</p>}>
      <div className={classes.root}>
        <Header setIsOpen={setSideBarIsOpen} {...props}/>
        <div className={classes.body}>
          <SideBar isOpen={sideBarIsOpen} setIsOpen={setSideBarIsOpen}/>
          <div className={classes.mainContent}>
            <DefaultRouts/>
          </div>
        </div>
        <Modals/>
      </div>
    </Suspense>
  )
};

App.defaultProps = {};

App.propTypes = {};

export default withStyles(styles)(App);