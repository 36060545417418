import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'Token',
  refresh : 'refresh'
}

class TokenApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }

  addToken(data){
    return this.post('', data);
  }

  refreshToken(){
    return this.post(`${END_POINT.refresh}`)
  }

}

const tokenApiHandler = new TokenApiHandler(END_POINT.prefix);
export default tokenApiHandler;
