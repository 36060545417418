import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import { EUserType, EUserTypeEnum } from '../../../core/constants/enums';
import { cntrlGetPartners } from '../../../state-management/actions/partnerActions';
import { cntrlGetTranslations } from '../../../state-management/actions/translationsActions';
import { cntrlGetCurrencyByCode } from '../../../state-management/actions/currenciesActions';

class UsersOperation extends Operations{
  constructor(usersApiHandler){
    super();
    this.usersApiHandler = usersApiHandler;
  }

  async getUsers(store, action){
    const response = await this.usersApiHandler.getUsers();
    return responseErrorCheck(response);
  }

  async editUser(store, action){
    const response = await this.usersApiHandler.editUser(action.payload);
    return responseErrorCheck(response);
  }

  async getUsersSettings(store, action){
    const currencies = store.getState().currencies.data;
    const response = await this.usersApiHandler.getUsersSettings();
    if (response.result) {
      const currencyIndex = currencies.findIndex(i => i.id === response.result.currencyId);
      if (~currencyIndex) {
        await store.dispatch(cntrlGetCurrencyByCode(currencies[currencyIndex].name));
      }
      store.dispatch(cntrlGetTranslations(response.result.languageId));
    }
    if (responseErrorCheck(response) && response.result.type === EUserTypeEnum.Admin) {
      store.dispatch(cntrlGetPartners())
    }
    return responseErrorCheck(response);
  }

  async addUserBackOfficeAccount(store, action){
    const response = await this.usersApiHandler.addUserBackOfficeAccount();
    return responseErrorCheck(response);
  }

  async getUsersBalance(store, action){
    const response = await this.usersApiHandler.getUsersBalance();
    return responseErrorCheck(response);
  }

  async editCurrency(store, action){
    const response = await this.usersApiHandler.editCurrency(action.payload);
    return responseErrorCheck(response);
  }

  async editLanguage(store, action){
    const response = await this.usersApiHandler.editLanguage(action.payload);
    if (response.status === 1 ) {
      store.dispatch(cntrlGetTranslations(action.payload));
    }
    return responseErrorCheck(response);
  }

  async getIsUserNameTaken(store, action){
    const response = await this.usersApiHandler.getIsUserNameTaken();
    return responseErrorCheck(response);
  }

  async updateResetedPassword(store, action){
    const response = await this.usersApiHandler.updateResetedPassword();
    return responseErrorCheck(response);
  }

  async updateUserPermissions(store, action){
    const response = await this.usersApiHandler.updateUserPermissions();
    return responseErrorCheck(response);
  }








}
export default UsersOperation;