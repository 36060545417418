import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import { authSchemeFactory } from '../../../core/utils/authUtil';
import LocalStorageHelper from '../../../core/helpers/LocalStorageHelper';
import ApiHandler from '../../../core/classes/ApiHandler';
import { ACCESS } from '../../../core/constants/util';
import { cntrlSignalRConnect } from '../../../state-management/actions/signalRActions';
import { cntrlGetCurrencies } from '../../../state-management/actions/currenciesActions';
import { rxSetIsAuth } from '../../../state-management/actions/tokenActions';
import { cntrlGetLanguages } from '../../../state-management/actions/translationsActions';

class TokenOperation extends Operations {
  _tokenAccessConfig = (response) => {
    ApiHandler.token = response.accessToken;
    LocalStorageHelper.deleteItem(ACCESS);
    response.expiresIn = (response.expiresIn - this.expiresInInsurance) * 1000 + Date.now();
    LocalStorageHelper.setItem(ACCESS, response)
  };

  constructor(tokenApiHandler) {
    super();
    this.tokenApiHandler = tokenApiHandler;
    this.expiresInInsurance = 10;
  }


  async login(store, action) {
    const { userName, password } = action.payload;
    const response = await this.authApp(userName, password);
    if (response.hasOwnProperty('accessToken')) {
      this._attachTimer(response);
      // await store.dispatch(cntrlGetLanguages());
      await store.dispatch(cntrlGetCurrencies());
    }
    return response;
  }

  async refreshToken(store, action) {
    const refresh = await this.authRefresh();
    this._attachTimer(refresh);
  }

  async authRefresh() {
    const response = await this.tokenApiHandler.refreshToken();
    return responseErrorCheck(response)
  }

  async authApp(userName, password) {
    const response = await this.tokenApiHandler.addToken(authSchemeFactory()(userName, password));
    return responseErrorCheck(response);
  }

  _attachTimer(response) {
    if (response.status === 0) {
      throw new Error(response.status)
    }
    const timer = (response.expiresIn - this.expiresInInsurance) * 1000;
    this._tokenAccessConfig(response);

    ApiHandler.refreshTimer = setInterval(() => {
      this.authRefresh().then(res => {
        this._tokenAccessConfig(res)
      }).catch(e => console.error(e));
    }, timer)
  }

  _tokenAccessConfig = (response) => {
    ApiHandler.token = response.accessToken;
    LocalStorageHelper.deleteItem(ACCESS);
    response.expiresIn = (response.expiresIn - this.expiresInInsurance) * 1000 + Date.now();
    LocalStorageHelper.setItem(ACCESS, response)
  };

  logout(store) {
    ApiHandler.token = "";
    LocalStorageHelper.deleteItem(ACCESS);
    store.dispatch(rxSetIsAuth(false))
  }
}

export default TokenOperation;