import Controller from '../../core/classes/AppController';
import {
  rxGetUsersSettingsPending,
  rxGetUsersSettingsDone,
  rxGetUsersPending,
  rxGetUsersDone,
  rxGetUserBalancePending, rxGetUserBalanceDone, rxEditCurrencyPending, rxEditCurrencyDone
} from '../../state-management/actions/userAction';
import { partnerModule } from '../../core/moduls/PartnerModul';
import PartnerModule from '../../core/moduls/PartnerModul';
import { cntrlSignalRConnect } from '../../state-management/actions/signalRActions';
import { cntrlGetLanguages } from '../../state-management/actions/translationsActions';
import { cntrlGetPartnerSettings } from '../../state-management/actions/partnerActions';

class UsersController extends Controller {
  constructor(usersOperation) {
    super();
    this.usersOperation = usersOperation;
  }


  async getSettings(store, action) {
    try {
      store.dispatch(rxGetUsersSettingsPending());
      const response = await this.usersOperation.getUsersSettings(store, action);
      partnerModule.setConfigItem(PartnerModule.constants.partnerId, response[PartnerModule.constants.partnerId]);
      store.dispatch(cntrlGetPartnerSettings());
      store.dispatch(cntrlSignalRConnect());
      await store.dispatch(cntrlGetLanguages());
      store.dispatch(rxGetUsersSettingsDone(response));
      return response;
    } catch ( e ) {
      return new Error(e);
    }
  }

  async getUserBalance(store, action) {
    try {
      store.dispatch(rxGetUserBalancePending());
      const balance = await this.usersOperation.getUserBalance(store, action);
      store.dispatch(rxGetUserBalanceDone(balance));
    } catch ( e ) {
      return new Error(e);
    }
  }

  async getUsers(store, action) {
    try {
      store.dispatch(rxGetUsersPending());
      const response = await this.usersOperation.getUsers(store, action);
      store.dispatch(rxGetUsersDone(response));
    } catch ( e ) {
      return new Error(e);
    }
  }

  async editCurrency(store, action) {
    try {
      store.dispatch(rxEditCurrencyPending());
      const response = await this.usersOperation.editCurrency(store, action);
      store.dispatch(rxEditCurrencyDone(response));
    } catch ( e ) {
      return new Error(e);
    }
  }

  async editLanguage(store, action) {
    try {
      const response = await this.usersOperation.editLanguage(store, action);
      return response;
    } catch (e) {
      return e;
    }
  }

  async editUser(store, action) {
    try {
      await this.usersOperation.editUser(store, action);
      const response = await this.usersOperation.getUsersSettings(store, action);
      store.dispatch(rxGetUsersSettingsDone(response));
    } catch ( e ) {
      return new Error(e)
    }
  }
}

export default UsersController;