import Controller from '../../core/classes/AppController';
import {
    rxGetSchedulerTemplatesDone,
    rxGetSchedulerTemplatesPending
} from '../../state-management/actions/schedulerTemplatesActions';


class SchedulerTemplatesController extends Controller {
    constructor(schedulerTemplatesOperation) {
        super();
        this.schedulerTemplatesOperation = schedulerTemplatesOperation;
    }


    async getSchedulerTemplates(store, action) {
        try {
            store.dispatch(rxGetSchedulerTemplatesPending());
            const schedulerTemplates = await this.schedulerTemplatesOperation.getSchedulerTemplates(store, action);
            store.dispatch(rxGetSchedulerTemplatesDone(schedulerTemplates));

        } catch (e) {
            return new Error(e);
        }
    }


    async setSchedulerTemplate(store, action) {
        try {
            return await this.schedulerTemplatesOperation.setSchedulerTemplate(store, action)
        } catch (e) {
            return new Error(e);
        }
    }

    async addSchedulerTemplate(store, action) {
        try {
            await this.schedulerTemplatesOperation.addSchedulerTemplate(store, action);
            await this._refreshSchedule(store, action);
        } catch (e) {
            return new Error(e);
        }
    }

    async deleteSchedulerTemplate(store, action) {
        try {
            await this.schedulerTemplatesOperation.deleteSchedulerTemplate(store, action);
            await this._refreshSchedule(store, action);
        } catch (e) {
            return new Error(e);
        }
    }

    async _refreshSchedule(store, action) {
      const schedulerTemplates = await this.schedulerTemplatesOperation.getSchedulerTemplates(store, action);
      store.dispatch(rxGetSchedulerTemplatesDone(schedulerTemplates));
    }

}


export default SchedulerTemplatesController;
