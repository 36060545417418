import Controller from '../../core/classes/AppController';
import * as signalR from '@aspnet/signalr';
import { SIGNAL_R_URL } from '../../core/constants/urls';
import ApiHandler from '../../core/classes/ApiHandler';
import { SignalrNotificationActionType } from '../../core/constants/SignalRTypes';
import { cntrlSignalRConnect } from '../../state-management/actions/signalRActions';
import { partnerModule } from '../../core/moduls/PartnerModul';
import PartnerModule  from '../../core/moduls/PartnerModul';
import { cntrlGetGames } from '../../state-management/actions/gameActions';
// import { APP_CONSTANTS } from '../../core/constants/constants';
// import { rxModalClose, rxModalOpen } from '../../state-management/actions/modalActions';
// import MODAL_NAMES from '../../core/constants/modalNames';
// import { rxAddHistoryCount } from '../../state-management/actions/betAction';
// import { cntrlGetUserBalance } from '../../state-management/actions/userActions';
// import { EBetHistoryCategoriesValues } from '../../core/constants/enums';

const SignalRConstants = {
  Notify: 'Notify'
};

class SignalRController extends  Controller{

  connect(store, action) {
    try {
      // this.connection = new signalR.HubConnectionBuilder()
      //   .withUrl(`${SIGNAL_R_URL}/MessageHub?partnerId=${partnerModule.getConfigItem(PartnerModule.constants.partnerId)}&access_token=${ApiHandler.token}`)
      //   .build();
      // this.connection.start().catch(err => {
      //     this.connection = null;
      //     store.dispatch(cntrlSignalRConnect());
      //   });
      // this.connection.on(SignalRConstants.Notify, data => {
      //   switch (data.actionType) {
      //     case SignalrNotificationActionType.GameFinished:
      //       const {gamesRowsCount, gamesPage, calendarDates:{startDate, endDate}} = store.getState().gameReducer;
      //       store.dispatch(cntrlGetGames({Skip: gamesRowsCount * gamesPage, Take: gamesRowsCount, startDate, endDate}));
      //       break;
      //     case SignalrNotificationActionType.BetPlaced:
      //       break;
      //     case SignalrNotificationActionType.GameCreated:
      //       break;
      //     case SignalrNotificationActionType.OddChanged:
      //       break;
      //     case SignalrNotificationActionType.GameCanceled:
      //       break;
      //   }
      // });

    } catch (e) {
      this.connection = null;
      // store.dispatch(cntrlSignalRConnect());
    }
  }

}

export default SignalRController;
