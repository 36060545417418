import { MODAL_ACTION_TYPES } from '../../core/constants/actionTypes';

export const rxModalOpen = payload=> {
  return {
    type: MODAL_ACTION_TYPES.RX_MODAL_TOGGLE,
    payload,
  }
};

export const rxModalClose = payload => {
  return {
    type: MODAL_ACTION_TYPES.RX_MODAL_CLOSE,
    payload,
  }
};

