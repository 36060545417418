import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../../../core/constants/colors';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { translateText } from '../../../components/translation/index';
import {
  addGameInputs, addGameMaxPercentageInputs,
} from '../../../../core/constants/InputsConstants';
import TextField from '@material-ui/core/TextField';
import { rxModalClose } from '../../../../state-management/actions/modalActions';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { cntrlAddGame } from '../../../../state-management/actions/gameActions';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {EGameCreationType} from "../../../../core/constants/enums";
import {
  cntrlAddSchedulerTemplate, cntrlDeleteSchedulerTemplate,
  cntrlEditSchedulerTemplate,
  cntrlGetSchedulerTemplates
} from "../../../../state-management/actions/schedulerTemplatesActions";


const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);
const styles = {
  root: {
    maxWidth: '400px',
    minWidth: '250px',
    background: '#fff',
    border: `1px solid ${colors.headerColor}`,
    borderRadius: '5px',
    fontSize: '1.1em',
    overflowY: 'hidden',
    fontWeight: '500',
    paddingBottom: '30px'
  },
  header: {
    height: '50px',
    background: colors.headerColor,
    marginBottom: '20px',
    padding: '0 10px',
    color: '#fff'
  },
  input: {
    borderColor: colors.headerColor,
    marginBottom: '10px',
  },
  icon: {
    cursor: 'pointer'
  },
  timeContent: {
    margin: '10px 0'
  },
  closeLimitsContent: {
    height: 0,
    opacity: 0,
    transition: 'all .3s ease-out',
    visibility: 'hidden'
  },
  openLimitsContent: {
    visibility: 'visible ',
    opacity: 1,
    height: '150px',
  },
  button: {
    background: colors.buttonColor,
    marginTop: '20px',
    '&:hover':{
      background: colors.headerColor,
      color: '#fff'
    }
  },
};


const AddGameModal = React.memo(({ classes, getSchedulerTemplates ,  schedulerTemplates:{data: schedulerTemplates, isLoading},...props }) => {

  const [switchValue, toggleSwitchValue] = useState(false);
  const [params, setParams] = useState({endDate: Date.now()});
  const [gameTypeId , setGameTypeId] = useState()
  const newDate = moment().toISOString();

  const handleInputChange = (key, value) =>{
    setParams(prevState => ({
        ...prevState, [key]: value
      }))
  };
  const addGame = _ =>{
    const dataModel = {
      ...params,
      gameTypeId,
    }
    props.addGame(dataModel)
  };

  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(()=>{
    getSchedulerTemplates();

  },[])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const manualGameType = useMemo(()=>{
    return schedulerTemplates.filter(item => item.type===0)
  },[schedulerTemplates])


  const handleCheckedBetType = (e,id)=>{
    const { checked } = e.target;
    if (checked){
      setGameTypeId(id)
    }else {
      setGameTypeId('')
    }

  }
  return (
    <div className={classes.root}>
      <Grid container alignContent={'flex-start'} justify={'center'}>
        <Grid container className={classes.header} justify={'space-between'} alignItems={'center'}>
          <Grid xs={6} item>
            <Grid container alignItems={'center'}>
              <span>{translateText('ADD_GAME')} </span>
            </Grid>
          </Grid>
          <Grid xs={6} item>
            <Grid container alignItems={'center'} justify={'flex-end'}>
              <Icon onClick={props.close} className={classes.icon}>close</Icon>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={'column'} alignItems={'center'}>
          <Grid item xs={10}>
            <Grid container alignContent={'center'} justify={'flex-start'} direction={'column'}>
              <Grid item xs={12}>
                {
                  addGameInputs.map((i, index) => <TextField
                    key={i.name}
                    value={params[i.name] ? params[i.name] : ''}
                    className={classes.input}
                    required={i.required}
                    fullWidth={i.fullWidth}
                    name={i.name}
                    label={i.label}
                    margin={i.margin}
                    onChange={e => handleInputChange(e.target.name, e.target.value)}
                  />)
                }
              </Grid>
              <Grid container alignItems={'center'}>
                <Grid xs={9} item>
                  <TextField
                    id="datetime-local"
                    type="datetime-local"
                    name='endDate'
                    defaultValue={`${newDate}`}
                    className={classes.timeContent}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e)=>handleInputChange(e.target.name, moment(e.target.value).toISOString())}
                  />
                </Grid>
                <Grid xs={9} item>
                  <div>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                      {translateText('GAME_TYPE')}
                    </Button>
                  <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                  >
                    {
                      manualGameType.map(({gameName,id}) => (
                          <StyledMenuItem>
                            <Checkbox
                                checked={gameTypeId===id}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                onChange={e => handleCheckedBetType(e, id)}
                            />
                            <ListItemText primary={gameName} />
                          </StyledMenuItem>
                      ))
                    }
                  </StyledMenu>
                  </div>
                </Grid>
              </Grid>
              <Grid className={[classes.closeLimitsContent, switchValue ?  classes.openLimitsContent : ''].join(' ')}>
                {
                  addGameMaxPercentageInputs.map((i, index) => <TextField
                    key={i.name}
                    value={params[i.name] ? params[i.name] : ''}
                    className={classes.input}
                    required={i.required}
                    fullWidth={i.fullWidth}
                    name={i.name}
                    label={i.label}
                    margin={i.margin}
                    // onChange={e => handleInputChange(e.target.name, e.target.value)}  //TODO
                  />)
                }
              </Grid>

              <Grid container justify={'center'}>
                <Button variant="contained"
                        disabled={!gameTypeId}
                        className={classes.button}

                         onClick={addGame}
                >
                  {translateText('ADD')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});


const mapStateToProps = state => ({
  schedulerTemplates: state.schedulerTemplates,
});
const mapDispatchToProps = dispatch => ({
  close: _ => dispatch(rxModalClose()),
  addGame: data => dispatch(cntrlAddGame(data)),
  getSchedulerTemplates: () => dispatch(cntrlGetSchedulerTemplates()),

});

AddGameModal.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddGameModal));
