import React from 'react';
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { defaultPath } from '../router';


const IsNotAuthLayer = ({ isAuth, children, ...props }) => {
  return (
    <>
      {!isAuth ? children : <Redirect to={defaultPath}/>}
    </>
  )
};

// IsNotAuthLayer.defaultProps = {
//   isAuth: false
// };

IsNotAuthLayer.propTypes = {
  isAuth: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuth: state.isAuth
});

export default withRouter(connect(mapStateToProps, null)(IsNotAuthLayer));

