// @material-ui/icons
import {lazy} from 'react'
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import GamepadIcon from "@material-ui/icons/Gamepad";
import AddIcon from "@material-ui/icons/Add";
import HistoryIcon from "@material-ui/icons/History";
import ReportIcon from "@material-ui/icons/Timeline";
import ToysIcon from "@material-ui/icons/Toys";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Schedule from "@material-ui/icons/Schedule";


// core components/views for Admin layout
// import Dashboard from '../view/page/dashBoard';
// import PartnerConfiguration from '../view/page/partnerConfiguration';
// import Players from '../view/page/players';
// import GameResult from '../view/page/game/gameResult';
// import GameReports from '../view/page/reports/gameReports';
// import BetReports from '../view/page/reports/betReports';
// import BetTypeReports from '../view/page/reports/betTypeReports';
// import LoginPage from '../view/page/login';
// import Account from '../view/page/account';
// import Wallet from '../view/page/wallet';


import { rxModalOpen } from '../state-management/actions/modalActions';
import MODAL_NAMES from '../core/constants/modalNames';
import store from '../state-management/store/index';
import { translateText } from '../view/components/translation';


//

const Dashboard = lazy(() => import('../view/page/dashBoard'));
const PartnerConfiguration = lazy(() => import('../view/page/partnerConfiguration'));
const Players = lazy(() => import('../view/page/players'));
const GameResult = lazy(() => import('../view/page/game/gameResult'));
const GameReports = lazy(() => import('../view/page/reports/gameReports'));
const BetReports = lazy(() => import('../view/page/reports/betReports'));
const BetTypeReports = lazy(() => import('../view/page/reports/betTypeReports'));
const LoginPage = lazy(() => import('../view/page/login'));
const Account = lazy(() => import('../view/page/account'));
const Wallet = lazy(() => import('../view/page/wallet'));
const FinancalReport = lazy(()=> import('../view/page/reports/financalReport'));
const GameTypes = lazy(()=> import('../view/page/schedulerTemplates'))

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: translateText('DASHBOARD'),
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    path: "/players",
    name: translateText('PLAYERS'),
    icon: PeopleIcon,
    component: Players,
  },
  {
    path: "/reports",
    name: translateText('REPORTS'),
    icon: ReportIcon,
    component: null,
    innerRouts: [
      {
        path: "/bet-reports",
        name: translateText('BET_REPORTS'),
        icon: AttachMoneyIcon,
        component: BetReports,
      },
      {
        path: "/bet-type-reports",
        name: translateText('BET_TYPE_REPORTS'),
        icon: BubbleChartIcon,
        component: BetTypeReports,
      },
      {
        path: "/game-reports",
        name: translateText('GAME_REPORTS'),
        icon: ToysIcon,
        component: GameReports,
      },

      {
        path : "/financal-reports",
        name : translateText('FINANCAL_REPORTS'),
        icon : AttachMoneyIcon,
        component : FinancalReport,
        permission: state => state.userSettings.data.type === 0
      }

    ]
  },
  {
    path: "/administration",
    name: translateText('ADMINISTRATION'),
    icon: SettingsIcon,
    component: null,
    innerRouts: [
      {
        path: "/partner-configuration",
        name: translateText('PARTNER_CONFIGURATION'),
        icon: PermDataSettingIcon,
        component: PartnerConfiguration,
      },
      {
        path: "/game-type",
        name: translateText('GAME_TYPE'),
        icon: Schedule,
        component: GameTypes,
      },
    ]
  },
  {
    path: "/game",
    name: translateText('GAME'),
    icon: GamepadIcon,
    component: null,
    innerRouts: [
      {
        notRedirect: true,
        name: translateText('ADD_GAME'),
        icon: AddIcon,
        component: null,
        onClick: () => store.dispatch(rxModalOpen(MODAL_NAMES.ADD_GAME_MODAL))
      },
      {
        path: "/game-result",
        name: translateText('GAME_RESULT'),
        icon: HistoryIcon,
        component: GameResult,
      },
    ]
  },
];

const partnerRoutes = [
  {
    path: "/dashboard",
    name: translateText('DASHBOARD'),
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    path: "/players",
    name: translateText('PLAYERS'),
    icon: PeopleIcon,
    component: Players,
  },
  {
    path: "/reports",
    name: translateText('REPORTS'),
    icon: ReportIcon,
    component: null,
    innerRouts: [
      {
        path: "/bet-reports",
        name: translateText('BET_REPORTS'),
        icon: AttachMoneyIcon,
        component: BetReports,
      },
      {
        path: "/bet-type-reports",
        name: translateText('BET_TYPE_REPORTS'),
        icon: BubbleChartIcon,
        component: BetTypeReports,
      },
      {
        path: "/game-reports",
        name: translateText('GAME_REPORTS'),
        icon: ToysIcon,
        component: GameReports,
      },

      {
        path : "/financal-reports",
        name : translateText('FINANCAL_REPORTS'),
        icon : AttachMoneyIcon,
        component : FinancalReport,
        permission: state => state.userSettings.data.type === 0
      }

    ]
  },
  // {
  //   path: "/administration",
  //   name: translateText('ADMINISTRATION'),
  //   icon: SettingsIcon,
  //   component: null,
  //   innerRouts: [
  //     {
  //       path: "/partner-configuration",
  //       name: translateText('PARTNER_CONFIGURATION'),
  //       icon: PermDataSettingIcon,
  //       component: PartnerConfiguration,
  //     },
  //   ]
  // },
  // {
  //   path: "/game",
  //   name: translateText('GAME'),
  //   icon: GamepadIcon,
  //   component: null,
  //   innerRouts: [
  //     {
  //       notRedirect: true,
  //       name: translateText('ADD_GAME'),
  //       icon: AddIcon,
  //       component: null,
  //       onClick: () => store.dispatch(rxModalOpen(MODAL_NAMES.ADD_GAME_MODAL))
  //     },
  //     {
  //       path: "/game-result",
  //       name: translateText('GAME_RESULT'),
  //       icon: HistoryIcon,
  //       component: GameResult,
  //     },
  //   ]
  // },
];


export const separatelyRouters = [
  {
    path: "/wallet",
    name: "Wallet",
    icon: null,
    component: Wallet,
  },
  {
    path: "/account",
    name: "Account",
    icon: null,
    component: Account,
  },
];

export const defaultPath = '/';

export { dashboardRoutes, partnerRoutes };
