import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'LuckyAnimals'
}

class LuckyAnimalsApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }
  getLuckyAnimals(){
    return this.get()
  }
  addLuckyAnimals(data){
    return this.post('', data)
  }

  deleteLuckyAnimal(id){
    return this.delete('', id)
  }
}

const luckyAnimalsApiHandler = new LuckyAnimalsApiHandler(END_POINT.prefix);
export default luckyAnimalsApiHandler;