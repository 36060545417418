import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { PARTNER_TYPES } from '../../core/constants/actionTypes';
import { PARTNER_BETTYPES } from '../../core/constants/actionTypes';

export const partnersBetTypeReducer = createAsyncReducer(PARTNER_BETTYPES.RX_GET_PARTNER_BETTYPE, []);
export const partnersBetTypeInfoReducer = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_INFO, []);
export const partnerCustomizationConfigReducer = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_CUSTOMIZATION_CONFIG, []);
export const partnerConfigReducer = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_CONFIG, {});
export const partnersList = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNERS, []);
export const partnerSettings = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_SETTINGS, []);
export const partnerBetTypesConfig = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_BET_TYPE_CONFIG, []);
export const partnerMainLimits = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_MAIN_LIMITS, {});
export const partnerBetTypesGroupReducer = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_BET_TYPES_GROUP,  []);
export const partnerAnimalsReducer = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_ANIMALS,  []);
export const partnerGameTypeLimits = createAsyncReducer(PARTNER_TYPES.RX_GET_PARTNER_GAMETYPE_LIMITS,{})
