let DOMAIN = null;
let TRANSLATIONS_API = null;
if (process.env.REACT_APP_ENV === 'production-eu') {
  DOMAIN = 'https://api.betcolottery.com';
  TRANSLATIONS_API = 'https://rpd-translations.betcoapps.com/api/v1';
}
else if(process.env.REACT_APP_ENV === 'production-latam') {
  DOMAIN = 'https://jdb-api-latam.rpd.cloud';
  TRANSLATIONS_API = 'https://rpd-translations.betcoapps.com/api/v1';
}
else if(process.env.REACT_APP_ENV === 'prod-build-crt') {
  DOMAIN = 'https://jdb-api.springbuilder.site';
  TRANSLATIONS_API = 'https://rpd-translations.betcoapps.com/api/v1';
}
else {
  TRANSLATIONS_API = 'https://rpd-translations-stage.betcoapps.com/api/v1';
  // DOMAIN = 'http://jdbtest01-ye.betconstruct.int:5000'
  // DOMAIN = 'http://10.25.65.92:21368'
  DOMAIN = 'https://jdb-api-stage.betcoapps.com'
}


export const API = `${DOMAIN}/api`;

export const SIGNAL_R_URL = DOMAIN;
export {
  DOMAIN,
  TRANSLATIONS_API
}
