import { USER_TYPES } from '../../core/constants/actionTypes';

//settings
export const cntrlGetUsersSettings = payload => ({
  type: USER_TYPES.CNTRL_GET_USERS_SETTINGS,
  payload
});

export const rxGetUsersSettingsDone = payload => ({
  type: USER_TYPES.RX_GET_USERS_SETTINGS_DONE,
  payload
});

export const rxGetUsersSettingsPending = payload => ({
  type: USER_TYPES.RX_GET_USERS_SETTINGS_PENDING,
  payload
});

// users list
export const cntrlGetUsers = payload => ({
  type: USER_TYPES.CNTRL_GET_USERS,
  payload
});

export const rxGetUsersPending = payload => ({
  type: USER_TYPES.RX_GET_USERS_PENDING,
  payload
});

export const rxGetUsersDone = payload => ({
  type: USER_TYPES.RX_GET_USERS_DONE,
  payload
});

//balance

export const cntrlGetUserBalance = payload => ({
  type: USER_TYPES.CNTRL_GET_USER_BALANCE,
  payload,
});

export const rxGetUserBalanceDone = payload => ({
  type: USER_TYPES.RX_GET_USER_BALANCE_DONE,
  payload,
});

export const rxGetUserBalancePending = payload => ({
  type: USER_TYPES.RX_GET_USER_BALANCE_PENDING,
  payload,
});

//edit data
export const cntrlEditUser = payload => ({
  type: USER_TYPES.CNTRL_EDIT_USER_SETTINGS,
  payload,
});

//edit currency
export const cntrlEditCurrency = payload => {
  return {
    type: USER_TYPES.CNTRL_EDIT_USER_CURRENCY,
    payload,
  }
};
export const rxEditCurrencyDone = payload => {
  return {
    type: USER_TYPES.RX_EDIT_USER_CURRENCY_DONE,
    payload,
  }
};
export const rxEditCurrencyPending = payload => {
  return {
    type: USER_TYPES.RX_EDIT_USER_CURRENCY_PENDING,
    payload,
  }
};

export const cntrlEditLanguages = payload => {
  return {
    type: USER_TYPES.CNTRL_EDIT_USER_LANGUAGE,
    payload
  }
};