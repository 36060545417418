import Controller from '../../core/classes/AppController';
import {
  rxGetCurrenciesDone,
  rxGetCurrenciesPending, rxGetCurrencyByCodeDone,
  rxGetCurrencyByCodePending
} from '../../state-management/actions/currenciesActions';

class CurrenciesController extends Controller{
  constructor(currenciesOperation){
    super();
    this.currenciesOperation = currenciesOperation;
  }

  async getCurrencies(store, action) {
    try {
      store.dispatch(rxGetCurrenciesPending());
      const response = await this.currenciesOperation.getCurrencies(store, action);
      store.dispatch(rxGetCurrenciesDone(response));
    } catch ( e ) {
      return new Error(e);
    }
  }
  async getCurrenciesCode(store, action) {
    try {
      store.dispatch(rxGetCurrencyByCodePending());
      const response = await this.currenciesOperation.getCurrenciesCode(store, action);
      store.dispatch(rxGetCurrencyByCodeDone(response));
    } catch ( e ) {
      return new Error(e);
    }
  }

}

export default CurrenciesController;