import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import {
  cntrlEditLanguages, cntrlEditUser,
  cntrlGetUsersSettings
} from '../../../state-management/actions/userAction';
import { cntrlLogOut } from '../../../state-management/actions/tokenActions';
import DataLoading from '../DataLoading/DataLoading';
import { TextField } from '@material-ui/core/';
import Icon from '@material-ui/core/esm/Icon/Icon';
import { rxModalOpen } from '../../../state-management/actions/modalActions';
import MODAL_NAMES from '../../../core/constants/modalNames';
import { EUserTypeEnum } from '../../../core/constants/enums';
import { translateText } from '../translation';
import currencyService from '../../../services/services/currencyService';


const currencySelect = {
  select: true,
  variant: 'standard',
  type: 'text',
  margin: 'dense',
  fullWidth: true
};

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  profileContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  profileName: {
    fontSize: 18,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textTransform: 'capitalize'
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing,
    paddingRight: theme.spacing,
    paddingBottom: theme.spacing,
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  currencyRoot: {
    display: 'flex',
    height: '70%',
    alignItems: 'center'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important',
  },
  currencyInput: {
    color: 'white'
  },

  wrapperSvg: {
    '& svg': {
      fill: 'white'
    },

  },
  mobileCurrency: {
    height: '100%'
  },

});

class PrimarySearchAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }


  componentDidMount() {
    this.props.getUserSettings().then(data => {
      if (Object.keys(data).length) {
        this.setState({ currencyCode: data.currencyCode, languageId: data.languageId })
      }
    })
  }


  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };
  logoutAndClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
    this.props.logOut();
  };


  handleChange = e => {
    const currencyCode = e.target.value;
    if (currencyCode === this.state.currencyCode) return;
    this.setState({ currencyCode: e.target.value }, () => {
      this.props.editUser({ currencyCode: this.state.currencyCode }).then(() => {
        this.handleMobileMenuClose();
        currencyService.setState(currencyCode);
        //TODO default type
        // window.location.reload();
      });
    })
  };

  handleLanguageChange = e => {
    const languageId = e.target.value;
    if (languageId === this.state.languageId) return;
    this.setState({ languageId: languageId }, () => {
      this.props.editUser({ languageId: this.state.languageId });
      this.handleMobileMenuClose();
    })
  };

  renderTitle = _ => {
    const pathName = this.props.location.pathname.split('/');
    return translateText(pathName[pathName.length - 1].replace(/\-/g, '_').toUpperCase());
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl, currencyCode, languageId } = this.state;
    const { classes, userSettings, currencies, languages, partnerSettings } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.logoutAndClose}>
          <ExitToAppIcon/><ListItemText inset primary={translateText('LOG_OUT')}/>
        </MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >

        <MenuItem>
          <IconButton color="inherit">
            <Icon>attach_money</Icon>
          </IconButton>
          {/*<TextField*/}
          {/*  autoWidth*/}
          {/*  {...currencySelect}*/}
          {/*  value={currencyCode}*/}
          {/*  onChange={this.handleChange}*/}
          {/*  InputProps={{*/}
          {/*    disableUnderline: true,*/}
          {/*  }}*/}
          {/*>{*/}
          {/*  currencies.data.map(option => {*/}
          {/*      return <MenuItem key={option.name} value={option.name}>{option.name} </MenuItem>*/}
          {/*    }*/}
          {/*  )}</TextField>*/}
        </MenuItem>
        <MenuItem>
          <IconButton color="inherit">
            <Icon>language</Icon>
          </IconButton>
          <TextField
            autoWidth
            {...currencySelect}
            id="language-select"
            value={this.state.languageId}
            onChange={this.handleLanguageChange}
            disableUnderline
            InputProps={{
              disableUnderline: true,
            }}
          >{
            languages.data.map(option => {
                return <MenuItem key={option.id} value={option.id}
                                 className={classes.currencyItem}>{option.name} </MenuItem>
              }
            )}</TextField>
        </MenuItem>
        <MenuItem onClick={this.logoutAndClose}>
          <IconButton color="inherit">
            <ExitToAppIcon/>
          </IconButton>
          <p>{translateText('LOG_OUT')}</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={() => this.props.setIsOpen(true)} className={classes.menuButton} color="inherit"
                        aria-label="Open drawer">
              <MenuIcon/>
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              {this.renderTitle()}
            </Typography>
            <div className={classes.grow}/>
            <div className={classes.sectionDesktop}>
              {
                DataLoading(languages.isLoading, languages.data,
                  <div className={classes.currencyRoot}>
                    <IconButton color="inherit">
                      <Icon>language</Icon>
                    </IconButton>
                    <TextField
                      {...currencySelect}
                      id="language-select"
                      value={this.state.languageId}
                      onChange={this.handleLanguageChange}
                      className={classes.wrapperSvg}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: 'white',
                          border: 0
                        }
                      }}
                    >{
                      languages.data.map(option => {
                          return <MenuItem key={option.id} value={option.id}
                                           className={classes.currencyItem}>{option.name} </MenuItem>
                        }
                      )}</TextField>
                  </div>)
              }
              {
                DataLoading(currencies.isLoading, currencies.data,
                  <div className={classes.currencyRoot}>
                    <IconButton color="inherit">
                      <Icon>attach_money</Icon>
                    </IconButton>
                    <TextField
                      {...currencySelect}
                      id="currency-select"
                      value={currencyCode}
                      onChange={this.handleChange}
                      className={classes.wrapperSvg}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: 'white',
                          border: 0
                        }
                      }}
                      SelectProps={{
                        MenuProps: {
                          style: {
                            height: '300px'
                          }
                        }
                      }}
                    >{
                      currencies.data.map(option => {
                          return <MenuItem key={option.name} value={option.name}
                                           className={classes.currencyItem}>{option.name} </MenuItem>
                        }
                      )}</TextField>
                  </div>)
              }
              <div className={classes.profileContent} onClick={this.handleProfileMenuOpen}>
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle/>
                </IconButton>
                <Typography className={classes.profileName} variant="h6" color="inherit" noWrap>
                  {!userSettings.isLoading ? userSettings.data.userName : null}
                </Typography>
                <ArrowDropDown/>
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon/>
              </IconButton>
            </div>
            {
              userSettings.data.type === EUserTypeEnum.Admin ?
                <IconButton color="inherit" onClick={_ => this.props.openModal(MODAL_NAMES.ADD_PARTNER_MODAL)}>
                  <Icon fontSize={'large'}>add_box</Icon>
                </IconButton> :
                null
            }
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSettings: state.userSettings,
  currencies: state.currencies,
  languages: state.languages,
  partnerSettings: state.partnerSettings,
});

const mapDispatchToProps = dispatch => ({
  getUserSettings: () => dispatch(cntrlGetUsersSettings()),
  logOut: () => dispatch(cntrlLogOut()),
  editUser: (data) => dispatch(cntrlEditUser(data)),
  setLanguageId: (Id) => dispatch(cntrlEditLanguages(Id)),
  openModal: name => dispatch(rxModalOpen(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrimarySearchAppBar));
