import { usersSettingsReducers, userListReducer } from "./usersReducers";
import { isAuthReducer } from "./tokenReducer";
import {
  currenciesReducer,
  currency,
  partnerCurrencies
} from "./currenciesReducers";
import {
  betReportReducer,
  betTypeReportReducer,
  gameReportReducer,
  userReportReducer,
  dashBoard,
  partnersFinancalReportReducer
} from "./reportsReducer";
import {
  partnerCustomizationConfigReducer,
  partnersBetTypeReducer,
  partnerConfigReducer,
  partnersList,
  partnerSettings,
  partnerBetTypesConfig,
  partnerMainLimits,
  partnerBetTypesGroupReducer,
  partnerAnimalsReducer,
  partnersBetTypeInfoReducer,
  partnerGameTypeLimits

} from "./partnerReducers";
import { schedulerTemplates } from "./schedulerTemplatesReducer";
import { translationReducer, languages } from "./translatiationReducer";
import { gamesReducer } from "./gameReducers";
import { modalState } from "./modalStateReducer";
import { animalsReducer } from "./animalsReducer";

export {
  isAuthReducer,
  usersSettingsReducers,
  userListReducer,
  currenciesReducer,
  betReportReducer,
  betTypeReportReducer,
  gameReportReducer,
  userReportReducer,
  partnerMainLimits,
  partnersBetTypeReducer,
  partnerCustomizationConfigReducer,
  partnerConfigReducer,
  translationReducer,
  schedulerTemplates,
  gamesReducer,
  dashBoard,
  partnersList,
  modalState,
  partnerSettings,
  partnerBetTypesConfig,
  languages,
  currency,
  partnerBetTypesGroupReducer,
  partnerCurrencies,
  partnersFinancalReportReducer,
  partnerAnimalsReducer,
  animalsReducer,
  partnersBetTypeInfoReducer,
  partnerGameTypeLimits
};
