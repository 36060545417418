
import { GAME_TYPES, REPORT_TYPES, } from '../../core/constants/actionTypes';

export const cntrlGetBetReport = payload =>{
  return ({
    type: REPORT_TYPES.CNTRL_GET_BET_REPORT,
    payload
  });

}

export const rxGetBetReport = payload => ({
  type: REPORT_TYPES.RX_GET_BET_REPORT,
  payload
});

export const rxGetBetReportPending = payload => ({
  type: REPORT_TYPES.RX_GET_BET_REPORT_PENDING,
  payload
});

export const rxGetBetReportDone = payload => ({
  type: REPORT_TYPES.RX_GET_BET_REPORT_DONE,
  payload
});

export const cntrlGetBetTypeReport = payload => ({
  type : REPORT_TYPES.CNTRL_GET_BET_TYPE_REPORT,
  payload
})

export const rxGetBetTypeReport = payload => ({
  type : REPORT_TYPES.RX_GET_BET_TYPE_REPORT,
  payload
})

export const rxGetBetTypeReportPending = payload => ({
  type : REPORT_TYPES.RX_GET_BET_TYPE_REPORT_PENDING,
  payload
})
export const rxGetBetTypeReportDone = payload => ({
  type : REPORT_TYPES.RX_GET_BET_TYPE_REPORT_DONE,
  payload
})

export const cntrlGetGameReport = payload => ({
  type : REPORT_TYPES.CNTRL_GET_GAME_REPORT,
  payload
})

export const rxGetGameReport = payload => ({
  type : REPORT_TYPES.RX_GET_GAME_REPORT,
  payload
})

export const rxGetGameReportPending = payload => ({
  type : REPORT_TYPES.RX_GET_GAME_REPORT_PENDING,
  payload
})


export const rxGetGameReportDone = payload => ({
  type : REPORT_TYPES.RX_GET_GAME_REPORT_DONE,
  payload
})

export const cntrlGetUserReport = payload => ({
  type : REPORT_TYPES.CNTRL_GET_USER_REPORT,
  payload
})

export const rxGetUserReport = payload => ({
  type : REPORT_TYPES.RX_GET_USER_REPORT,
  payload
})

export const rxGetUserReportPending = payload => ({
  type : REPORT_TYPES.RX_GET_USER_REPORT_PENDING,
  payload
})

export const rxGetUserReportDone = payload => ({
  type : REPORT_TYPES.RX_GET_USER_REPORT_DONE,
  payload
})



//REPORTS ACTIVITY
export const cntrlGetReportsActivity = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_REPORTS_ACTIVITY,
    payload,
  }
};
export const rxGetReportsActivityDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_ACTIVITY_DONE,
    payload,
  }
};
export const rxGetReportsActivityPending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_ACTIVITY_PENDING,
    payload,
  }
};

//REPORTS CONFIGURATIONS
export const cntrlGetReportsConfigurations = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_REPORTS_CONFIGS,
    payload,
  }
};

export const cntrlSetReportsConfigurations = payload => {
  return {
    type: REPORT_TYPES.CNTRL_SET_REPORTS_CONFIGS,
    payload,
  }
};

export const rxGetReportsConfigurationsDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_CONFIGS_DONE,
    payload,
  }
};
export const rxGetReportsConfigurationsPending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_CONFIGS_PENDING,
    payload,
  }
};

//REPORTS BY WINNING
export const cntrlGetReportsByWining = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_REPORTS_BY_WINNING,
    payload,
  }
};

export const rxGetReportsByWiningDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_BY_WINNING_DONE,
    payload,
  }
};

export const rxGetReportsByWiningPending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_BY_WINNING_PENDING,
    payload,
  }
};

//REPORTS BY STAKE
export const cntrlGetReportsByStake = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_REPORTS_BY_STAKE,
    payload,
  }
};
export const rxGetReportsByStakeDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_BY_STAKE_DONE,
    payload,
  }
};
export const rxGetReportsByStakePending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_REPORTS_BY_STAKE_PENDING,
    payload,
  }
};

//TOP BET_TYPE REPORTS
export const cntrlGetReportsByTopBetType = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_TOP_BET_TYPES,
    payload,
  }
};
export const rxGetReportsByTopBetTypeDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_TOP_BET_TYPES_DONE,
    payload,
  }
};
export const rxGetReportsByTopBetTypePending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_TOP_BET_TYPES_PENDING,
    payload,
  }
};

//DASHBOARD DATA
export const cntrlGetDashBoardData = payload => {
  return {
    type: REPORT_TYPES.CNTRL_GET_DASHBOARD_DATA,
    payload,
  }
};
export const rxGetDashBoardDataDone = payload => {
  return {
    type: REPORT_TYPES.RX_GET_DASHBOARD_DATA_DONE,
    payload,
  }
};
export const rxGetDashBoardDataPending = payload => {
  return {
    type: REPORT_TYPES.RX_GET_DASHBOARD_DATA_PENDING,
    payload,
  }
};



export const rxSetBetReportPageNumber = payload => ({
  type: REPORT_TYPES.RX_SET_BETREPORT_PAGE_NUMBER,
  payload,
});

export const rxSetBetReportRowsCount = payload => ({
  type: REPORT_TYPES.RX_SET_BETREPORT_ROWS_COUNT,
  payload,
});

export const rxSetBetTypeReportPageNumber = payload => ({
  type : REPORT_TYPES.RX_SET_BET_TYPE_REPORT_PAGE_NUMBER ,
  payload
});

export const rxSetBetTypeReportRowsCount = payload => ({
  type : REPORT_TYPES.RX_SET_BET_TYPE_REPORT_ROWS_COUNT,
  payload
});

export const rxSetFinancialReportPageNumber = payload => ({
  type : REPORT_TYPES.RX_SET_FINANCIAL_REPORT_PAGE_NUMBER ,
  payload
});

export const rxSetFinancialReportRowsCount = payload => ({
  type : REPORT_TYPES.RX_SET_FINANCIAL_REPORT_ROWS_COUNT,
  payload
});

export const rxSetGameReportPageNumber = payload => ({
  type : REPORT_TYPES.RX_SET_GAME_REPORT_PAGE_NUMBER,
  payload
});
export const rxSetGameReportRowsCount = payload => ({
  type : REPORT_TYPES.RX_SET_GAME_REPORT_ROWS_COUNT,
  payload
});

export const rxSetPlayersPageNumber = payload => ({
  type : REPORT_TYPES.RX_SET_PLAYERS_PAGE_NUMBER,
  payload
});
export const rxSetPlayersRowsCount = payload => ({
  type : REPORT_TYPES.RX_SET_PLAYERS_ROWS_COUNT,
  payload
});


export const cntrlGetPartnersFinancalReport = payload => ({
  type : REPORT_TYPES.CNTRL_GET_PARTNER_FINANCAL_REPORT,
  payload
});


export const rxGetPartnersFinancalReport = payload => ({
  type : REPORT_TYPES.RX_GET_PARTNER_FINANCAL_REPORT,
  payload
});


export const rxGetPartnersFinancalReportPending = payload => ({
  type : REPORT_TYPES.RX_GET_PARTNER_FINANCAL_REPORT_PENDING,
  payload
});

export const rxGetPartnersFinancalReportDone = payload => ({
  type : REPORT_TYPES.RX_GET_PARTNER_FINANCAL_REPORT_DONE,
  payload
});




