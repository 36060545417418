import TranslationsApiHandler from '../TranslationsApiHandler';

const END_POINTS = {
  prefix: 'Translations',
  game: 'game'
};

class TranslationsBackOfficeApiHandler extends TranslationsApiHandler{
  constructor(prefix) {
    super(prefix);
  }

  getTranslationsByID(query) {
    return this.get(END_POINTS.game, query)
  }
}

const translationsApi = new TranslationsBackOfficeApiHandler(END_POINTS.prefix);
export default translationsApi;
