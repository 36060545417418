import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
    prefix: 'GameTypes',
};

class SchedulerTemplatesApiHandler extends JogoBackOfficeApiHandler {
    constructor(prefix) {
        super(prefix);
        this.defaultCurrencyId = 45
    }

    getSchedulerTemplates() {
        return this.get()
    }

    setSchedulerTemplate(body) {
        return this.put('', body)
    }

    addSchedulerTemplate(body) {
        return this.post('', body)
    }

    deleteSchedulerTemplate(schedulerTemplateId) {
        return this.delete('',schedulerTemplateId)
    }

}


const schedulerTemplatesApiHandler = new SchedulerTemplatesApiHandler(END_POINT.prefix);
export default schedulerTemplatesApiHandler;
