import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix: 'Animals',
};


class AnimalsApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix)
  }

  getAnimals(){
    return this.get()
  }


}

const animalsApiHandler = new AnimalsApiHandler(END_POINT.prefix);
export default animalsApiHandler;
