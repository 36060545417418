import { ACCESS } from '../constants/util';
import ApiHandler from '../classes/ApiHandler';

const authSchemeFactory = (authScheme = 2) => {
  switch ( authScheme ) {
    case 2:
      return (userName, password) => {
        return {
          authScheme,
          data: {
            userName,
            password
          }
        }
      };
    default :
      throw new Error('authScheme is not defined')
  }

};
export {
  authSchemeFactory,
}

export const getIsAuthDefaultValue = () => {
  const access = JSON.parse(localStorage.getItem(ACCESS));
  if(access && access.expiresIn && access.expiresIn > Date.now()) {
    ApiHandler.token = access.accessToken;
    return true
  }
  return false
};