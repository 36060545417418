import { PARTNER_TYPES } from '../../core/constants/actionTypes';

// default animals actions

export const cntrlGetDefaultAnimals = payload => ({
    type: PARTNER_TYPES.CNTRL_GET_DEFAULT_ANIMALS,
    payload,
});
export const rxGetDefaultAnimalsDone = payload => ({
    type: PARTNER_TYPES.RX_GET_DEFAULT_ANIMALS_DONE,
    payload,
});
export const rxGetDefaultAnimalsPending = payload => ({
    type: PARTNER_TYPES.RX_GET_DEFAULT_ANIMALS_PENDING,
    payload,
});
