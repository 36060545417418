import { GAME_TYPES } from '../../core/constants/actionTypes';


// get games

export const cntrlGetGames = payload => ({
  type: GAME_TYPES.CNTRL_GET_GAMES,
  payload,
});

export const rxGetGamesDone = payload => ({
  type: GAME_TYPES.RX_GET_GAMES_DONE,
  payload,
});

export const rxGetGamesPending = payload => ({
  type: GAME_TYPES.RX_GET_GAMES_PENDING,
  payload,
});


// get game by id

export const cntrlGetGameById = payload => ({
  type: GAME_TYPES.CNTRL_GET_GAME_BY_ID,
  payload,
});


// set pagination parameters

export const rxSetPageNumber = payload => ({
  type: GAME_TYPES.RX_SET_PAGE_NUMBER,
  payload,
});

export const rxSetRowsCount = payload => ({
  type: GAME_TYPES.RX_SET_ROWS_COUNT,
  payload,
});

// cancel game

export const cntrlCanelGame = payload => ({
  type: GAME_TYPES.CNTRL_CANCEL_GAME,
  payload
});

// get result by id

export const cntrlGetResultById = payload => {
  return {
    type: GAME_TYPES.CNTRL_GET_RESULT_BY_ID,
    payload,
  }
};
export const rxGetResultByIdDone = payload => {
  return {
    type: GAME_TYPES.RX_GET_RESULT_BY_ID_DONE,
    payload,
  }
};
export const rxGetResultByIdPending = payload => {
  return {
    type: GAME_TYPES.RX_GET_RESULT_BY_ID_PENDING,
    payload,
  }
};

// add game result
export const cntrlAddGameResult = payload => {
  return {
    type: GAME_TYPES.CNTRL_ADD_GAME_RESULT,
    payload,
  }
};
export const rxAddGameResultDone = payload => {
  return {
    type: GAME_TYPES.RX_ADD_GAME_RESULT_DONE,
    payload,
  }
};
export const rxAddGameResultPending = payload => {
  return {
    type: GAME_TYPES.RX_ADD_GAME_RESULT_PENDING,
    payload,
  }
};

// set selected game id
export const rxSetSelectedGameId = payload => ({
  type: GAME_TYPES.RX_SET_SELECTED_GAME_ID,
  payload
});

//add Game

export const cntrlAddGame = payload => {
  return {
    type: GAME_TYPES.CNTRL_ADD_GAME,
    payload,
  }
};

//set calendar dates

export const rxSetCalendarDates = payload => {
  return {
    type: GAME_TYPES.RX_SET_CALENDAR_DATES,
    payload,
  }
};