
import Controller from '../../core/classes/AppController';
import {
  rxGetBetReport,
  rxGetBetReportDone,
  rxGetBetReportPending,
  rxGetBetTypeReportDone,
  rxGetBetTypeReportPending,
  rxGetGameReport,
  rxGetGameReportDone,
  rxGetGameReportPending, rxGetPartnersFinancalReportDone, rxGetPartnersFinancalReportPending,
  rxGetReportsActivityDone,
  rxGetReportsActivityPending,
  rxGetReportsByStakeDone,
  rxGetReportsByStakePending,
  rxGetReportsByTopBetTypeDone,
  rxGetReportsByTopBetTypePending,
  rxGetReportsByWiningDone,
  rxGetReportsByWiningPending,
  rxGetReportsConfigurationsDone,
  rxGetReportsConfigurationsPending,
  rxGetUserReportDone,
  rxGetUserReportPending
} from '../../state-management/actions/reportAction';

class ReportsController extends Controller {
  constructor(reportsOperation) {
    super();
    this.reportsOperation = reportsOperation;
  }

  async getBetReport(store, action) {
    try {
      store.dispatch(rxGetBetReportPending());
      const response = await this.reportsOperation.getBetReport(action.payload);
      store.dispatch(rxGetBetReportDone(response));
    } catch (e) {
      return new Error(e)
    }
  }

  async getBetTypeReport(store, action) {

    try {
      store.dispatch(rxGetBetTypeReportPending());
      const response = await this.reportsOperation.getBetTypeReport(action.payload);
      store.dispatch(rxGetBetTypeReportDone(response));
    } catch (e) {
      return new Error(e);
    }
  }

  async getGameReport(store, action) {
    try {
      store.dispatch(rxGetGameReportPending());
      const response = await this.reportsOperation.getGameReport(action.payload);
      store.dispatch(rxGetGameReportDone(response));
    } catch (e) {
      return new Error(e);
    }
  }

  async getUserReport(store, action) {
    try {
      store.dispatch(rxGetUserReportPending());
      const response = await this.reportsOperation.getUserReport(action.payload);
      store.dispatch(rxGetUserReportDone(response));
    } catch (e) {
      return new Error(e);
    }
  }


  /**
   * @name getReportsActivity
   * @description controller method for getting user activity data about bets
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getReportsActivity(store, action) {
    try {
      store.dispatch(rxGetReportsActivityPending());
      const response = await this.reportsOperation.getReportsActivity(store, action);
      store.dispatch(rxGetReportsActivityDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  /**
   * @description method for dashboard info about top betTypes
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getTopBetTypeReport(store, action) {
    try {
      store.dispatch(rxGetReportsByTopBetTypePending());
      const response = await this.reportsOperation.getTopBetTypeReport(store, action);
      store.dispatch(rxGetReportsByTopBetTypeDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  /**
   * @description method for dashboard info about bets by stake
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getReportsByStake(store, action) {
    try {
      store.dispatch(rxGetReportsByStakePending());
      const response = await this.reportsOperation.getBetReportByStake(store, action);
      store.dispatch(rxGetReportsByStakeDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  /**
   * @description method for dashboard info about bets by winning amount
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getReportByWinning(store, action) {
    try {
      store.dispatch(rxGetReportsByWiningPending());
      const response = await this.reportsOperation.getReportByWinning(store, action);
      store.dispatch(rxGetReportsByWiningDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  /**
   * @description method for dashboard configs
   * @param store
   * @param action
   * @returns {Promise<*>}
   */
  async getDashboardConfigs(store, action) {
    try {
      store.dispatch(rxGetReportsConfigurationsPending());
      const response = await this.reportsOperation.getDashboardConfigs(store, action);
      await this.getDashBoardData(store, action);
      store.dispatch(rxGetReportsConfigurationsDone(response));
      return response;
    } catch (e) {
      return e
    }
  }

  async getDashBoardData(store, action) {
    try {
      const response = await this.reportsOperation.getDashBoardData(store, action);
    } catch (e) {
      return e
    }
  }

  async setReportsConfigs(store, action) {
    try {
      const response = await this.reportsOperation.setReportsConfigs(store, action.payload);
      return response;
    } catch (e) {
      return e;
    }
  }

  /**
   * description
   * @param store
   * @param action
   * @returns {Promise<void>}
   */

  async getPartnersFinancalReport(store, action){

    try {
      store.dispatch(rxGetPartnersFinancalReportPending());
      const response = await this.reportsOperation.getPartnersFinancialReport(store, action);
      store.dispatch(rxGetPartnersFinancalReportDone(response));
    }catch (e) {
      return new Error(e);
    }
  }

}



export default ReportsController;