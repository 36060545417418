import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import { addResultInput } from '../../../core/constants/InputsConstants';

class GamesOperation extends Operations{
  constructor(gamesApiHandler){
    super();
    this.gamesApiHandler = gamesApiHandler
  }


  async getGameResultById(store, action){
    const response = await this.gamesApiHandler.getGameResultById(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * @description get game results
   * @param store
   * @param action
   * @returns {Promise.<void>}
   */
  async getGames(store, action){
    let queryObject = Object.keys(action.payload).length ? {...action.payload} : null;
    for(let key in  queryObject){
      if(queryObject[key] === '' && queryObject.hasOwnProperty(key)){
        delete queryObject[key]
      }
    }
    const response = await this.gamesApiHandler.getGames(queryObject);
    return responseErrorCheck(response);
  }


  /**
   * get game by Id
   * @param store
   * @param action
   * @returns {Promise.<void>}
   */
  async getGameById(store, action){
    const response = await this.gamesApiHandler.getGameById(action.payload);
    return responseErrorCheck(response);
  }

  /**
   * acton.payload is object, and in it are gameId and results
   * @param store
   * @param action
   * @returns {Promise.<void>}
   */
  async addGameResultById(store, action){
    let data;
    if(action.payload && Object.keys(action.payload).length === 2){
      data = {...action.payload, second: action.payload.first,  third: action.payload.first,  fourth: action.payload.first, fifth: action.payload.first,  }
    }else {
      data = action.payload
    }
    const response = await this.gamesApiHandler.addGameResultById(action.payload.gameId, data);
    return responseErrorCheck(response);
  }

  async getGamesLastResults(store, action){
    const response = await this.gamesApiHandler.getGamesLastResults();
    return responseErrorCheck(response);
  }

  async getActiveGamesById(store, action){
    const response = await this.gamesApiHandler.getActiveGamesById();
    return responseErrorCheck(response);
  }

  async getGameAutoGeneratesResultById(store, action){
    const response = await this.gamesApiHandler.getGameAutoGeneratesResultById();
    return responseErrorCheck(response);
  }

  async cancelGame(store, action){
    const response = await this.gamesApiHandler.cancelGameById(action.payload);
    return responseErrorCheck(response);
  }

  async addGame(store, action){
    const partnerId = store.getState().partnerSettings.data.partnerId;
    const isAdmin = store.getState().userSettings.data.type;

    const payload = !!isAdmin ? {PartnerIds: [partnerId] , ...action.payload} : action.payload;
    const response = await this.gamesApiHandler.addGames(payload);
    return responseErrorCheck(response);
  }



}
export default GamesOperation;
