const keyToTranslationMapper = name => {
  const mappedValue = name.replace(/[A-Z]/g, (...args) => {
    return '_' + args[0]
  });
  if (mappedValue[0] === "_") {
    return mappedValue.slice(1).toUpperCase()
  }
  return mappedValue.toUpperCase();
};

export default keyToTranslationMapper;