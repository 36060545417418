import {SCHEDULER_TEMPLATES_TYPES} from '../../core/constants/actionTypes';

// Scheduler Template

export const cntrlGetSchedulerTemplates = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.CNTRL_GET_SCHEDULER_TEMPLATE,
    payload,
});
export const rxGetSchedulerTemplatesDone = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.RX_GET_SCHEDULER_TEMPLATE_DONE,
    payload,
});
export const rxGetSchedulerTemplatesPending = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.RX_GET_SCHEDULER_TEMPLATE_PENDING,
    payload,
});

export const cntrlEditSchedulerTemplate = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.CNTRL_EDIT_SCHEDULER_TEMPLATE,
    payload,
});
export const cntrlAddSchedulerTemplate = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.CNTRL_ADD_SCHEDULER_TEMPLATE,
    payload,
});
export const cntrlDeleteSchedulerTemplate = payload => ({
    type: SCHEDULER_TEMPLATES_TYPES.CNTRL_DELETE_SCHEDULER_TEMPLATE_BY_ID,
    payload,
});
