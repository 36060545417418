import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { translateText } from '../../../components/translation/index';
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon';
import { rxModalClose } from '../../../../state-management/actions/modalActions';
import DataLoading from '../../../components/DataLoading/DataLoading';
import { colors } from '../../../../core/constants/colors';

const styles = theme => ({
  root: {
    width: '330px',
    minWidth: '250px',
    background: '#fff',
    border: `1px solid ${colors.headerColor}`,
    borderRadius: '5px',
    fontSize: '1.1em',
    fontWeight: '500',
    overflowY: 'hidden'
  },
  header: {
    height: '50px',
    background: colors.headerColor,
    marginBottom: '20px',
    padding: '0 10px',
    color: '#fff'
  },
  paper: {
    height: '50px',
    border: `1px solid ${colors.headerColor}`,
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: `${colors.headerColor}`,
    fontSize: '1.2em',
    fontWeight: '500',
  },
  icon: {
    cursor: 'pointer'
  },
  number: {
    letterSpacing: '2px'
  }
});

const ResultModal = React.memo(({ classes, isAdmin, ...props }) => {


  const result = Object.entries(props.gameResult.data);
  const resultName = ['1st', '2nd', '3td', '4th', '5th'];


  return (
    <div className={classes.root}>
      <Grid container  alignContent={'flex-start'} justify={'center'}>
        <Grid container  className={classes.header} justify={'space-between'} alignItems={'center'}>
          <Grid item>
            <p>{`${translateText('GAME_ID', true)} - ${props.gameId}`}</p>
          </Grid>
          <Grid item>
            <Icon onClick={props.close} className={classes.icon}>close</Icon>
          </Grid>
        </Grid>
        {
          DataLoading(props.gameResult.isLoading, props.gameResult.data,
            <Grid container  justify={'center'}>
              {
                (isAdmin || props.partnerSettings.hasMultipleDrawings) ?
                  result.map((i, index) => {
                    return <Grid container  justify={'center'} key={`${index}`}>
                      <Grid item xs={2}>
                        <Paper className={classes.paper}>{resultName[index]}</Paper>
                      </Grid>
                      <Grid item xs={8}>
                        <Paper className={[classes.paper, classes.number].join(' ')}>{i[1]}</Paper>
                      </Grid>
                    </Grid>
                  })
                  :
                  <Grid container  justify={'center'}>
                    <Grid item xs={10}>
                      <Paper className={[classes.paper, classes.number].join(' ')}>{result[0][1]}</Paper>
                    </Grid>
                  </Grid>
              }
            </Grid>
          )
        }

      </Grid>
    </div>
  );
});


const mapStateToProps = state => ({
  partnerSettings: state.partnerSettings.data,
  gameResult: state.gameReducer.gameResult,
  gameId: state.gameReducer.selectedGameId,
  isAdmin: !state.userSettings.data.type
});

const mapDispatchToProps = dispatch => ({
  close: _ => dispatch(rxModalClose())
});

ResultModal.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResultModal));

