import Enum from '../helpers/Enum';

export const EBetResult = Enum('UNKNOWN', 'WIN', 'LOSE', 'RETURNED',  'ALL')(0);

export const EDateConstants = {
  endDate: 'END_DATE',
  startDate: 'START_DATE'
};

export const EUserTypeEnum = {
  Admin: 0,
  Player: 1,
  BackOfficeAccount: 2
};

export const EPartnerViewMode = {
  classic: 0,
  alternate: 1
};

export const EDashBoardConfigTypes = [
  {
    blockName: 'activeUsers',
    enable: true,
    isActivity: true,
  }, {
    blockName: 'newActiveUsers',
    enable: true,
    isActivity: true,
  },  {
    blockName: 'profitOrLose',
    enable: true,
    isActivity: true,
  }, {
    blockName: 'totalBetAmount',
    enable: true,
    isActivity: true,
  },  {
    blockName: 'totalWonAmount',
    enable: true,
    isActivity: true,
  }, {
    blockName: 'totalBets',
    enable: true,
    isActivity: true,
  },{
    blockName: 'totalLoseBets',
    enable: true,
    isActivity: true,
  },{
    blockName: 'totalOpenBets',
    enable: true,
    isActivity: true,
  },{
    blockName: 'totalWinBets',
    enable: true,
    isActivity: true,
  },{
    blockName: 'TopBetsByWinning',
    enable: true,
    isActivity: false,
  },  {
    blockName: 'TopBetTypes',
    enable: true,
    isActivity: false,
  }, {
    blockName: 'TopBetsByStakeAmount',
    enable: true,
    isActivity: false,
  },
];
export const EUserType = ['Admin', 'Player', 'BackOfficeAccount'];


export const EGameResultFilter = {
  0: 'OPEN',
  1: 'FINISHED',
  2: 'CANCELLED'
};

export const EGameCreationType = {
  0: 'Manual',
  1: 'Auto'
};


export const EChartLabels = {
  winning: 'WINNING_AMOUNT',
  stake : 'STAKE_AMOUNT',
  bets: 'BET_COUNT',
};

export const ESkipTake = {
  skip: 'Skip',
  take: 'Take',
};