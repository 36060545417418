import {EBetResult, EGameCreationType, EGameResultFilter} from './enums';
import moment from 'moment';
import { translateText } from '../../view/components/translation/index';



export const searchByDate = [
  {
    label : 'START_TIME',
    variant : 'outlined',
    type : 'text',
    name : 'Start Time',
    key : 'Start_Time',
    onDoneClick : 'startTimeDone'
  },

  {
    label : 'END_TIME',
    variant : 'outlined',
    type : 'text',
    name : 'End Time',
    key : 'End_Time',
    onDoneClick : 'endTimeDone'
  }
];


export const betReportsSearchByDate = [
  {
    label : 'Start Time',
    variant : 'outlined',
    type : 'text',
    name : 'Start Time',
    key : 'Start Time'
  },

  {
    label : 'End Time',
    variant : 'outlined',
    type : 'text',
    name : 'End Time',
    key : 'End Time'
  }
];

export const betReportSearchFields = [
  {
    label:translateText('ID'),
    type : 'text',
    name: 'Id',
    key : 'Id',
  },
  {
    label:translateText('USER_ID'),
    type : 'text',
    name: 'UserId',
    key : 'UserId',
  },
  {
    label : translateText('USERNAME'),
    type: 'text',
    key : 'Username',
    name: 'Username',
  },

  {
    label: translateText('GAME_ID'),
    type : 'text',
    key : 'GameId',
    name: 'GameId',
  },
  {
    label: translateText('PLAYER_EXTERNAL_ID'),
    type : 'text',
    key : 'playerExternalId',
    name: 'PlayerExternalId',
  },
  {
    select: true,
    label: translateText('RESULT'),
    name: 'Result',
    type: 'select',
    key : 'Result',
    options : [
      {
        value: translateText(EBetResult[0]),
        label: EBetResult[0],
        id : 0
      },
      {
        value: translateText(EBetResult[1]),
        label: EBetResult[1],
        id : 1
      },
      {
        value: translateText(EBetResult[2]),
        label: EBetResult[2],
        id : 2
      },
      {
        value: translateText(EBetResult[3]),
        label: EBetResult[3],
        id : 3
      },
      {
        value: translateText(EBetResult[4]),
        label: EBetResult[4],
        id : 4
      },
    ]
  },
  {
    select: true,
    label: translateText('BET_TYPE'),
    name: 'BetTypeId',
    type: 'select',
    key : 'BetTypeId',
  },
];



export const betReportSearchInputs_1 = [
  {
    label:translateText('ID'),
    type : 'text',
    name: 'Id',
    key : 'Id',
  },
  {
    label : translateText('USERNAME'),
    type: 'text',
    key : 'Username',
    name: 'Username',
  },

  {
    label: translateText('GAME_ID'),
    type : 'text',
    key : 'GameId',
    name: 'GameId',
  },
  {
    label: translateText('EXTERNAL_ID'),
    type : 'text',
    key : 'ExternalId',
    name: 'ExternalId',

  },

];

export const betReportsSearchInputs_2 = [
  {
    select: true,
    label: 'RESULT',
    variant: 'outlined',
    name: 'Result',
    type: 'text',
    key : 'Result',
    margin : 'dense',
  },
  {
    select: true,
    label: 'BET_TYPE',
    variant: 'outlined',
    name: 'BetTypeId',
    type: 'text',
    key : 'BetTypeId',
    margin : 'dense',

  },
];

export const betTypeReportsByProfitOrLose = [
  {
    label : 'PROFIT_OR_LOSS_FROM',
    key : 'ProfitOrLoseFrom',
    margin : 'dense',
    variant : 'outlined',
    name : 'ProfitOrLoseFrom',
    helperText : 'Profit or Lose from',

  },

  {
    label : 'PROFIT_OR_LOSS_TO',
    key : 'ProfitOrLoseTo',
    margin : 'dense',
    variant : 'outlined',
    name : 'ProfitOrLoseTo',
    helperText : 'Profit or Lose To',

  },
]

export const betTypeReportsSearchFields = [
  {
    label : 'BET_TYPE_ID',
    margin : 'dense',
    variant : 'outlined',
    name : 'BetTypeId',
    helperText : 'Enter Id',
    key : 'Id'
  },
  {
    label : 'BET_COUNT_FROM',
    margin : 'dense',
    variant : 'outlined',
    name : 'BetsCountFrom',
    helperText : 'Enter Bets Count From',
    inputProps : {
      min : 0
    },
    key : 'BetsCountFrom'
  },
  {
    label : 'BET_COUNT_TO',
    margin : 'dense',
    variant : 'outlined',
    name : 'BetsCountTo',
    helperText : 'Enter Bets Count To',
    inputProps : {
      min : 0
    },
    key : 'BetsCountTo'
  },
  {
    label : 'MIN',
    margin : 'dense',
    variant : 'outlined',
    name : 'StakeAmountFrom',
    helperText : 'Stake Amount From',
    inputProps : {
      min : 0
    },
    key : 'Min'
  },

  {
    label : 'MAX',
    margin : 'dense',
    variant : 'outlined',
    name : 'StakeAmountTo',
    helperText : 'Stake Amount To',
    inputProps : {
      min : 0
    },
    key : 'StakeAmountTo'
  }
]

export const gameReportsSearchInputs = [
  {
    label : 'NAME',
    margin : 'dense',
    variant : 'outlined',
    type : 'text',
    key : 'Name',
    name : 'Name'
  },
  {
    label : 'STAKE_AMOUNT_FROM',
    margin : 'dense',
    variant : 'outlined',
    name : 'StakeAmountFrom',
    inputProps : {
      min : 0
    },
    key : 'Min'
  },

  {
    label : 'STAKE_AMOUNT_TO',
    margin : 'dense',
    variant : 'outlined',
    name : 'StakeAmountTo',
    inputProps : {
      min : 0
    },
    key : 'StakeAmountTo'
  },
  {
    label : 'BETS_COUNT_FROM',
    margin : 'dense',
    variant : 'outlined',
    name : 'BetsCountFrom',
    inputProps : {
      min : 0
    },
    key : 'BetsCountFrom'
  },
  {
    label : 'BETS_COUNT_TO',
    margin : 'dense',
    variant : 'outlined',
    name : 'BetsCountTo',
    inputProps : {
      min : 0
    },
    key : 'BetsCountTo'
  },
  {
    label : 'CREATION_TYPE',
    margin : 'dense',
    variant : 'outlined',
    name : 'CreationType',
    inputProps : {
      min : 0
    },
    key : 'CreationType'
  },
];





export const BetResultTypes = [
  {
    value: EBetResult[0],
    label: EBetResult[0],
    id : 0
  },
  {
    value: EBetResult[1],
    label: EBetResult[1],
    id : 1
  },
  {
    value: EBetResult[2],
    label: EBetResult[2],
    id : 2
  },
  {
    value: EBetResult[3],
    label: EBetResult[3],
    id : 3
  },
  {
    value: EBetResult[4],
    label: EBetResult[4],
    id : 4
  },
];


export const reportSearchsByInterval = [
  {
    timeInterval : 'Today',
    startDate : new Date(),
    endDate : new Date().setDate(new Date().getDate()+1)
  },
  {
    timeInterval : 'Yesterday',
    startDate: new Date().setDate(new Date().getDate() - 1),
    endDate : new Date()
  },

  {
    timeInterval : 'This Week',
    startDate : new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() ? 1 : -6))),
    endDate : new Date().setDate(new Date().getDate()+1)
  },
  {
    timeInterval : 'Last Week',
    startDate :  new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() ? -6 : -13))),
    endDate : new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() ? 1 : -6))),
  },
  {
    timeInterval : 'This Month',
    startDate : new Date(new Date().setDate(1)),
    endDate :  new Date().setDate(new Date().getDate()+1)
  },
  {
    timeInterval : 'Last Month',
    startDate : moment().subtract(1,'month').date(1),
    endDate : moment().date(0)
  },
];

export const timePickerConstants = {
  startTime: 'StartTime',
  endTime: 'EndTime',
};

export const gameResultsSelect = [
  {
    value: 5,
    name: translateText("ALL")
  },
  {
   value: 0,
   name: translateText(EGameResultFilter[0])
  },
  {
   value: 1,
   name: translateText(EGameResultFilter[1])
  },
  {
   value: 2,
   name: translateText(EGameResultFilter[2])
  }
];

export const gameTypeValue = [
  {
    value: 5,
    name: translateText("ALL")
  },
  {
    value: 0,
    name: translateText(EGameCreationType[0])
  },
  {
    value: 1,
    name: translateText(EGameCreationType[1])
  },
]

export const addResultInput = [
  {
    fullWidth: true,
    required: true,
    label: translateText('FIRST_RESULT'),
    margin:'dense',
    name: 'first'
  },
  {
    fullWidth: true,
    required: true,
    label: translateText('SECOND_RESULT'),
    margin:'dense',
    name: 'second'
  },
  {
    fullWidth: true,
    required: true,
    label: translateText('THIRD_RESULT'),
    margin:'dense',
    name: 'third'
  },
  {
    fullWidth: true,
    required: true,
    label: translateText('FOURTH_RESULT'),
    margin:'dense',
    name: 'fourth'
  },
  {
    fullWidth: true,
    required: true,
    label: translateText('FIFTH_RESULT'),
    margin:'dense',
    name: 'fifth'
  },
];

export const addGameInputs = [
  {
    fullWidth: true,
    required: true,
    label: translateText('NAME'),
    margin:'dense',
    name: 'name'
  },
  {
    fullWidth: true,
    required: false,
    label: translateText('RESULTS_URL'),
    margin:'dense',
    name: 'resultsUrl'
  },
];

export const addGameMaxPercentageInputs = [
  {
    fullWidth: true,
    required: false,
    label: translateText('MAX_GAME_STAKE_DEFAULT_PERCENTAGE'),
    margin:'dense',
    name: 'stake'
  },
  {
    fullWidth: true,
    required: false,
    label: translateText('MAX_GAME_DEFAULT_PERCENTAGE'),
    margin:'dense',
    name: 'winning'
  },
];

export const addPartnerModalInputs = [
  {
    fullWidth: true,
    required: true,
    label: translateText('PARTNER_NAME'),
    margin:'dense',
    name: 'partnerName',
    xs: 6
  },
  {
    fullWidth: true,
    required: true,
    label: translateText('PARTNER_ID'),
    margin:'dense',
    name: 'id',
    xs: 6,
    type: 'number'
  },
  {
    fullWidth: true,
    required: false,
    label: translateText('USER_NAME'),
    margin:'dense',
    name: 'userName',
    xs: 6
  },
  {
    fullWidth: true,
    required: false,
    label: translateText('PASSWORD'),
    margin:'dense',
    name: 'password',
    xs: 6
  },
];