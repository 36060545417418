import Controller from '../../core/classes/AppController';
import { rxGetLanguagesDone, rxGetLanguagesPending } from '../../state-management/actions/translationsActions';

class LanguagesController extends Controller {
  constructor(languagesOperations) {
    super();
    this.languagesOperations = languagesOperations;
  }

  async getLanguages(store, action) {
    try {
      store.dispatch(rxGetLanguagesPending());
      const response = await this.languagesOperations.getLanguages(store, action);
      store.dispatch(rxGetLanguagesDone(response?.items || []));
      return response;
    } catch ( e ) {
      return e;
    }
  }
}

export default LanguagesController;