import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { componentSwitcher } from '../../../core/helpers/componentSwitcher';
import './styles.css'
import MODAL_NAMES from '../../../core/constants/modalNames';
import ResultModal from './resultsModal/resultsModal';
import DashBoardConfig from './DashboardConfig/DashboardConfig';
import AddResultModal from './addResultModal/addResultModal';
import AddGameModal from './addGameModal/addGameModal'
import AddPartnerModal from './addPartnerModal/addPartnerModal'

const renderItem = (type) => {
  switch (type) {
    case MODAL_NAMES.ADD_RESULT_MODAL:
      return <AddResultModal/>;
    case MODAL_NAMES.RESULT_MODAL:
      return <ResultModal/>;
    case MODAL_NAMES.DASHBOARD_CONFIG:
      return <DashBoardConfig/>;
    case MODAL_NAMES.ADD_GAME_MODAL:
      return <AddGameModal/>;
    case MODAL_NAMES.ADD_PARTNER_MODAL:
      return <AddPartnerModal/>;
    default:
      return null;
  }
};


const Modals = ({ modalState, onClose }) => {

  return componentSwitcher(modalState.length,
    ReactDOM.createPortal(
      <div onClick={onClose} className='modal-wrapper'>
        <div className={`modal-content`} onClick={event => event.stopPropagation()}>
          {renderItem(modalState[modalState.length - 1])}
        </div>
      </div>,
      document.documentElement.children[1]
    )
  );
};

Modals.defaultProps = {};

Modals.propTypes = {
  modalState: PropTypes.array,
  onClose: PropTypes.func
};

export default Modals;