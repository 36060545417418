import { AUTH_TYPES } from '../../core/constants/actionTypes';

export const cntrlLogin = (userName, password) =>({
  type: AUTH_TYPES.CNTRL_LOG_IN,
  payload: {
    userName,
    password
  }
});

export const cntrlRefreshToken = payload => ({
  type: AUTH_TYPES.CNTRL_REFRESH_TOKEN,
})
;
export const cntrlLogOut = payload => ({
  type: AUTH_TYPES.CNTRL_LOGOUT,
});

export const rxSetIsAuth = payload => ({
  type: AUTH_TYPES.RX_SET_AUTH_BOOL,
  payload,
});