import JogoBackOfficeApiHandler from "../JogoBackOfficeApiHandler";
import PartnerModule, {
  partnerModule
} from "../../../core/moduls/PartnerModul";
import { is } from "react-date-range/src/locale";

const END_POINT = {
  prefix: "Partners",
  betTypes: "betTypes",
  customizationConfig: "customizationConfig",
  status: "status",
  settings: "settings",
  animals: "animals",
  betTypeInfo:"betTypeInfo",
  helpItems: "helpItems",
  config: "config",
  currency: "currency",
  // schedulerTemplate: 'schedulerTemplate',
  betTypeConfigs: "betTypeConfigs",
  mainLimits: "mainLimits",
  partnerID: 4,
  backofficeLanguages: "backofficeLanguages",
  betTypeGroups: "betTypeGroups",
  betTypeGroup: "betTypeGroup",
  currencies: "currencies",
  animalsReset: "animals/images/default",
  backgrounds: "customizationConfig/images",
  backgroundsReset: "customizationConfig/images/default",
  partnerGameTypeLimit : 'partnerGameTypeLimit'
};

class PartnersApiHandler extends JogoBackOfficeApiHandler {
  constructor(prefix) {
    super(prefix);
    this.defaultCurrencyId = 45;
  }

  getPartners() {
    return this.get();
  }
  getPartnerGameTypeLimits(data){
    return this.get(END_POINT.partnerGameTypeLimit,data)
  }
  editPartnerGameTypeLimits(data){
    return this.put(END_POINT.partnerGameTypeLimit,data)
  }
  addPartner(body) {
    return this.post("", body);
  }

  deletePartnersById(id) {
    return this.delete(`${id}`);
  }

  setPartnersStatusById(id, data) {
    return this.put(`${id}/${END_POINT.status}`, data);
  }

  getPartnersBetTypesById(
    id = partnerModule.getConfigItem(PartnerModule.constants.partnerId) || 4
  ) {
    return this.get(`${id}/${END_POINT.betTypes}`);
  }

  getPartnerCustomizationConfigById(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.customizationConfig}`);
  }

  putPartnerCustomizationConfigById(data, id, isFormData) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.put(
      `${partnerId}/${END_POINT.customizationConfig}`,
      data,
      "",
      isFormData
    );
  }
  resetPartnerBackgrounds(id) {
    const partnerId =
        id !== null
            ? id
            : partnerModule.getConfigItem(PartnerModule.constants.partnerId);

    return this.put(`${partnerId}/${END_POINT.backgroundsReset}`, "", "", false);
  }

  setPartnerCustomizationConfigBackgrounds({partnerId: id, data}){
      const partnerId =
          id !== null
              ? id
              : partnerModule.getConfigItem(PartnerModule.constants.partnerId);

      return this.put(`${partnerId}/${END_POINT.backgrounds}`, data, "", true);

  }

  getPartnerSettings(
    id = partnerModule.getConfigItem(PartnerModule.constants.partnerId)
  ) {
    return this.get(`${id}/${END_POINT.settings}`);
  }

  getPartnerAnimalsById(id) {
    return this.get(`${id}/${END_POINT.animals}`);
  }

  addPartnerAnimalsById(id, data) {
    return this.post(`${id}/${END_POINT.animals}`, data);
  }

  getPartnerHelpItemsById(id) {
    return this.get(`${id}/${END_POINT.helpItems}`);
  }

  getPartnersConfigById(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.config}`);
  }

  getBackofficeLanguages(
    id = partnerModule.getConfigItem(PartnerModule.constants.partnerId)
  ) {
    return this.get(`${id}/${END_POINT.backofficeLanguages}`);
  }

  addPartnersConfig(data) {
    let newFormData = { ...data.tempConfig };
    let queryParam = "";

    if (data.partnerValue !== null) {
      queryParam = { partnerId: data.partnerValue };
    }
    return this.post(END_POINT.config, newFormData, queryParam);
  }

  addPartnerCurrencyById(id) {
    return this.post(`${id}/${END_POINT.currency}`);
  }

  deletePartnerCurrencyById(id) {
    return this.delete(`${id}/${END_POINT.currency}`);
  }

  // getPartnersSchedulerTemplate() {
  //   return this.get(`${END_POINT.schedulerTemplate}`)
  // }
  //
  // setPartnersSchedulerTemplate(body) {
  //   return this.put(`${END_POINT.schedulerTemplate}`, body)
  // }
  //
  // addPartnersSchedulerTemplate(body) {
  //   return this.post(`${END_POINT.schedulerTemplate}`, body)
  // }
  //
  // deletePartnersSchedulerTemplate(schedulerTemplateId) {
  //   return this.delete(`${END_POINT.schedulerTemplate}/${schedulerTemplateId}`)
  // }

  getPartnerBetTypeConfigs(currencyId, id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(END_POINT.betTypeConfigs, { partnerId, currencyId });
  }

  setPartnerBetTypeConfigs(data, id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.post(END_POINT.betTypeConfigs, data, { partnerId });
  }

  getPartnerMainLimits(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(END_POINT.mainLimits, { partnerId });
  }

  postPartnerMainLimits(data, id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.post(END_POINT.mainLimits, data, { partnerId });
  }

  getPartnerCurrencies(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.currencies}`);
  }
  getPartnerBetTypesGroups(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.betTypeGroups}`);
  }

  addPartnerBetTypesGroup({ partnerId, data }) {
    return this.post(`${END_POINT.betTypeGroup}`, data);
  }

  deletePartnerBetTypesGroup({ partnerId, groupId }) {
    return this.delete(`${END_POINT.betTypeGroup}/${groupId}`);
  }
  editPartnerBetTypesGroup({ partnerId, groupId, data }) {
    return this.put(`${END_POINT.betTypeGroup}/${groupId}`, data);
  }
  getPartnerBetTypeInfo(id) {
    const partnerId =
        id !== null
            ? id
            : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.betTypeInfo}`);
  }
  editPartnerBetTypeInfo({ partnerId: id, data }) {

    const partnerId =
        id !== null
            ? id
            : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.put(`${partnerId}/${END_POINT.betTypeInfo}`, data, "", true);
  }

  getPartnerAnimals(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);
    return this.get(`${partnerId}/${END_POINT.animals}`);
  }
  editPartnerAnimals({ partnerId: id, data , circleAnimals }) {
    const query = circleAnimals ? 'Circle' : 'Default'
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);

    return this.put(`${partnerId}/${END_POINT.animals}`, data, {animalType:query}, true);
  }

  resetPartnerAnimals(id) {
    const partnerId =
      id !== null
        ? id
        : partnerModule.getConfigItem(PartnerModule.constants.partnerId);

    return this.put(`${partnerId}/${END_POINT.animalsReset}`, "", "", false);
  }
}

const partnersApiHandler = new PartnersApiHandler(END_POINT.prefix);
export default partnersApiHandler;
