class PartnerModule {

  static constants = {
    partnerId: 'partnerId'
  };

  constructor(config = {}) {
    this.config = config
  }

  getConfigItem(itemKey) {
    return this.config[itemKey];
  }

  valueOf() {
    return this.config;
  }

  setConfigItem(itemKey, itemValue) {
    PartnerModule.setConstants(itemKey);
    this.config[itemKey] = itemValue;
    return itemValue
  }

  static setConstants(key, value = key) {
    if (!PartnerModule.constants.key) {
      PartnerModule.constants[key] = value
    }
  }
}

const partnerModule = new PartnerModule();
export { partnerModule }
export default PartnerModule
