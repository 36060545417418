import React, { useEffect, useState, Suspense } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { cntrlAppInit } from '../../../state-management/actions/appActions';
import { connect } from 'react-redux';
import { rxSetIsAuth } from '../../../state-management/actions/tokenActions';
import { defaultPath } from '../../../routing/router';
import CircularProgress from '@material-ui/core/CircularProgress';

const Splash = props => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    props.init().then(isValid => {
      setLoading(false);
      props.setIsAuth(!!isValid);

      // console.log(!isValid, props.location.pathname)

      return !isValid ? props.history.push('/login') : props.history.push({
        path: props.location.pathname || defaultPath,
        search: props.location.search
      });
    })
  }, []);

  return isLoading ?
    <div style={{width: '100%', height: '100%', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress/>
    </div> : <Suspense fallback={<p>Loading</p>}>   {props.children}</Suspense>
};

Splash.defaultProps = {};

Splash.propTypes = {};

const mapStateToProps = state => ({
  isAuth: state.isAuth
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(cntrlAppInit()),
  setIsAuth: bool => dispatch(rxSetIsAuth(bool)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Splash));