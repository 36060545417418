import Controller from '../../core/classes/AppController';
import { rxSetIsAuth } from '../../state-management/actions/tokenActions';
import ApiHandler from '../../core/classes/ApiHandler';
import { ACCESS } from '../../core/constants/util';
import LocalStorageHelper from '../../core/helpers/LocalStorageHelper';

class TokenController extends Controller{
  constructor(tokenOperation){
    super();
    this.tokenOperation = tokenOperation;
  }

  async login(store, action) {
    try {
      const response = await this.tokenOperation.login(store, action);
      return response;
    } catch ( e ) {
      store.dispatch(rxSetIsAuth(false));
      return e;
      // return new Error(e);
    }
  }

  async refreshToken(store, action) {
    try {
      return await this.tokenOperation.refreshToken();
    } catch ( e ) {
      ApiHandler.token = "";
      LocalStorageHelper.deleteItem(ACCESS);
      store.dispatch(rxSetIsAuth(false));
      return e
    }
  }

  logOut(store, action) {
    this.tokenOperation.logout(store)
  }
}

export default TokenController;