import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'Users',
  settings : 'settings',
  addBackofficeAccount : 'addBackofficeAccount',
  balance : 'balance',
  changeCurrency : 'changeCurrency',
  isUsernameTaken : 'isUsernameTaken',
  updateResetedPassword : 'updateResetedPassword',
  updateUserPermissions : 'updateUserPermissions',
  reportConfigs : 'reportConfigs',
  changeLanguageId : 'changeLanguageId',
};

class UsersApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }
  getUsers(){
    return this.get('', '?skip=0&take=20' );
  }
  editUser(data){
    return this.put('', data);
  }
  getUsersSettings(){
    return this.get(END_POINT.settings);
  }
  addUserBackOfficeAccount(data){
    return this.post(END_POINT.addBackofficeAccount, data);
  }
  getUsersBalance(){
    return this.get(END_POINT.balance);
  }
  editCurrency(currencyId){
    return this.post(`${END_POINT.changeCurrency}/${currencyId}`);
  }

  editLanguage(languageId){
    return this.post(`${END_POINT.changeLanguageId}/${languageId}`);
  }
  getIsUserNameTaken(){
    return this.get(END_POINT.isUsernameTaken)
  }
  updateResetedPassword(data){
    return this.post(END_POINT.updateResetedPassword, data);
  }
  updateUserPermissions(data){
    return this.put(END_POINT.updateUserPermissions, data)
  }

  getDashboardConfigs(data){
    return this.get(END_POINT.reportConfigs, data)
  }

  postDashboardConfigs(dashboard){
    const formData = { dashboard };
    return this.post(END_POINT.reportConfigs, formData)
  }

}
const usersApiHandler = new UsersApiHandler(END_POINT.prefix);
export default usersApiHandler;