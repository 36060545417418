import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class AnimalsOperation extends Operations{
  constructor(animalsApiHandler){
    super();
    this.animalsApiHandler = animalsApiHandler;
  }
  async getDefaultAnimals(){
    const response = await this.animalsApiHandler.getAnimals();
    return responseErrorCheck(response);
  }

}

export default AnimalsOperation;
