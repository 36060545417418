import { connect } from 'react-redux';
import Modals from './Modals';
import { rxModalClose } from '../../../state-management/actions/modalActions';

const mapStateToProps = state => ({
  modalState: state.modalState,

});

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(rxModalClose());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals)