import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix : 'JackpotEntries',
  ticket : 'ticket'
}

class JackpotEntriesApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);

  }
  getJackpotEntries(){
    return this.get()
  }

  addJeckpotEntries(data){
    return this.post(`${END_POINT.ticket}`, data)
  }

}

const jackpotEntriesApiHandler = new JackpotEntriesApiHandler(END_POINT.prefix);
export default jackpotEntriesApiHandler;