import Controller from "../../core/classes/AppController";
import {
  cntrlGetBetTypeGroups,
  cntrlGetPartnerBetTypeConfig,
  cntrlGetPartnerCustomizationConfig,
  cntrlGetPartnerMainLimits,
  cntrlGetPartners,
  rxGetBetTypeGroupsDone,
  rxGetBetTypeGroupsPending,
  rxGetPartnerBetTypeConfigDone,
  rxGetPartnerBetTypeConfigPending,
  rxGetPartnerConfigDone,
  rxGetPartnerConfigPending,
  rxGetPartnerCurrenciesDone,
  rxGetPartnerCurrenciesPending,
  rxGetPartnerCustomizationConfigDone,
  rxGetPartnerCustomizationConfigPending,
  rxGetPartnerMainLimitsDone,
  rxGetPartnerMainLimitsPending,
  rxGetPartnersDone,
  rxGetPartnerSettingsDone,
  rxGetPartnerSettingsPending,
  rxGetPartnersPending,
  rxGetPartnerAnimalsDone,
  rxGetPartnerAnimalsPending,
  cntrlGetPartnerAnimals,
  rxGetPartnerBetTypePending,
  rxGetPartnerBetTypeDone,
  rxGetPartnerBetTypeInfoPending,
  rxGetPartnerBetTypeInfoDone,
  cntrlGetPartnerBetTypeInfo,
  rxGetPartnerGameTypeLimitsDone,
  rxGetPartnerGameTypeLimitsPending, rxEditPartnerGameTypeLimitsPending, rxEditPartnerGameTypeLimitsDone
} from "../../state-management/actions/partnerActions";
import { rxModalClose } from "../../state-management/actions/modalActions";

class PartnersController extends Controller {
  constructor(partnersOperation) {
    super();
    this.partnersOperation = partnersOperation;
  }
  async getPartnerGameTypeLimits(store, action) {
    try {
      store.dispatch(rxGetPartnerGameTypeLimitsPending());
      const response = await this.partnersOperation.getPartnerGameTypeLimits(
          store,
          action
      );
      store.dispatch(rxGetPartnerGameTypeLimitsDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }
  async editPartnerGameTypeLimits(store, action) {
    try {
      store.dispatch(rxEditPartnerGameTypeLimitsPending());
      const response = await this.partnersOperation.editPartnerGameTypeLimits(
          store,
          action
      );
      store.dispatch(rxEditPartnerGameTypeLimitsDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async getCustomizationConfig(store, action) {
    try {
      store.dispatch(rxGetPartnerCustomizationConfigPending());
      const customizationConfig = await this.partnersOperation.getPartnerCustomizationConfigById(
        store,
        action
      );
      store.dispatch(rxGetPartnerCustomizationConfigDone(customizationConfig));
      return customizationConfig;
    } catch (e) {
      return new Error(e);
    }
  }

  async getPartnersBetTypesById(store, action) {
    try {
      store.dispatch(rxGetPartnerBetTypePending());
      const response = await this.partnersOperation.getPartnersBetTypesById(
        store,
        action
      );
      store.dispatch(rxGetPartnerBetTypeDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }


  async setCustomizationConfigColors(store, action) {
    try {
      await this.partnersOperation.setPartnerCustomizationConfigColors(
        store,
        action
      );
      store.dispatch(
        cntrlGetPartnerCustomizationConfig(action.payload.partnerValue)
      );
    } catch (e) {
      return new Error(e);
    }
  }

  async setCustomizationConfigBackgrounds(store, action) {
    try {
      await this.partnersOperation.setPartnerCustomizationConfigBackgrounds(
        action.payload
      );
      store.dispatch(
        cntrlGetPartnerCustomizationConfig(action.payload.partnerId)
      );
    } catch (e) {
      return new Error(e);
    }
  }
  async resetCustomizationConfigBackgrounds(store, action) {
    try {
      const response = await this.partnersOperation.resetPartnerBackgrounds(
        action.payload
      );
      store.dispatch(cntrlGetPartnerCustomizationConfig(action.payload));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async getPartnerConfig(store, action) {
    try {
      store.dispatch(rxGetPartnerConfigPending());
      const response = await this.partnersOperation.getPartnersConfigById(
        store,
        action.payload
      );
      store.dispatch(rxGetPartnerConfigDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async editPartnerConfig(store, action) {
    try {
      await this.partnersOperation.addPartnersConfig(store, action);
      const response = await this.partnersOperation.getPartnersConfigById(
        store,
        action.payload.partnerValue
      );
      store.dispatch(rxGetPartnerConfigDone(response));
    } catch (e) {
      return new Error(e);
    }
  }

  // ?PartnersSchedulerTemplates start

  // async getPartnersSchedulerTemplates(store, action) {
  //   try {
  //     store.dispatch(rxGetPartnerSchedulerTemplatesPending());
  //     const partnerSchedulerTemplates = await this.partnersOperation.getPartnersSchedulerTemplate(store, action);
  //     store.dispatch(rxGetPartnerSchedulerTemplatesDone(partnerSchedulerTemplates));
  //
  //   } catch ( e ) {
  //     return new Error(e);
  //   }
  // }
  //
  // async editPartnersSchedulerTemplate(store, action) {
  //   try {
  //     await this.partnersOperation.setPartnersSchedulerTemplate(store, action)
  //   } catch ( e ) {
  //     return new Error(e);
  //   }
  // }
  //
  // async addPartnersSchedulerTemplate(store, action) {
  //   try {
  //     await this.partnersOperation.addPartnersSchedulerTemplate(store, action);
  //     await this._refreshSchedule(store, action);
  //   } catch ( e ) {
  //     return new Error(e);
  //   }
  // }
  //
  // async deletePartnersSchedulerTemplateById(store, action) {
  //   try {
  //     await this.partnersOperation.deletePartnersSchedulerTemplate(store, action);
  //     await this._refreshSchedule(store, action);
  //   } catch ( e ) {
  //     return new Error(e);
  //   }
  // }

  // ?PartnersSchedulerTemplates end

  async getPartners(store, action) {
    try {
      store.dispatch(rxGetPartnersPending());
      const response = await this.partnersOperation.getPartners(store, action);
      store.dispatch(rxGetPartnersDone(response));
      return response;
    } catch (e) {
      return e;
    }
  }

  async getPartnerSettings(store, action) {
    try {
      store.dispatch(rxGetPartnerSettingsPending());
      const response = await this.partnersOperation.getPartnerSettings(
        store,
        action
      );
      store.dispatch(rxGetPartnerSettingsDone(response));
      return response;
    } catch (e) {
      return e;
    }
  }

  //partner bet type config
  async getPartnerBetTypeConfig(store, action) {
    try {
      store.dispatch(rxGetPartnerBetTypeConfigPending());
      const response = await this.partnersOperation.getPartnerBetTypeConfigs(
        store,
        action
      );
      store.dispatch(rxGetPartnerBetTypeConfigDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async setPartnerBetTypeConfig(store, action) {
    try {
      await this.partnersOperation.setPartnerBetTypeConfigs(store, action);
      store.dispatch(cntrlGetPartnerBetTypeConfig(action.payload));
    } catch (e) {
      return new Error(e);
    }
  }

  // async _refreshSchedule(store, action) {
  //   const partnerSchedulerTemplates = await this.partnersOperation.getPartnersSchedulerTemplate(store, action);
  //   store.dispatch(rxGetPartnerSchedulerTemplatesDone(partnerSchedulerTemplates));
  // }

  async addPartner(store, action) {
    try {
      const response = await this.partnersOperation.addPartner(store, action);
      store.dispatch(cntrlGetPartners());
      store.dispatch(rxModalClose());
    } catch (e) {
      return e;
    }
  }

  // partner main limits
  async getPartnerMainLimits(store, action) {
    try {
      store.dispatch(rxGetPartnerMainLimitsPending());
      const response = await this.partnersOperation.getPartnerMainLimits(
        store,
        action
      );
      store.dispatch(rxGetPartnerMainLimitsDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async setPartnerMainLimits(store, action) {
    try {
      await this.partnersOperation.postPartnerMainLimits(store, action);
      store.dispatch(cntrlGetPartnerMainLimits(action.payload.partnerValue));
    } catch (e) {
      return new Error(e);
    }
  }

  //partner betTypes group
  async getPartnerBetTypesGroups(store, action) {
    try {
      store.dispatch(rxGetBetTypeGroupsPending());
      const response = await this.partnersOperation.getPartnerBetTypesGroups(
        store,
        action
      );
      store.dispatch(rxGetBetTypeGroupsDone(response));
    } catch (e) {
      return new Error(e);
    }
  }

  async addPartnerBetTypesGroup(store, action) {
    try {
      await this.partnersOperation.addPartnerBetTypesGroup(store, action);
      store.dispatch(cntrlGetBetTypeGroups(null));
    } catch (e) {
      return new Error(e);
    }
  }

  async deletePartnerBetTypesGroup(store, action) {
    try {
      await this.partnersOperation.deletePartnerBetTypesGroup(store, action);
      store.dispatch(cntrlGetBetTypeGroups(null));
    } catch (e) {
      return new Error(e);
    }
  }

  async editPartnerBetTypesGroup(store, action) {
    try {
      await this.partnersOperation.editPartnerBetTypesGroup(store, action);
      store.dispatch(cntrlGetBetTypeGroups(null));
    } catch (e) {
      return new Error(e);
    }
  }
  async getPartnerCurrencies(store, action) {
    try {
      store.dispatch(rxGetPartnerCurrenciesPending());
      const response = await this.partnersOperation.getPartnerCurrencies(
        store,
        action
      );
      store.dispatch(rxGetPartnerCurrenciesDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  // partner animals

  async getPartnerAnimals(store, action) {
    try {
      store.dispatch(rxGetPartnerAnimalsPending());
      const response = await this.partnersOperation.getPartnerAnimals(
        store,
        action
      );
      store.dispatch(rxGetPartnerAnimalsDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async getPartnerBetTypeInfo(store, action) {
    try {
      store.dispatch(rxGetPartnerBetTypeInfoPending());
      const response = await this.partnersOperation.getPartnerBetTypeInfo(
        store,
        action
      );
      store.dispatch(rxGetPartnerBetTypeInfoDone(response));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }
  async editPartnerBetTypeInfo(store, action) {
    try {
      const response = await this.partnersOperation.editPartnerBetTypeInfo(
        action.payload
      );
      store.dispatch(cntrlGetPartnerBetTypeInfo(action.payload.partnerId));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async editPartnerAnimals(store, action) {
    try {
      const response = await this.partnersOperation.editPartnerAnimals(
        action.payload
      );
      store.dispatch(cntrlGetPartnerAnimals(action.payload.partnerId));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }

  async resetPartnerAnimals(store, action) {
    try {
      const response = await this.partnersOperation.resetPartnerAnimals(
        action.payload
      );
      store.dispatch(cntrlGetPartnerAnimals(null));
      return response;
    } catch (e) {
      return new Error(e);
    }
  }
}

export default PartnersController;
