import JogoBackOfficeApiHandler from '../JogoBackOfficeApiHandler';

const END_POINT = {
  prefix: 'Games',
  result : 'result',
  cancel : 'cancel',
  autoGeteratedResult : 'autoGeteratedResult',
  lastResults : 'lastResults',
  active : 'active'

};

class GamesApiHandler extends JogoBackOfficeApiHandler{
  constructor(prefix){
    super(prefix);
  }
  getGameResultById(id){
      return this.get(`${id}/${END_POINT.result}`)
  }

  getGameById(id){
    return this.get(`${id}`)
  }


  getGames(query){
    if(query){
      return this.get('',query)
    }else {
      return this.get()
    }
  }

  getGamesLastResults(){
    return this.get(`${END_POINT.lastResults}`)
  }
  getActiveGamesById(partnerId){
    return this.get(`${END_POINT.active}/${partnerId}`)
  }
  getGameAutoGeneratesResultById(id){
    return this.get(`${id}/${END_POINT.autoGeteratedResult}`)
  }
  cancelGameById(id){
    return this.post(`${id}/${END_POINT.cancel}`)
  }
  addGames(data){
    return this.post('', data)
  }
  addGameResultById(id , data){
    return this.post(`${id}/${END_POINT.result}`, data)
  }

}

const gamesApiHandler = new GamesApiHandler(END_POINT.prefix);
export default gamesApiHandler;