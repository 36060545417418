import React, { useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../../../core/constants/colors";
import { translateText } from "../../../components/translation/index";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { addResultInput } from "../../../../core/constants/InputsConstants";
import { validations } from "../../../../core/constants/validations";
import { rxModalClose } from "../../../../state-management/actions/modalActions";
import { cntrlAddGameResult } from "../../../../state-management/actions/gameActions";

const styles = {
  root: {
    width: "330px",
    minWidth: "250px",
    background: "#fff",
    border: `1px solid ${colors.headerColor}`,
    borderRadius: "5px",
    fontSize: "1.1em",
    overflowY: "hidden",
    fontWeight: "500",
    paddingBottom: "30px"
  },
  header: {
    height: "50px",
    background: colors.headerColor,
    marginBottom: "20px",
    padding: "0 10px",
    color: "#fff"
  },
  input: {
    borderColor: colors.headerColor,
    marginBottom: "10px"
  },
  button: {
    background: colors.buttonColor,
    marginTop: "20px",
    "&:hover": {
      background: colors.headerColor,
      color: "#fff"
    }
  },
  icon: {
    cursor: "pointer"
  }
};

const AddResultModal = React.memo(({ game, classes, isAdmin, ...props }) => {
  const [results, setResult] = useState({ gameId: `${props.gameId}` || "" });

  const handleInputChange = (key, value) => {
    if (validations.addResultValidator.test(value)) {
      setResult(prevState => ({
        ...prevState,
        [key]: value
      }));
    } else if (value === "") {
      setResult(prevState => ({
        ...prevState,
        [key]: ""
      }));
    }
  };

  const addResult = _ => {
    props.addGameResult(results);
  };

  let resultArray = Object.values(results);
  resultArray.splice(0, 1);
  let notDisabled =
    props.partnerSettings.hasMultipleDrawings || isAdmin
      ? resultArray.length === 5 && resultArray.every(i => i.length === 4)
      : resultArray.length === 1 && resultArray.every(i => i.length === 4);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          container
          className={classes.header}
          justify={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <p>{translateText("ADD_GAME_RESULT")} </p>
          </Grid>
          <Grid item>
            <Icon onClick={props.close} className={classes.icon}>
              close
            </Icon>
          </Grid>
        </Grid>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid container alignContent={"flex-start"} justify={"center"}>
            {props.partnerSettings.hasMultipleDrawings || isAdmin ? (
              <Grid item xs={10}>
                {addResultInput.map((i, index) => (
                  <TextField
                    key={`${index}`}
                    value={results[i.name] ? results[i.name] : ""}
                    className={classes.input}
                    required={i.required}
                    fullWidth={i.fullWidth}
                    name={i.name}
                    label={i.label}
                    margin={i.margin}
                    onChange={e =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                ))}
              </Grid>
            ) : (
              <Grid item xs={10}>
                <TextField
                  value={
                    results[addResultInput[0].name]
                      ? results[addResultInput[0].name]
                      : ""
                  }
                  className={classes.input}
                  required={addResultInput[0].required}
                  fullWidth={addResultInput[0].fullWidth}
                  name={addResultInput[0].name}
                  label={addResultInput[0].label}
                  margin={addResultInput[0].margin}
                  onChange={e =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.button}
              disabled={!notDisabled}
              onClick={addResult}
            >
              {translateText("ADD")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

const mapStateToProps = state => ({
  partnerSettings: state.partnerSettings.data,
  gameId: state.gameReducer.selectedGameId,
  isAdmin: !state.userSettings.data.type
});

const mapDispatchToProps = dispatch => ({
  close: _ => dispatch(rxModalClose()),
  addGameResult: data => dispatch(cntrlAddGameResult(data))
});

AddResultModal.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddResultModal));
