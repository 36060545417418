import React , { useEffect } from 'react';
import { List, ListItem, Switch, ListItemSecondaryAction } from '@material-ui/core/';
import { translateText } from '../../../../components/translation';
import keyToTranslationMapper from '../../../../../core/helpers/translationKeyMapper';

const ConfigItem = ({data, mutator }) => {

  const [state, setState] = React.useState(data.enable);

  useEffect(() => {
    mutator(data.blockName, state)
  },[state]);

  const handleChange = ( event)=> {
    setState( event.target.checked );
  };


  const renderTranslations = (name, config) => {
    if (config.isActivity) {
      return translateText(keyToTranslationMapper(name))
    }
    return translateText(name.toUpperCase())
  };
  return (
    <List>
      <ListItem>
        {renderTranslations(data.blockName, data)}
      </ListItem>
      <ListItemSecondaryAction>
        <Switch
          onChange={(event ) =>handleChange(event )}
          checked={state}
        />
      </ListItemSecondaryAction>
    </List>
  );
};

export default ConfigItem;