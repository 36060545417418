import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';
import { rxSetAllLanguages } from '../../../state-management/actions/translationsActions';

class LanguagesOperations extends Operations {
  constructor(languagesApiHandler, partnerApiHandler) {
    super();
    this.languagesApiHandler = languagesApiHandler;
    this.partnerApiHandler = partnerApiHandler;
  }

  async getLanguages(store, action) {
    const langIds = await this.partnerApiHandler.getBackofficeLanguages();
    const response = await this.languagesApiHandler.getLanguages();
    let newResult = [];

    if (langIds.result && langIds.result.length) {
      langIds.result.forEach(languageItem => {
        if (responseErrorCheck(response)) {
          const itemIndex =  response.result.findIndex(item => {
            return item.id === languageItem.languageId
          });
          if (~itemIndex) {
            newResult.push(response.result[itemIndex])
          }
        }

      });
      return newResult;
    }

    return responseErrorCheck(response);
  }

}


export default LanguagesOperations;